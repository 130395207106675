import React from 'react';
import { faMoneyBills, faSignature, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Footer } from 'components/Common/Footer/Footer';
import { Header } from 'components/Common/Header/Header';
import { ImageLoader } from 'components/Common/ImageLoader/ImageLoader';
import { SegmentDescriptor } from 'components/Common/SegmentDecriptor/SegmentDecriptor';
import { ErrorScreen } from 'components/Shared/ErrorScreen/ErrorScreen';
import { LoadingScreen } from 'components/Shared/LoadingScreen/LoadingScreen';
import { User } from 'firebase/auth';
import { getProduct } from 'firestore';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createCheckoutSession } from 'stripe/createCheckoutSession';
import { Movie } from 'types/shared/movie-type';
import { filterActivePrice } from 'utils/Movie/filter-active-price/filter-active-price';
import { Container, ImageWrapper, Section } from './PlayerError.styled';

export const PlayerError = ({ movie, user, movieId }: { movie: Movie; user: User; movieId: string }) => {
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  const [checkoutLoading, setCheckoutLoading] = useState<boolean>(false);
  const [product, setProduct] = useState<any | null>(null);
  const [productError, setProductError] = useState<string | null>(null);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    (async () => {
      try {
        const product = await getProduct(movie.productId);
        setProduct(product);
      } catch (e) {
        setProductError(t('player.playerError.error'));
      }
    })();
  }, [user, movieId]);

  const handleClickBuyMovie = async (user: User, movieId: string, movieTitle: string, product: any) => {
    const activePrice = filterActivePrice(product.prices)[0];

    try {
      setCheckoutLoading(true);
      await createCheckoutSession(
        user.uid,
        // it does not support polish characters when those links are send to stripe
        // basically stripe does not handle polish characters
        `${window.location.origin}/${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}/movies/${movieId}/player`,
        `${window.location.origin}/${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}/movies/${movieId}`,
        [{ quantity: 1, price: activePrice.id }],
        i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language,
      );
    } catch (e) {}
  };

  if (productError) return <ErrorScreen errorTitle={productError} />;
  if (!product) return <LoadingScreen />;
  return (
    <>
      <Header user={user} />
      <Container>
        <Section>
          <ImageWrapper to='..' relative='path'>
            <ImageLoader
              imageFirebasePath={
                movie.imageSmall[i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language]
              }
            />
          </ImageWrapper>
          <Box sx={{ gridArea: 'box2' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                margin: '0 0 15px 0',
                color: (theme) => theme.palette.text.primary,
              }}>
              <FontAwesomeIcon
                style={{ display: 'inline-block', margin: '0 20px 0 0' }}
                size={mdUp ? '5x' : '4x'}
                icon={faTriangleExclamation}
              />
              <Typography component='h1' fontSize={mdUp ? '60px' : '46px'}>
                {t('player.playerError.warning')}
              </Typography>
            </Box>
            <Typography
              sx={{
                margin: '0 0 30px 0',
                padding: '0 0 3px 0',
                width: 'fit-content',
                borderBottom: (theme) => `2px solid ${theme.palette.primary.main}`,
              }}
              color={(theme) => theme.palette.text.primary}
              component='p'
              fontSize={mdUp ? '36px' : '26px'}>
              {t('player.playerError.warningInformation')}
            </Typography>
            <Typography
              sx={{
                margin: '0 0 30px 0',
              }}
              component='p'
              color={(theme) => theme.palette.text.primary}
              fontSize={mdUp ? '30px' : '22px'}>
              {t('player.playerError.warningHint')}
            </Typography>
            <SegmentDescriptor
              icon={faSignature}
              title={t('shared.segmentDescriptor.title')}
              description={t(movie.title)}
            />
          </Box>
          <Box sx={{ gridArea: 'button' }}>
            {movie.status === 'unavailable' || movie.status === 'hidden' ? (
              <Box sx={{ border: '1px solid', padding: '10px', borderColor: theme.palette.text.secondary }}>
                <Typography fontSize='18px' color='text.primary'>
                  {t('movie.movieDetails.contentUnavailable')}
                </Typography>
              </Box>
            ) : (
              <LoadingButton
                size='large'
                fullWidth
                sx={{ margin: '0 0 30px 0', textTransform: 'none' }}
                onClick={async () => await handleClickBuyMovie(user, movieId as string, movie.title, product)}
                variant='contained'
                startIcon={<FontAwesomeIcon size='sm' icon={faMoneyBills} />}
                loading={checkoutLoading}
                disabled={checkoutLoading}>
                {t('shared.buttons.rentWithTime', {
                  price: (filterActivePrice(product.prices)[0].unit_amount * 0.01).toFixed(2),
                  currency: filterActivePrice(product.prices)[0].currency.toUpperCase(),
                })}
              </LoadingButton>
            )}
          </Box>
        </Section>
      </Container>
      <Footer />
    </>
  );
};
