import React, { useEffect, useState } from 'react';
import { Container } from './Shopping.styled';
import { ShoppingList } from './ShoppingList/ShoppingList';
import { getUserPayments } from 'firestore';
import { filterSuccessPayments } from 'utils/Account/Shopping/filter-success-payments/filter-success-payments';
import { User } from 'firebase/auth';
import { ErrorScreen } from 'components/Shared/ErrorScreen/ErrorScreen';
import { LoadingScreen } from 'components/Shared/LoadingScreen/LoadingScreen';
import { Payment } from 'models/Account/Shopping/payment/payment';
import { HelmetContent } from 'components/Common/HelmetContent/HelmetContent';
import { useTranslation } from 'react-i18next';

export const Shopping: React.FC<OwnProps> = ({ user }) => {
  const [payments, setPayments] = useState<Payment[] | null>(null);
  const [paymentsError, setPaymentsError] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      try {
        const paymentsResponse = await getUserPayments(user.uid);
        if (paymentsResponse) {
          setPayments(filterSuccessPayments(paymentsResponse));
        }
      } catch (e) {
        setPaymentsError(true);
      }
    })();
  }, []);

  if (paymentsError) return <ErrorScreen errorTitle='Wystąpił błąd podczas ładowania twoich płatności' />;
  if (!payments) return <LoadingScreen />;
  return (
    <Container>
      <HelmetContent title={t('account.shopping.pageTitle')} description={t('account.shopping.pageDescription')} />
      <ShoppingList payments={payments} />
    </Container>
  );
};

interface OwnProps {
  user: User;
}
