import { styled } from '@mui/material';
import { NavLink } from 'react-router-dom';

export const Container = styled('article')(({ theme }) => ({
  width: '100%',
  margin: '0 0 0px 0',
  position: 'relative',
  zIndex: 1,
  // borderBottom: `2px dashed ${theme.palette.primary.main}`,

  // '&:after': {
  //   display: 'block',
  //   content: '""',
  //   position: 'absolute',
  //   width: '80%',
  //   bottom: '10px',
  //   left: '50%',
  //   borderBottom: `3px dashed ${theme.palette.primary.main}`,
  //   // padding: '3px',
  //   borderRadius: '10px',
  //   // backgroundColor: theme.palette.primary.main,
  //   transform: 'translate(-50%, 0)',
  // },

  '&:last-of-type': {
    margin: '0',
  },

  '& .carousel-container': {
    padding: '15px 0',
    overflow: 'none',
  },

  '& .react-multiple-carousel__arrow--left': {
    left: '4px',
  },

  '& .react-multiple-carousel__arrow--right': {
    right: '4px',
  },

  '& .carousel-item': {
    padding: '0 5px 0 0',
    transition: 'transform .2s ease-in-out',

    '&:last-of-type': {
      padding: '0',
    },

    [theme.breakpoints.up('sm')]: {
      padding: '0 15px 0 0',
    },
  },

  '& .carousel-item:hover': {
    transition: 'transform .2s ease-in-out',
    transform: 'scale(1.1)',
    zIndex: theme.zIndex.modal,
  },
}));

export const MovieStyled = styled(NavLink)(({ theme }) => ({
  display: 'block',
  width: '100%',
  borderRadius: '5px',
  boxShadow: theme.shadows[10],
  overflow: 'hidden',
}));

export const MovieImage = styled('img')({
  width: '100%',
});
