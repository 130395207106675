import { Title } from 'components/Common/Title/Title';
import React, { PropsWithChildren } from 'react';
import { Container, TitleWrapper, Wrapper } from './SectionLayout.styled';

export const SectionLayout: React.FC<OwnProps> = (props: PropsWithChildren<OwnProps>) => {
  const {
    title,
    titlePrefix,
    children,
    addStyleTop = true,
    addStyleBottom = true,
    paddingDefault,
    paddingMd,
    paddingLg,
    paddingXl,
    maxWidth,
  } = props;

  return (
    <Container
      addStyleBottom={addStyleBottom}
      addStyleTop={addStyleTop}
      paddingDefault={paddingDefault}
      paddingMd={paddingMd}
      paddingLg={paddingLg}
      paddingXl={paddingXl}>
      <Wrapper maxWidth={maxWidth}>
        <TitleWrapper>
          <Title addIcons={false} title={title} titlePrefix={titlePrefix} />
        </TitleWrapper>
        {children}
      </Wrapper>
    </Container>
  );
};

interface OwnProps {
  title: string;
  titlePrefix?: string;
  children: React.ReactNode;
  addStyleTop?: boolean;
  addStyleBottom?: boolean;
  paddingDefault?: string;
  paddingMd?: string;
  paddingLg?: string;
  paddingXl?: string;
  maxWidth?: 'auto';
}
