import { styled } from '@mui/material';

export const Container = styled('main')(() => ({}));

export const Wrapper = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'grid',
  gridColumnGap: '40px',
  position: 'relative',
  gridTemplateColumns: `1fr`,
  gridTemplateRows: `fit-content 1fr`,
  gridRowGap: '80px',
  gridTemplateAreas: `
    "basketPrice"
    "basketMoviesList"
    `,

  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: `1fr minmax(100px, 900px) minmax(300px, 1fr)`,
    gridTemplateRows: `200px 1fr 1fr`,
    gridTemplateAreas: `
    ". basketMoviesList basketPrice"
    ". basketMoviesList ."
    ". basketMoviesList ."`,
  },
}));
