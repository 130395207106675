import React, { useContext, useEffect } from 'react';
import { Container, Wrapper } from './Basket.styled';
import { TitleWrapper, Container as SectionContainer } from 'layouts/SectionLayout/SectionLayout.styled';
import { Title } from 'components/Common/Title/Title';
import { BasketMoviesList } from 'components/Basket/BasketMoviesList/BasketMoviesList';
import { BasketPrice } from 'components/Basket/BasketPrice/BasketPrice';
import { Box } from '@mui/material';
import { BasketContext } from 'context/BasketContext';
import { User } from 'firebase/auth';
import { useTranslation } from 'react-i18next';
import { HelmetContent } from 'components/Common/HelmetContent/HelmetContent';
import { scrollTop } from 'utils/Common/scroll-top/scroll-top';

export const Basket: React.FC<OwnProps> = ({ user }: { user: User }) => {
  const { items } = useContext(BasketContext);
  const { t } = useTranslation();

  useEffect(() => {
    scrollTop();
  }, []);

  return (
    <Container>
      <HelmetContent title={t('basket.pageTitle')} description={t('basket.pageDescription')} />
      <SectionContainer addStyleBottom={false} addStyleTop={true}>
        <Box sx={{ width: '100%' }}>
          <TitleWrapper>
            <Title addIcons={false} title={t('basket.title')} />
          </TitleWrapper>
          <Wrapper>
            <BasketPrice user={user} />
            <BasketMoviesList user={user} basketMovies={items} />
          </Wrapper>
        </Box>
      </SectionContainer>
    </Container>
  );
};

interface OwnProps {
  user: User;
}
