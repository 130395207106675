import { styled } from '@mui/material';

export const Container = styled('section')(({ theme }) => ({
  background: theme.palette.background.paper,
  position: 'relative',
  padding: '17px 20px',
  overflow: 'hidden',
  borderImage:
    'linear-gradient(90deg, rgba(144,202,249,1) 17%, rgba(102,178,255,1) 32%, rgba(19,47,76,1) 71%, rgba(144,202,249,1) 100%) 30',
  borderTopWidth: '4px',
  borderStyle: 'solid',
  borderRight: 'none',
  borderBottom: 'none',
  borderLeft: 'none',

  [theme.breakpoints.up('md')]: {
    padding: '50px',
  },

  [theme.breakpoints.up('lg')]: {
    padding: '50px 70px',
  },
}));

export const ExtraBackgroundWrapper = styled('div')(() => ({
  backgroundImage: 'url("/assets/images/backgrounds/background_image_1.svg")',
  backgroundPositionX: '-400px',
  backgroundPositionY: '0',
  backgroundRepeat: 'no-repeat',
  zIndex: 0,
  position: 'absolute',
  top: '20px',
  left: 0,
  width: '100%',
  height: '100%',
}));
