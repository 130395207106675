export class MyMovie {
  id: string;
  title: string;
  imageSmall: Record<string, string>;

  constructor(id: string, title: string, imageSmall: Record<string, string>) {
    this.id = id;
    this.title = title;
    this.imageSmall = imageSmall;
  }
}
