import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { formatDuration } from 'utils/Player/formatDuration/formatDuration';

export const WatchedTimeIndicator: React.FC<OwnProps> = (props) => {
  const { videoWatchedTime, videoDuration, showTime = true, description } = props;

  const theme = useTheme();

  return (
    <Box sx={{ position: 'static', left: 0, minHeight: '5px', bottom: '101%', width: '100%' }}>
      {!!description && (
        <Box sx={{ mb: '6px' }}>
          <Typography fontWeight={500} fontSize='16px' lineHeight='16px' textAlign='left'>
            {description}
          </Typography>
        </Box>
      )}

      {showTime && (
        <Box
          sx={{
            pb: '3px',
            pl: '3px',
          }}>
          <Typography
            sx={{ textShadow: '1px 1px 1px rgba(0, 0, 0, 1)' }}
            fontSize='14px'
            lineHeight='14px'
            fontWeight={700}
            component='span'
            color='primary'>
            {/* {Math.floor(videoWatchedTime / 60) % 60}
            {' z '} */}
            {formatDuration(videoWatchedTime)} /{' '}
          </Typography>
          <Typography sx={{ textShadow: '1px 1px 1px black' }} fontSize='14px' lineHeight='14px' component='span'>
            {/* {Math.floor(videoDuration / 60) % 60}
            {' Min'} */}
            {formatDuration(videoDuration)}
          </Typography>
        </Box>
      )}

      <Box
        sx={{
          width: '100%',
          position: 'relative',
        }}>
        <Box
          sx={{
            background: theme.palette.grey[600],
            height: '5px',
            width: '100%',
            position: 'relative',
            zIndex: 1,
          }}></Box>
        <Box
          sx={{
            background: theme.palette.primary.main,
            height: '5px',
            width: `${(videoWatchedTime / videoDuration) * 100}%`,
            position: 'absolute',
            left: 0,
            top: 0,
            zIndex: 2,
          }}></Box>
      </Box>
    </Box>
  );
};

interface OwnProps {
  videoWatchedTime: number;
  videoDuration: number;
  showTime?: boolean;
  description?: string;
}
