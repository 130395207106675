import { Movies } from 'components/Movies/Movies';
import React from 'react';

export const ChristianMovies: React.FC = () => {
  return (
    <Movies
      initialTag='christian'
      title='christianMovies.title'
      titlePrefix='christianMovies.titlePrefix'
      pageTitle='christianMovies.pageTitle'
      pageDescription='christianMovies.pageDescription'
      keywords='christianMovies.pageKeywords'
    />
  );
};
