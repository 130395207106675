import React from 'react';
import { Container } from './LoadingScreen.styled';
import { CircularProgress } from '@mui/material';

export const LoadingScreen: React.FC<OwnProps> = ({ addSpinner = true }) => {
  return <Container>{addSpinner && <CircularProgress thickness={1.6} />}</Container>;
};

interface OwnProps {
  addSpinner?: boolean;
}
