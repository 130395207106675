import React from 'react';
import { Container, TitleWrapper, CarouselWrapper, ExtraBackgroundElement } from './MovieExtras.styled';
import { Title } from 'components/Common/Title/Title';
import { TitleOptionEnum } from 'types/common/Title/title-option.type';
import { MovieExtrasCarousel } from './MovieExtrasCarousel/MovieExtrasCarousel';
import { MovieExtra } from 'types/shared/movie-type';
import { useTranslation } from 'react-i18next';

export const MovieExtras: React.FC<OwnProps> = ({ movieExtras }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <ExtraBackgroundElement />
      <TitleWrapper>
        <Title
          titlePrefix={t('movie.movieExtras.titlePrefix')}
          addIcons={true}
          title={t('movie.movieExtras.title')}
          titleOption={TitleOptionEnum.LARGE}
        />
      </TitleWrapper>
      <CarouselWrapper>
        <MovieExtrasCarousel movieExtras={movieExtras} />
      </CarouselWrapper>
    </Container>
  );
};

interface OwnProps {
  movieExtras: MovieExtra[];
}
