import { styled } from '@mui/material';

export const Container = styled('article')({
  width: '100%',
  maxWidth: '750px',

  '& .carousel-container': {
    padding: '30px 0',
    overflow: 'none',
  },

  '& .react-multiple-carousel__arrow--left': {
    left: '4px',
  },

  '& .react-multiple-carousel__arrow--right': {
    right: '4px',
  },

  '& .carousel-item': {
    padding: '0 30px 0 0',
    transition: 'all .2s ease-in-out',

    '&:last-of-type': {
      padding: '0',
    },
  },
});

export const MovieExtraFigure = styled('figure')(({ theme }) => ({
  paddingBottom: '56.25%', // 16:9
  height: 0,
  width: '100%',
  position: 'relative',
  overflow: 'hidden',
  borderRadius: '10px',
  boxShadow: theme.shadows[10],
}));

export const MovieExtraIFrame = styled('iframe')(() => ({
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
}));
