import {
  faCartPlus,
  faClock,
  faClosedCaptioning,
  faDisplay,
  faFileSignature,
  faFilm,
  faLanguage,
  faMoneyBills,
  faPhotoFilm,
  faPlayCircle,
  faSignature,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import { Box, Link, Modal, Skeleton, Typography, useMediaQuery, useTheme } from '@mui/material';
import { HelmetContent } from 'components/Common/HelmetContent/HelmetContent';
import { ImageLoader } from 'components/Common/ImageLoader/ImageLoader';
import { Player } from 'components/Common/Player/Player';
import { SegmentDescriptor } from 'components/Common/SegmentDecriptor/SegmentDecriptor';
import { Title } from 'components/Common/Title/Title';
import { WatchedTimeIndicator } from 'components/Common/WatchedTimeIndicator/WatchedTimeIndicator';
import { LoadingScreen } from 'components/Shared/LoadingScreen/LoadingScreen';
import { BasketContext } from 'context/BasketContext';
import { WhishlistContext } from 'context/WhishlistContext';
import dayjs from 'dayjs';
import { User } from 'firebase/auth';
import {
  addItemToBasket,
  addItemToWhishlist,
  getBookmark,
  getImageAsUrl,
  getProduct,
  getUserPayments,
  removeItemFromBasket,
  removeItemFromWhishlist,
} from 'firestore';
import { BasketMovie } from 'models/Movie/basket-movie/basket-movie';
import { WhishlistMovie } from 'models/Movie/whishlist-movie/whishlist-movie';
import { Bookmark } from 'models/Player/bookmark';
import React, { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { createCheckoutSession } from 'stripe/createCheckoutSession';
import { Movie } from 'types/shared/movie-type';
import { convertMovieTileToOnlyItsOwnLanguage } from 'utils/Common/convert-movie-title-to-only-its-own-language/convert-movie-title-to-only-its-own-language';
import { resolveLanguages } from 'utils/Common/resolve-languages/resolve-languages';
import { resolveSubtitles } from 'utils/Common/resolve-subtitles/resolve-subtitles';
import { scrollTop } from 'utils/Common/scroll-top/scroll-top';
import { isItemExistInBasket } from 'utils/Context/BasketContext/isItemExistInBasket/isItemExistInBasket';
import { isItemExistInWhishlist } from 'utils/Context/WhishlistContext/is-item-exist-in-whishlist/is-item-exist-in-whishlist';
import { checkHowManyHoursLeft } from 'utils/Movie/check-how-many-hours-are-left/check-how-many-hours-are-left';
import { checkIfUserPaidForMovie } from 'utils/Movie/check-if-user-paid-for-movie/check-if-user-paid-for-movie';
import { filterActivePrice } from 'utils/Movie/filter-active-price/filter-active-price';
import { ModalBox } from '../Posters/PostersCarousel/PostersCarousel.styled';
import {
  Box1,
  Box2,
  ButtonsContainer,
  ButtonsWrapper,
  Container,
  CustomButton,
  ExtraBackgroundWrapper,
  ImageWrapper,
  SegmentsWrapper,
  TitleWrapper,
  Wrapper,
} from './MovieDetails.styled';

export const MovieDetails: React.FC<OwnProps> = ({ movie, user }) => {
  const navigate = useNavigate();
  const { items } = useContext(BasketContext);
  const { items: whishlistItems } = useContext(WhishlistContext);
  const [product, setProduct] = useState<any | null>(null);
  const [isUserPaidForCurrentMovie, setIsUserPaidForCurrentMovie] = useState<boolean>(false);
  const [isUserPaidForCurrentMovieLoading, setIsUserPaidForCurrentMovieLoading] = useState<boolean>(true);
  const [checkoutLoading, setCheckoutLoading] = useState<boolean>(false);
  const [hoursLeft, setHoursLeft] = useState<null | number>(null);
  const [videoBookmark, setVideoBookmark] = useState<Bookmark | null>(null);
  const [isBookmarkLoading, setIsBookmarkLoading] = useState<boolean>(true);
  const { t, i18n } = useTranslation();
  const [imageDownloadUrl, setImageDownloadUrl] = useState<null | string>(null);

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const theme = useTheme();
  const xlUp = useMediaQuery(theme.breakpoints.up('xl'));
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));

  const handleClickBuyMovie = async (user: User, product: any) => {
    const activePrice = filterActivePrice(product.prices)[0];

    try {
      setCheckoutLoading(true);
      await createCheckoutSession(
        user.uid,
        // it does not support polish characters when those links are send to stripe
        // basically stripe does not handle polish characters
        `${window.location.origin}/${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}/movies/${movie.id}/player`,
        `${window.location.origin}/${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}/movies/${movie.id}`,
        [{ quantity: 1, price: activePrice.id }],
        i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language,
      );
    } catch (e) {
    } finally {
      const basketMovieElement: BasketMovie | undefined = items.find((item: BasketMovie) => item.id === movie.id);
      if (basketMovieElement) {
        await removeItemFromBasket(user.uid, basketMovieElement.id);
      }

      const whishlistMovieElement: WhishlistMovie | undefined = items.find((item: BasketMovie) => item.id === movie.id);
      if (whishlistMovieElement) {
        await removeItemFromWhishlist(user.uid, whishlistMovieElement.id);
      }
    }
  };

  const toJsDate = (firestoreDate: any) => {
    const ts = (firestoreDate.seconds + firestoreDate.nanoseconds * 10 ** -9) * 1000;
    return new Date(ts);
  };

  useEffect(() => {
    (async () => {
      try {
        setIsUserPaidForCurrentMovieLoading(true);
        const product = await getProduct(movie.productId);
        if (product) {
          setProduct(product);
        }
        if (user) {
          const userPayments = await getUserPayments(user.uid);
          if (userPayments) {
            if (checkIfUserPaidForMovie(movie, userPayments)) {
              setHoursLeft(checkHowManyHoursLeft(movie, userPayments));
              setIsUserPaidForCurrentMovie(true);

              const bookmark = await getBookmark(user.uid, movie.id);
              if (bookmark) {
                setVideoBookmark(bookmark);
              }
            } else {
              setIsUserPaidForCurrentMovie(false);
            }
          } else {
            setIsUserPaidForCurrentMovie(false);
          }
        }
      } catch (e) {
        console.log(e);
      } finally {
        setIsUserPaidForCurrentMovieLoading(false);
        setIsBookmarkLoading(false);
      }
    })();
  }, [user, movie]);

  useEffect(() => {
    (async () => {
      try {
        const imageDownloadUrl = await getImageAsUrl(
          movie.imageSmall[i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language],
        );
        setImageDownloadUrl(imageDownloadUrl);
      } catch (e) {
        console.log(e);
      }
    })();
  }, [i18n.language]);

  useEffect(() => {
    scrollTop();
  }, []);

  const handleWatchMovie = () => {
    navigate(`./player`, { relative: 'path' });
  };

  const handleClickAddMovieToBasket = async (
    uid: string,
    itemId: string,
    title: string,
    price: number,
    priceId: string,
    imageSmall: Record<string, string>,
    currency: string,
  ) => {
    try {
      await addItemToBasket(uid, itemId, title, price, priceId, imageSmall, currency);
    } catch (e) {
      console.log(e);
    }
  };

  const handleClickRemoveMovieFromBasket = async (uid: string, itemId: string) => {
    try {
      await removeItemFromBasket(uid, itemId);
    } catch (e) {
      console.log(e);
    }
  };

  const handleClickAddMovieToWhishlist = async (
    uid: string,
    itemId: string,
    title: string,
    imageSmall: Record<string, string>,
  ) => {
    try {
      await addItemToWhishlist(uid, itemId, title, imageSmall);
    } catch (e) {
      console.log(e);
    }
  };

  const handleClickRemoveMovieFromWhishlist = async (uid: string, itemId: string) => {
    try {
      await removeItemFromWhishlist(uid, itemId);
    } catch (e) {
      console.log(e);
    }
  };

  if (!product || isBookmarkLoading) return <LoadingScreen />;
  return (
    <Container>
      <HelmetContent
        title={t('movie.pageTitle', { movieTitle: convertMovieTileToOnlyItsOwnLanguage(t(movie.title)) })}
        description={t(movie.description)}
        ogTitle={t('movie.pageTitle', { movieTitle: convertMovieTileToOnlyItsOwnLanguage(t(movie.title)) })}
        ogDescription={t(movie.description)}
        ogType='video.movie'
        ogUrl={window.location.href}
        ogSiteName={window.location.hostname}
        ogImage={imageDownloadUrl ? imageDownloadUrl : ''}
      />
      <Box1>
        <ImageWrapper>
          <ImageLoader
            alt={t(movie.title)}
            imageFirebasePath={
              movie.imageSmall[i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language]
            }
          />
        </ImageWrapper>
        <Wrapper>
          {user ? (
            <>
              {isUserPaidForCurrentMovieLoading ? (
                <Skeleton width='100%' height='60px' />
              ) : isUserPaidForCurrentMovie ? (
                <ButtonsContainer>
                  <CustomButton
                    size='large'
                    onClick={() => handleWatchMovie()}
                    variant='outlined'
                    startIcon={<FontAwesomeIcon size='sm' icon={faPlayCircle} />}>
                    {videoBookmark ? (
                      <WatchedTimeIndicator
                        showTime={false}
                        description={t('shared.buttons.continueWatching', {
                          minutes: Math.floor((videoBookmark.videoDuration - videoBookmark.lastWatchTime) / 60) % 60,
                        })}
                        videoWatchedTime={videoBookmark.lastWatchTime}
                        videoDuration={videoBookmark.videoDuration}
                      />
                    ) : (
                      t('shared.buttons.watch')
                    )}
                  </CustomButton>
                </ButtonsContainer>
              ) : movie.status === 'unavailable' || movie.status === 'hidden' ? (
                <Box
                  sx={{
                    border: '1px solid',
                    padding: '10px',
                    borderColor: theme.palette.text.secondary,
                    marginBottom: mdUp ? 0 : '50px',
                  }}>
                  <Typography fontSize='18px' color='text.primary'>
                    {t('movie.movieDetails.contentUnavailable')}
                  </Typography>
                </Box>
              ) : (
                <ButtonsContainer>
                  <ButtonsWrapper>
                    <LoadingButton
                      size='large'
                      fullWidth
                      sx={{ margin: '0 0 30px 0', textTransform: 'none' }}
                      onClick={async () => await handleClickBuyMovie(user, product)}
                      variant='contained'
                      startIcon={<FontAwesomeIcon size='sm' icon={faMoneyBills} />}
                      loading={checkoutLoading}
                      disabled={checkoutLoading}>
                      {t('shared.buttons.rent', {
                        price: (filterActivePrice(product.prices)[0].unit_amount * 0.01).toFixed(2),
                        currency: filterActivePrice(product.prices)[0].currency.toUpperCase(),
                      })}
                    </LoadingButton>
                    {isItemExistInBasket(items, movie.id) ? (
                      <CustomButton
                        size='large'
                        variant='outlined'
                        disabled={checkoutLoading}
                        onClick={async () => handleClickRemoveMovieFromBasket(user.uid, movie.id)}
                        startIcon={<FontAwesomeIcon size='sm' icon={faTrash} />}>
                        {t('shared.buttons.removeFromBasket')}
                      </CustomButton>
                    ) : (
                      <CustomButton
                        size='large'
                        variant='outlined'
                        disabled={checkoutLoading}
                        onClick={async () =>
                          handleClickAddMovieToBasket(
                            user.uid,
                            movie.id,
                            movie.title,
                            filterActivePrice(product.prices)[0].unit_amount,
                            filterActivePrice(product.prices)[0].id,
                            movie.imageSmall,
                            filterActivePrice(product.prices)[0].currency,
                          )
                        }
                        startIcon={<FontAwesomeIcon size='sm' icon={faCartPlus} />}>
                        {t('shared.buttons.addToBasket')}
                      </CustomButton>
                    )}
                  </ButtonsWrapper>
                  {isItemExistInWhishlist(whishlistItems, movie.id) ? (
                    <CustomButton
                      size='large'
                      variant='outlined'
                      disabled={checkoutLoading}
                      onClick={async () => handleClickRemoveMovieFromWhishlist(user.uid, movie.id)}
                      startIcon={<FontAwesomeIcon size='sm' icon={faTrash} />}>
                      {t('shared.buttons.removeFromWhishlist')}
                    </CustomButton>
                  ) : (
                    <CustomButton
                      size='large'
                      variant='outlined'
                      disabled={checkoutLoading}
                      onClick={async () =>
                        handleClickAddMovieToWhishlist(user.uid, movie.id, movie.title, movie.imageSmall)
                      }
                      startIcon={<FontAwesomeIcon size='sm' icon={faPhotoFilm} />}>
                      {t('shared.buttons.addToWhishlist')}
                    </CustomButton>
                  )}
                </ButtonsContainer>
              )}
            </>
          ) : movie.status === 'unavailable' || movie.status === 'hidden' ? (
            <Box
              sx={{
                border: '1px solid',
                padding: '10px',
                borderColor: theme.palette.text.secondary,
                marginBottom: mdUp ? 0 : '50px',
              }}>
              <Typography fontSize='18px' color='text.primary'>
                {t('movie.movieDetails.contentUnavailable')}
              </Typography>
            </Box>
          ) : (
            <ButtonsContainer>
              <ButtonsWrapper>
                <CustomButton
                  size='large'
                  onClick={() =>
                    navigate(`/${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}/login`)
                  }
                  variant='contained'
                  disabled={checkoutLoading}
                  startIcon={<FontAwesomeIcon size='sm' icon={faMoneyBills} />}>
                  {t('shared.buttons.rent', {
                    price: (filterActivePrice(product.prices)[0].unit_amount * 0.01).toFixed(2),
                    currency: filterActivePrice(product.prices)[0].currency.toUpperCase(),
                  })}
                </CustomButton>
                <CustomButton
                  size='large'
                  variant='outlined'
                  onClick={() =>
                    navigate(`/${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}/login`)
                  }
                  startIcon={<FontAwesomeIcon size='sm' icon={faCartPlus} />}>
                  {t('shared.buttons.addToBasket')}
                </CustomButton>
              </ButtonsWrapper>
              <CustomButton
                size='large'
                variant='outlined'
                onClick={() =>
                  navigate(`/${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}/login`)
                }
                startIcon={<FontAwesomeIcon size='sm' icon={faPhotoFilm} />}>
                {t('shared.buttons.addToWhishlist')}
              </CustomButton>
            </ButtonsContainer>
          )}

          <SegmentsWrapper>
            <SegmentDescriptor
              title={t('shared.segmentDescriptor.title')}
              description={t(movie.title)}
              icon={faSignature}
            />
            <SegmentDescriptor
              title={t('shared.segmentDescriptor.description')}
              description={
                <>
                  {`${t(movie.description).substring(0, 40)} ... `}
                  <Link component='button' onClick={handleOpenModal} color='inherit'>
                    {t('shared.buttons.fullDescription')}
                  </Link>
                </>
              }
              icon={faFileSignature}
            />
            <Modal
              open={modalOpen}
              onClose={handleCloseModal}
              aria-labelledby='modal-modal-title'
              aria-describedby='modal-modal-description'>
              <ModalBox sx={{ background: theme.palette.background.background5, padding: 4, borderRadius: '4px' }}>
                <SegmentDescriptor
                  title={t('shared.segmentDescriptor.description')}
                  description={t(movie.description)}
                  icon={faFileSignature}
                />
              </ModalBox>
            </Modal>
            <SegmentDescriptor
              title={t('shared.segmentDescriptor.releaseDate')}
              description={dayjs(toJsDate(movie.releaseDate)).format(
                (i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language) === 'en'
                  ? 'YYYY-MM-DD'
                  : 'DD/MM/YYYY',
              )}
              icon={faFilm}
            />
            <SegmentDescriptor
              title={t('shared.segmentDescriptor.languages')}
              description={resolveLanguages(movie.languages, t).join(', ')}
              icon={faLanguage}
            />
            <SegmentDescriptor
              title={t('shared.segmentDescriptor.captions')}
              description={resolveSubtitles(movie.subtitles, t).join(', ')}
              icon={faClosedCaptioning}
            />
            <SegmentDescriptor
              title={t('shared.segmentDescriptor.quality')}
              description={t(movie.quality)}
              icon={faDisplay}
            />
            {!isUserPaidForCurrentMovieLoading && !isUserPaidForCurrentMovie && (
              <SegmentDescriptor title={t('shared.segmentDescriptor.rentPeriod')} description={'48h'} icon={faClock} />
            )}

            {!isUserPaidForCurrentMovieLoading && isUserPaidForCurrentMovie && (
              <SegmentDescriptor
                title={t('shared.segmentDescriptor.rentTime')}
                description={
                  <Trans
                    i18nKey='movie.movieDetails.rentTimeDescription'
                    values={{
                      hours: hoursLeft,
                    }}
                    components={[
                      <Typography
                        key={0}
                        fontWeight={500}
                        color='primary'
                        sx={{ display: 'inline-block', margin: '0 10px 0 0' }}
                        component='span'
                      />,
                    ]}
                  />
                }
                icon={faClock}
              />
            )}
          </SegmentsWrapper>
        </Wrapper>
      </Box1>
      <Box2>
        {xlUp && <ExtraBackgroundWrapper />}
        <TitleWrapper>
          <Title
            titlePrefix={t('movie.movieDetails.titlePrefix')}
            title={t('movie.movieDetails.title')}
            addIcons={true}
          />
        </TitleWrapper>
        <Player
          movieVimeoId={movie.trailer[i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language]}
        />
      </Box2>
    </Container>
  );
};

interface OwnProps {
  movie: Movie;
  user: User | null;
}
