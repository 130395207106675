import { Autocomplete, Chip, TextField } from '@mui/material';
import { HelmetContent } from 'components/Common/HelmetContent/HelmetContent';
import { MoviesList } from 'components/Movies/MoviesList/MoviesList';
import { MoviesContext } from 'context/MoviesContext';
import { SectionLayout } from 'layouts/SectionLayout/SectionLayout';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { filterMoviesByCategories } from 'utils/Common/filter-movies-by-categories/filter-movies-by-categories';
import { PossibleTag, filterPossibleTags } from 'utils/Common/filter-possible-tags/filter-possible-tags';
import { scrollTop } from 'utils/Common/scroll-top/scroll-top';
import { Container } from './Movies.styled';

export const Movies: React.FC<OwnProps> = (props) => {
  const { initialTag = 'christian', title, titlePrefix, pageTitle, pageDescription, keywords } = props;
  const { movies } = useContext(MoviesContext);
  const [selectedTags, setSelectedTags] = useState<PossibleTag[]>(
    initialTag !== 'all' ? [{ value: initialTag, qty: 1 }] : [],
  );
  const [possibleTags, setPossibleTags] = useState<PossibleTag[]>(
    filterPossibleTags(filterMoviesByCategories(movies, [initialTag])),
  );

  const [filteredMovies, setFilteredMovies] = useState(filterMoviesByCategories(movies, [initialTag]));

  const { t } = useTranslation();

  useEffect(() => {
    scrollTop();
  }, []);

  useEffect(() => {
    if (selectedTags.length > 0) {
      setPossibleTags(
        filterPossibleTags(
          filterMoviesByCategories(
            movies,
            selectedTags.map((selectedTag: PossibleTag) => selectedTag.value),
          ),
        ),
      );
      return setFilteredMovies(
        filterMoviesByCategories(
          movies,
          selectedTags.map((selectedTag: PossibleTag) => selectedTag.value),
        ),
      );
    }
    setPossibleTags(filterPossibleTags(filterMoviesByCategories(movies, ['all'])));
    return setFilteredMovies(filterMoviesByCategories(movies, ['all']));
  }, [selectedTags]);

  return (
    <Container>
      <HelmetContent
        title={t(pageTitle)}
        description={t(pageDescription)}
        keywords={keywords ? t(keywords) : undefined}
      />
      <SectionLayout
        addStyleBottom={false}
        paddingMd='40px'
        paddingLg='60px 70px 40px 70px'
        paddingXl='120px 70px 40px 70px'
        maxWidth='auto'
        titlePrefix={t(titlePrefix)}
        title={t(title)}>
        <Autocomplete
          multiple
          noOptionsText={t('shared.autocomplete.categories.none')}
          filterSelectedOptions
          id='size-small-standard-multi'
          size='medium'
          options={possibleTags}
          value={selectedTags}
          onChange={(event: any, newValue: PossibleTag[] | null) => {
            setSelectedTags(newValue ? newValue : []);
          }}
          renderTags={(value, getTagProps) =>
            // value.map((option, index) => {
            //   const object = getTagProps({ index });
            //   // @ts-ignore
            //   delete object.key;
            //   return (
            //     // @ts-ignore
            //     <Chip key={index} label={t(`shared.categoryTags.${option.value}`)} {...object} />
            //   );
            // })
            value.map((option, index) => (
              // eslint-disable-next-line
              <Chip label={t(`shared.categoryTags.${option.value}`)} {...getTagProps({ index })} />
            ))
          }
          isOptionEqualToValue={(option: PossibleTag, value: PossibleTag) => {
            return option.value === value.value;
          }}
          sx={{ margin: '0 0 30px 0' }}
          getOptionLabel={(option) => t(`shared.categoryTags.withQty.${option.value}`, { qty: option.qty })}
          renderInput={(params) => (
            <TextField
              {...params}
              variant='outlined'
              label={t('shared.autocomplete.categories.label')}
              placeholder={t('shared.autocomplete.categories.placeholder')}
            />
          )}
        />
        <MoviesList movies={filteredMovies} />
      </SectionLayout>
    </Container>
  );
};

interface OwnProps {
  initialTag?: string;
  title: string;
  titlePrefix: string;
  pageTitle: string;
  pageDescription: string;
  keywords?: string;
}
