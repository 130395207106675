export class PaymentItem {
  id: string;
  description: string;
  productId: string;
  amount_discount: number;
  amount_subtotal: number;
  amount_total: number;
  currency: string;

  constructor(
    amount_discount: number,
    amount_subtotal: number,
    amount_total: number,
    currency: string,
    id: string,
    description: string,
    productId: string,
  ) {
    this.amount_discount = amount_discount;
    this.amount_subtotal = amount_subtotal;
    this.amount_total = amount_total;
    this.currency = currency;
    this.id = id;
    this.description = description;
    this.productId = productId;
  }
}
