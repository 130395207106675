import { LoadingButton } from '@mui/lab';
import { Alert, Box, Snackbar, Tab, Tabs } from '@mui/material';
import { Settings } from 'components/Account/Settings/Settings';
import { Shopping } from 'components/Account/Shopping/Shopping';
import { HelmetContent } from 'components/Common/HelmetContent/HelmetContent';
import { User } from 'firebase/auth';
import { firebaseSignOutUser } from 'firestore';
import { SectionLayout } from 'layouts/SectionLayout/SectionLayout';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';
import { scrollTop } from 'utils/Common/scroll-top/scroll-top';
import { Container, TabsContainer } from './Account.styled';

export const Account: React.FC<OwnProps> = ({ user }: { user: User }) => {
  let [searchParams] = useSearchParams();
  const [open, setOpen] = useState<string | null>(searchParams.get('open'));
  const [signOutLoading, setSignOutLoading] = useState<boolean>(false);
  const [signOutError, setSignOutError] = useState<null | string>(null);
  const [signOutSuccess, setSignOutSuccess] = useState<null | string>(null);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    setOpen(searchParams.get('open'));
  }, [searchParams]);

  const handleClickSignOut = async () => {
    try {
      setSignOutSuccess(null);
      setSignOutLoading(true);
      setSignOutError(null);
      await firebaseSignOutUser();
      setSignOutSuccess(t('shared.requestMessages.logOutSuccess'));
    } catch (e: any) {
      setSignOutError(t('shared.requestMessages.logOutError'));
    } finally {
      setSignOutLoading(false);
    }
  };

  useEffect(() => {
    scrollTop();
  }, []);

  return (
    <Container>
      <HelmetContent
        title={t('account.accountSettings.pageTitle')}
        description={t('account.accountSettings.pageDescription')}
      />
      <SectionLayout addStyleBottom={false} title={t('account.title')}>
        <TabsContainer>
          <Tabs value={open} aria-label='nav tabs example'>
            <Tab
              label={t('account.tabs.shopping')}
              value='shopping'
              to={`/${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}/account?open=shopping`}
              component={Link}
            />
            <Tab
              label={t('account.tabs.accountSettings')}
              value='settings'
              to={`/${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}/account?open=settings`}
              component={Link}
            />
          </Tabs>
        </TabsContainer>
        {open === 'shopping' && <Shopping user={user} />}
        {open === 'settings' && <Settings user={user} />}

        {open === 'settings' && (
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <LoadingButton
              loading={signOutLoading}
              onClick={handleClickSignOut}
              fullWidth
              variant='contained'
              sx={{ textTransform: 'none', maxWidth: '300px', margin: '50px 0 0 0' }}>
              {t('shared.buttons.logOut')}
            </LoadingButton>
          </Box>
        )}
      </SectionLayout>

      <Snackbar
        open={!!signOutError}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={() => setSignOutError(null)}>
        <Alert onClose={() => setSignOutError(null)} severity='error' sx={{ width: '100%' }}>
          {signOutError ? signOutError : ''}
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!signOutSuccess}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={() => setSignOutSuccess(null)}>
        <Alert onClose={() => setSignOutSuccess(null)} severity='success' sx={{ width: '100%' }}>
          {signOutSuccess ? signOutSuccess : ''}
        </Alert>
      </Snackbar>
    </Container>
  );
};

interface OwnProps {
  user: User;
}
