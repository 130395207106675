// import { Movie } from 'types/shared/movie-type';

// export const filterPossibleTags = (currentMovies: Movie[]): string[] => {
//   const possibleTags: string[] = [];

//   currentMovies.forEach((currentMovie: Movie) => {
//     currentMovie.categoryTags.forEach((categoryTag: string) => {
//       if (categoryTag !== 'all' && categoryTag !== 'main_slider' && !possibleTags.includes(categoryTag))
//         possibleTags.push(categoryTag);
//     });
//   });

//   return possibleTags;
// };

import { Movie } from 'types/shared/movie-type';

export interface PossibleTag {
  value: string;
  qty: number;
}

export const filterPossibleTags = (currentMovies: Movie[]): PossibleTag[] => {
  const possibleTags: PossibleTag[] = [];

  currentMovies.forEach((currentMovie: Movie) => {
    currentMovie.categoryTags.forEach((categoryTag: string) => {
      if (
        categoryTag !== 'all' &&
        categoryTag !== 'main_slider' &&
        possibleTags.some((possibleTag: PossibleTag) => possibleTag.value === categoryTag)
      ) {
        const elementIndex = possibleTags.findIndex((possibleTag: PossibleTag) => possibleTag.value === categoryTag);
        possibleTags[elementIndex] = { value: categoryTag, qty: ++possibleTags[elementIndex].qty };
      }
      if (
        categoryTag !== 'all' &&
        categoryTag !== 'main_slider' &&
        !possibleTags.some((possibleTag: PossibleTag) => possibleTag.value === categoryTag)
      ) {
        possibleTags.push({ value: categoryTag, qty: 1 });
      }
    });
  });

  return possibleTags;
};
