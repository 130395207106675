import dayjs from 'dayjs';
import { Payment } from 'models/Account/Shopping/payment/payment';
import { Movie } from 'types/shared/movie-type';
import { findSuccessAndValidPayment } from '../find-success-and-valid-payment/find-success-and-valid-payment';

export const checkHowManyHoursLeft = (movie: Movie, payments: Payment[]): number => {
  const foundPayment: Payment | undefined = findSuccessAndValidPayment(movie, payments);
  const TWO_DAYS_IN_MILISECONDS: number = 172800000;
  const paymentDate = dayjs(foundPayment!!.created);
  const todayDate = dayjs();
  const diff = paymentDate.diff(todayDate);
  return Number.parseInt(
    dayjs
      .duration(TWO_DAYS_IN_MILISECONDS - Math.abs(diff))
      .asHours()
      .toString(),
  );
};
