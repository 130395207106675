import { faCartPlus, faSignature } from '@fortawesome/free-solid-svg-icons';
import { Box } from '@mui/material';
import { ImageLoader } from 'components/Common/ImageLoader/ImageLoader';
import { SegmentDescriptor } from 'components/Common/SegmentDecriptor/SegmentDecriptor';
import { MoviesContext } from 'context/MoviesContext';
import { PaymentItem } from 'models/Account/Shopping/payment/payment-item/payment-item';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Movie } from 'types/shared/movie-type';
import { OrderPrice } from './OrderPrice/OrderPrice';
import { Container } from './ProductsListItem.styled';
import { convertMovieTileToDescriptivePath } from 'utils/Common/convert-movie-title-to-descriptive-path/convert-movie-title-to-descriptive-path';

export const ProductsListItem: React.FC<OwnProps> = (props: OwnProps) => {
  const { paymentItem } = props;
  const { movies } = useContext(MoviesContext);
  const { productId, amount_discount, amount_subtotal, amount_total, currency } = paymentItem;
  const [currentMovie] = useState<Movie>(movies.filter((movie: Movie) => movie.productId === productId)[0]);
  const [movieImage] = useState<Record<string, string>>(currentMovie.imageSmall);
  const [movieTitle] = useState<string>(currentMovie.title);
  const [movieId] = useState<string>(currentMovie.id);

  const { t, i18n } = useTranslation();

  return (
    <Container>
      <Box sx={{ display: 'flex' }}>
        <Box
          sx={{ width: '100%', maxWidth: '120px' }}
          to={`/${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}/movies/${convertMovieTileToDescriptivePath(t(movieTitle))}/${movieId}`}
          component={Link}>
          <ImageLoader
            imageFirebasePath={movieImage[i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language]}
          />
        </Box>
        <Box sx={{ padding: '0 0 0 20px' }}>
          <Box>
            <SegmentDescriptor
              icon={faSignature}
              title={t('shared.segmentDescriptor.title')}
              description={t(movieTitle)}
            />

            <SegmentDescriptor
              icon={faCartPlus}
              title={t('shared.segmentDescriptor.purchasePrice')}
              description={
                <OrderPrice
                  amount_discount={amount_discount}
                  amount_subtotal={amount_subtotal}
                  amount_total={amount_total}
                  currency={currency}
                />
              }
            />
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

interface OwnProps {
  paymentItem: PaymentItem;
}
