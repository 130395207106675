import React, { useContext, useEffect, useState } from 'react';
import { Container } from './MyMovies.styled';
import { SectionLayout } from 'layouts/SectionLayout/SectionLayout';
import { MyMoviesList } from 'components/MyMovies/MyMoviesList/MyMoviesList';
import { Movie } from 'types/shared/movie-type';
import { getUserPayments } from 'firestore';
import { checkIfUserPaidForMovie } from 'utils/Movie/check-if-user-paid-for-movie/check-if-user-paid-for-movie';
import { MoviesContext } from 'context/MoviesContext';
import { User } from 'firebase/auth';
import { ErrorScreen } from 'components/Shared/ErrorScreen/ErrorScreen';
import { LoadingScreen } from 'components/Shared/LoadingScreen/LoadingScreen';
import { MyMovie } from 'models/MyMovies/my-movie/my-movie';
import { useTranslation } from 'react-i18next';
import { HelmetContent } from 'components/Common/HelmetContent/HelmetContent';
import { scrollTop } from 'utils/Common/scroll-top/scroll-top';

export const MyMovies: React.FC<OwnProps> = ({ user }: { user: User }) => {
  const { movies } = useContext(MoviesContext);
  const [myMovies, setMyMovies] = useState<null | MyMovie[]>(null);
  const [myMoviesError, setMyMoviesError] = useState<boolean>(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (user) {
      (async () => {
        try {
          const paymentsResponse = await getUserPayments(user.uid);
          if (paymentsResponse) {
            const myMoviesTempArray: MyMovie[] = [];
            movies.forEach((movie: Movie) => {
              if (checkIfUserPaidForMovie(movie, paymentsResponse)) {
                const { id, title, imageSmall } = movie;
                myMoviesTempArray.push(new MyMovie(id, title, imageSmall));
              }
            });
            setMyMovies(myMoviesTempArray);
          }
        } catch (e) {
          console.log(e);
          setMyMoviesError(true);
        }
      })();
    }
  }, [user]);

  useEffect(() => {
    scrollTop();
  }, []);

  if (myMoviesError) return <ErrorScreen errorTitle='Wystąpił błąd z ładowaniem twoich filmów' />;
  if (!myMovies) return <LoadingScreen />;
  return (
    <Container>
      <HelmetContent title={t('myMovies.pageTitle')} description={t('myMovies.pageDescription')} />
      <SectionLayout addStyleBottom={false} titlePrefix={t('myMovies.titlePrefix')} title={t('myMovies.title')}>
        <MyMoviesList myMovies={myMovies} user={user} />
      </SectionLayout>
    </Container>
  );
};

interface OwnProps {
  user: User;
}
