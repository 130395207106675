import { Player } from 'components/Common/Player/Player';
import React from 'react';
import { useTranslation } from 'react-i18next';
import 'react-multi-carousel/lib/styles.css';
import { MovieExtra } from 'types/shared/movie-type';
import { Container } from './MovieExtrasCarousel.styled';

export const MovieExtrasCarousel: React.FC<OwnProps> = (props: OwnProps) => {
  const { movieExtras } = props;

  const { i18n } = useTranslation();

  // const responsive = {
  //   xsUp: {
  //     breakpoint: { max: 4000, min: 0 },
  //     items: 1,
  //   },
  // };

  return (
    <Container>
      <Player
        movieVimeoId={movieExtras[0].src[i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language]}
      />
    </Container>
  );

  // return (
  //   <Container>
  //     <Carousel
  //       containerClass='carousel-container'
  //       draggable={false}
  //       swipeable={false}
  //       itemClass='carousel-item'
  //       responsive={responsive}>
  //       {movieExtras.map((element: MovieExtra) => {
  //         const { src, id } = element;
  //         const { i18n } = useTranslation();

  //         return (
  //           <Player
  //             key={id}
  //             movieVimeoId={src[i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language]}
  //           />
  //         );

  //         // return (
  //         //   <MovieExtraFigure key={id}>
  //         //     <MovieExtraIFrame
  //         //       title={t(name)}
  //         //       allow='autoplay; fullscreen; picture-in-picture; clipboard-write'
  //         //       src={`https://player.vimeo.com/video/${
  //         //         src[i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language]
  //         //       }?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
  //         //     />
  //         //   </MovieExtraFigure>
  //         // );
  //       })}
  //     </Carousel>
  //   </Container>
  // );
};

interface OwnProps {
  movieExtras: MovieExtra[];
}
