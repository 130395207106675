import React from 'react';
import { Container } from './ErrorScreen.styled';
import { Typography } from '@mui/material';

export const ErrorScreen: React.FC<OwnProps> = ({ errorTitle, errorDescription }) => {
  return (
    <Container>
      <Typography sx={{ margin: '0 0 5px 0' }} color='text.primary' component='h1' fontSize='36px'>
        {errorTitle}
      </Typography>
      <Typography color='text.secondary' component='p' fontSize='20px'>
        {errorDescription}
      </Typography>
    </Container>
  );
};

interface OwnProps {
  errorTitle: string;
  errorDescription?: string;
}
