import { ImageLoader } from 'components/Common/ImageLoader/ImageLoader';
import { Title } from 'components/Common/Title/Title';
import React from 'react';
import { useTranslation } from 'react-i18next';
import MoviesCarouselSlider, { ResponsiveType } from 'react-multi-carousel';
import { TitleOptionEnum } from 'types/common/Title/title-option.type';
import { MoviePreview } from 'types/shared/movie-type';
import { Container, MovieStyled } from './MoviesCarousel.styled';
import { convertMovieTileToDescriptivePath } from 'utils/Common/convert-movie-title-to-descriptive-path/convert-movie-title-to-descriptive-path';
import { NavLink } from 'react-router-dom';

export const MoviesCarousel: React.FC<OwnProps> = (props) => {
  const { category, movies, xlItemsAmount = 9.2, path } = props;
  const { i18n, t } = useTranslation();

  const responsive: ResponsiveType = {
    xl: {
      breakpoint: { max: 4000, min: 1536 },
      items: xlItemsAmount,
    },
    lg: {
      // the naming can be any, depends on you.
      breakpoint: { max: 1535, min: 1200 },
      items: 6,
    },
    md: {
      breakpoint: { max: 1199, min: 900 },
      items: 4,
    },
    sm: {
      breakpoint: { max: 899, min: 600 },
      items: 4,
    },
    xs: {
      breakpoint: { max: 599, min: 0 },
      items: 3.2,
    },
  };

  return (
    <Container>
      <NavLink
        style={{ width: 'fit-content', display: 'inline-block' }}
        to={`/${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}/${path}`}>
        <Title
          hoverAnimation
          hoverAnimationText={t('shared.buttons.seeAll')}
          title={category}
          addIcons={true}
          titleOption={TitleOptionEnum.SMALL}
        />
      </NavLink>
      <MoviesCarouselSlider
        itemClass='carousel-item'
        containerClass='carousel-container'
        infinite
        swipeable
        draggable
        minimumTouchDrag={25}
        removeArrowOnDeviceType={['xs', 'sm', 'md']}
        responsive={responsive}>
        {movies.map((element: MoviePreview) => (
          <MovieStyled
            aria-label={t(element.title)}
            key={element.id}
            to={`/${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}/movies/${convertMovieTileToDescriptivePath(t(element.title))}/${element.id}`}>
            <ImageLoader
              imageFirebasePath={
                element.imageSmall[i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language]
              }
            />
          </MovieStyled>
        ))}
      </MoviesCarouselSlider>
    </Container>
  );
};

interface OwnProps {
  category: string;
  movies: MoviePreview[];
  xlItemsAmount?: number;
  path: string;
}
