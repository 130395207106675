import React, { ReactNode } from 'react';
import { Container, Title, Description, Wrapper } from './SegmentDecriptor.styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useMediaQuery, useTheme } from '@mui/material';

export const SegmentDescriptor: React.FC<OwnProps> = (props: OwnProps) => {
  const { title, description, icon, segmentDescriptorSize = 'large' } = props;

  const theme = useTheme();
  const xlUp = useMediaQuery(theme.breakpoints.up('xl'));
  return (
    <Container segmentDescriptorSize={segmentDescriptorSize}>
      <Wrapper segmentDescriptorSize={segmentDescriptorSize}>
        <FontAwesomeIcon
          style={{ margin: '0 10px 0 0' }}
          color={theme.palette.common.white}
          size={segmentDescriptorSize === 'large' ? (xlUp ? 'xl' : 'lg') : xlUp ? 'lg' : 'lg'}
          icon={icon}
        />
        <Title segmentDescriptorSize={segmentDescriptorSize}>{title}</Title>
      </Wrapper>
      <Description segmentDescriptorSize={segmentDescriptorSize}>{description}</Description>
    </Container>
  );
};

interface OwnProps {
  title: string;
  description: string | ReactNode;
  icon: IconProp;
  segmentDescriptorSize?: 'small' | 'large';
}
