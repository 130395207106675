import { faSignature } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';
import { ImageLoader } from 'components/Common/ImageLoader/ImageLoader';
import { Title } from 'components/Common/Title/Title';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ActorsCarouselSlider from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { TitleOptionEnum } from 'types/common/Title/title-option.type';
import { Actor, Role } from 'types/shared/actor.type';
import { ActorDescription, ActorStyled, Container, TextModifier, Wrapper1, Wrapper2 } from './ActorsCarousel.styled';

export const ActorsCarousel: React.FC<OwnProps> = (props) => {
  const { category, actors, movieId } = props;
  const { t } = useTranslation();

  const responsive = {
    xl: {
      breakpoint: { max: 4000, min: 1536 },
      items: 6,
    },
    lg: {
      // the naming can be any, depends on you.
      breakpoint: { max: 1535, min: 1200 },
      items: 5,
    },
    md: {
      breakpoint: { max: 1199, min: 900 },
      items: 4,
      partialVisibilityGutter: 40,
    },
    sm: {
      breakpoint: { max: 899, min: 600 },
      items: 3,
    },
    xs: {
      breakpoint: { max: 599, min: 0 },
      items: 1.8,
    },
  };

  return (
    <Container>
      <Title title={category} addIcons={true} titleOption={TitleOptionEnum.SMALL} />
      <ActorsCarouselSlider
        itemClass='carousel-item'
        containerClass='carousel-container'
        infinite
        swipeable
        draggable
        removeArrowOnDeviceType={['xs', 'md']}
        responsive={responsive}>
        {actors.map((element: Actor) => (
          <ActorStyled key={element.id}>
            <ImageLoader imageFirebasePath={element.image} />
            <ActorDescription>
              <Wrapper1>
                <FontAwesomeIcon style={{ margin: '0 10px 0 0' }} icon={faSignature} />
                <Typography color='text.primary'>
                  <Trans i18nKey='movie.actors.sliderItem.description' components={[<TextModifier key='0' />]} />
                </Typography>
              </Wrapper1>
              <Wrapper2>
                <Typography color='text.secondary'>
                  <Trans
                    i18nKey='movie.actors.sliderItem.descriptionValue'
                    values={{
                      name: element.name,
                      surname: element.surname,
                      role: t(element.playedRoles.find((role: Role) => role.movieId === movieId)?.character as string),
                    }}
                    components={[<TextModifier key='0' />]}
                  />
                </Typography>
              </Wrapper2>
            </ActorDescription>
          </ActorStyled>
        ))}
      </ActorsCarouselSlider>
    </Container>
  );
};

interface OwnProps {
  category: string;
  actors: Actor[];
  movieId: string;
}
