import { Button, styled } from '@mui/material';

export const Container = styled('section')(({ theme }) => ({
  width: '100%',
  padding: '30px 20px',
  background: theme.palette.background.default,

  [theme.breakpoints.up('sm')]: {
    padding: '30px 60px',
  },

  [theme.breakpoints.up('md')]: {
    padding: '50px 80px',
  },

  [theme.breakpoints.up('xl')]: {
    display: 'flex',
    padding: '100px 120px',
    justifyContent: 'space-between',
  },
}));

export const Box1 = styled('div')(({ theme }) => ({
  margin: '0 0 40px 0',
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    flex: '1 0 100%',
    margin: '0 0 85px 0',
  },

  [theme.breakpoints.up('lg')]: {
    margin: '0 0 135px 0',
  },

  [theme.breakpoints.up('xl')]: {
    flex: '0 0 60%',
    margin: '0',
  },
}));

export const Wrapper = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    padding: '0 0 0 50px',
    display: 'flex',
    flexDirection: 'column-reverse',
    justifyContent: 'flex-end',
  },

  [theme.breakpoints.up('xl')]: {
    maxWidth: '370px',
  },
}));

export const CustomButton = styled(Button)({
  width: '100%',
  margin: '0 0 20px 0',
  textTransform: 'none',

  '&:last-of-type': {
    margin: 0,
  },
});

export const ImageWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: '430px',
  margin: '0 0 40px 0',
  borderRadius: '5px',
  boxShadow: theme.shadows[10],
  overflow: 'hidden',
  height: 'fit-content',

  [theme.breakpoints.up('xl')]: {
    margin: '0',
    minWidth: '420px',
  },
}));

export const Image = styled('img')({
  width: '100%',
});

export const ButtonsContainer = styled('div')(({ theme }) => ({
  margin: '0 0 50px 0',

  [theme.breakpoints.up('lg')]: {
    margin: 0,
  },
}));

export const ButtonsWrapper = styled('div')(() => ({
  margin: '0 0 50px 0',
}));

export const SegmentsWrapper = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    margin: '0 0 50px 0',
  },
}));

export const Box2 = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('xl')]: {
    flex: '0 0 40%',
    position: 'relative',
  },
}));

export const TitleWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  margin: '0 0 60px 0',

  [theme.breakpoints.up('xl')]: {
    justifyContent: 'start',
    margin: '0 0 30px 0',
  },
}));

export const ExtraBackgroundWrapper = styled('div')(() => ({
  backgroundImage: 'url("/assets/images/backgrounds/background_image_3.svg")',
  backgroundPositionX: '0',
  backgroundPositionY: '0',
  width: '100%',
  height: '100%',

  backgroundRepeat: 'no-repeat',
  zIndex: 0,
  position: 'absolute',
  top: '230px',
  left: '-80px',
}));

export const Title = styled('h1')(({ theme }) => ({
  fontSize: '30px',
  fontWeight: '500',
  padding: '0 0 10px 0',
  color: theme.palette.common.white,
  borderBottom: `3px solid ${theme.palette.primary.main}`,

  [theme.breakpoints.up('lg')]: {
    fontSize: '36px',
  },
}));

export const Figure = styled('figure')(({ theme }) => ({
  height: 0,
  background: theme.palette.common.black,
  paddingBottom: '56.25%',
  position: 'relative',
  width: '100%',
  borderRadius: '10px',
  overflow: 'hidden',
  boxShadow: theme.shadows[10],
}));

export const IFrame = styled('iframe')(() => ({
  height: '100%',
  left: 0,
  position: 'absolute',
  top: 0,
  width: '100%',
}));
