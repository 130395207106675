import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowsRotate,
  faCheck,
  faCompress,
  faExpand,
  faGear,
  faPause,
  faPlay,
  faRotateLeft,
  faRotateRight,
  faVolumeHigh,
  faVolumeXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  IconButton,
  ListItemText,
  MenuItem,
  MenuList,
  Slider,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import Vimeo from '@vimeo/player';
import { StyledMenu } from 'components/Common/Header/Header.styled';
import { DATA_BASE_64_STRING_CONTENT_16_9 } from 'components/Common/ImageLoader/ImageLoader';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDuration } from 'utils/Player/formatDuration/formatDuration';
import {
  BackTenSecondsBadge,
  BottomControls,
  CenterControls,
  CenterControlsLeft,
  CenterControlsMiddle,
  CenterControlsRight,
  Container,
  ControlsLayer,
  ExtraLayer,
  ForwardTenSecondsBadge,
  TopControls,
  VideoWrapper,
  VideoWrapper2,
  VideoWrapper3,
  VolumeSlider,
} from './Player.styled';
import { isIOS } from 'react-device-detect';

export const Player: React.FC<OwnProps> = (props) => {
  const { movieVimeoId } = props;
  const playerRef = useRef<HTMLDivElement>(null);
  const player = useRef<Vimeo>(null);
  const playPauseButton = useRef<HTMLButtonElement>(null);
  const videoContainerRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();

  // const getMovieAudio = (movie: Movie, language: string): string => {
  //   const movieSrcObject = movie.movieSrc.find(
  //     (movieSrc) => movieSrc.language === (language.includes('-') ? language.split('-')[0] : language),
  //   );
  //   return movieSrcObject ? movieSrcObject.language : '';
  // };
  const [initialMovieVimeoId, setInitialMovieVimeoId] = useState<number>(movieVimeoId);
  const [isVideoBuffering, setIsVideoBuffering] = useState<boolean>(false);
  const [isVideoLoaded, setIsVideoLoaded] = useState<boolean>(false);
  const [isVideoPlayed, setIsVideoPlayed] = useState<boolean>(false);
  const [isVideoEnded, setIsVideoEnded] = useState<boolean>(false);
  const [videoCurrentTime, setVideoCurrentTime] = useState<number>(0);
  const [videoDuration, setVideoDuration] = useState<number>(0);
  const [volume, setVolume] = useState<number>(1);
  const [isSoundMuted, setIsSoundMuted] = useState<boolean>(false);
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
  const [displayControls, setDisplayControls] = useState<boolean>(false);
  const [lastTimeoutId, setLastTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const [currentTimeoutId, setCurrentTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const [, setLastSliderTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const [, setCurrentSliderTimeoutId] = useState<NodeJS.Timeout | null>(null);
  // const [subtitle, setSubTitle] = useState<string>('off');
  // const [subtitles, setSubtitles] = useState<Vimeo.VimeoTextTrack[] | null>(null);
  const [qualities, setQualities] = useState<null | Vimeo.VimeoVideoQualityObject[]>(null);
  const [quality, setQuality] = useState<Vimeo.VimeoVideoQuality | null>(null);
  const [iconFontSize, setIconFontSize] = useState<SizeProp>('sm');

  const [showMiddleControlLeftAnimation, setShowMiddleControlLeftAnimation] = useState<boolean>(false);
  const [lastMiddleControlLeftTimeoutId, setLastMiddleControlLeftTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const [currentMiddleControlLeftTimeoutId, setCurrentMiddleControlLeftTimeoutId] = useState<NodeJS.Timeout | null>(
    null,
  );

  const [showMiddleControlCenterAnimation, setShowMiddleControlCenterAnimation] = useState<boolean>(false);
  const [lastMiddleControlCenterTimeoutId, setLastMiddleControlCenterTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const [currentMiddleControlCenterTimeoutId, setCurrentMiddleControlCenterTimeoutId] = useState<NodeJS.Timeout | null>(
    null,
  );

  const [showMiddleControlRightAnimation, setShowMiddleControlRightAnimation] = useState<boolean>(false);
  const [lastMiddleControlRightTimeoutId, setLastMiddleControlRightTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const [currentMiddleControlRightTimeoutId, setCurrentMiddleControlRightTimeoutId] = useState<NodeJS.Timeout | null>(
    null,
  );

  const leftPendingClick = useRef<NodeJS.Timeout | null>(null);
  const rightPendingClick = useRef<NodeJS.Timeout | null>(null);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));

  // const [subtitlesAndAudioAnchorEl, setSubtitlesAndAudioAnchorEl] = useState<null | HTMLElement>(null);
  // const [isSubtitlesAndAudioOpen, setIsSubtitlesAndAudioOpen] = useState<boolean>(false);

  const [qualitiesListAnchorEl, setQualitiesListAnchorEl] = useState<null | HTMLElement>(null);
  const [isQualitiesListOpen, setIsQualitiesListOpen] = useState<boolean>(false);

  const handleStartVideo = async () => {
    if (player.current) {
      try {
        await Promise.all([player.current.play()]);
        setIsVideoPlayed(true);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleWatchAgain = () => {
    if (player.current) {
      player.current.play();
      setIsVideoPlayed(true);
      setIsVideoEnded(false);
    }
  };

  const handleStopVideo = async () => {
    if (player.current) {
      try {
        await Promise.all([player.current.pause()]);
        setIsVideoPlayed(false);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleUpdateTime = (currentTimeInSeconds: number) => {
    if (player.current) {
      setVideoCurrentTime(currentTimeInSeconds);
    }
  };

  const handleLoaded = async (playerInit: Vimeo) => {
    if (playerInit) {
      try {
        const [duration, volume] = await Promise.all([playerInit.getDuration(), playerInit.setVolume(0.7)]);
        if (typeof duration === 'number' && typeof volume === 'number') {
          setVideoDuration(duration);
          setVolume(volume);
          setIsVideoLoaded(true);
          setIsVideoPlayed(false);
          setIsSoundMuted(false);
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleChangeSlider = async (event: Event, newValue: number | number[]) => {
    if (player.current && typeof newValue === 'number') {
      try {
        setVideoCurrentTime(newValue);

        const currentTime = await player.current.setCurrentTime(newValue);
        if (currentTime) {
          const sliderTimeoutId = setTimeout(() => {
            setLastSliderTimeoutId(sliderTimeoutId);
          }, 2000);
          setCurrentSliderTimeoutId(sliderTimeoutId);

          await player.current.play();
          setIsVideoEnded(false);
          setIsVideoPlayed(true);
          return () => clearTimeout(sliderTimeoutId);
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleVideoEnded = async () => {
    setIsVideoEnded(true);
  };

  const handleClickBackTenSeconds = async () => {
    if (player.current && videoCurrentTime - 10 >= 0) {
      const currentTimeResponse = await player.current.setCurrentTime(videoCurrentTime - 10);

      if (currentTimeResponse) {
        setShowMiddleControlLeftAnimation(true);
        await player.current.play();
        setIsVideoEnded(false);
        setIsVideoPlayed(true);
      }
    }
  };

  const handleClickForwardTenSeconds = async () => {
    if (player.current && videoCurrentTime + 10 <= videoDuration) {
      const currentTimeResponse = await player.current.setCurrentTime(videoCurrentTime + 10);

      if (currentTimeResponse) {
        setShowMiddleControlRightAnimation(true);
        await player.current.play();
        setIsVideoEnded(false);
        setIsVideoPlayed(true);
      }
    }
  };

  const handleChangeVolumeSlider = async (event: Event, newValue: number | number[]) => {
    if (player.current && typeof newValue === 'number') {
      try {
        setVolume(newValue);
        await player.current.setVolume(newValue);
        if (newValue === 0) {
          await player.current.setMuted(true);
          return setIsSoundMuted(true);
        }
        await player.current.setMuted(false);
        return setIsSoundMuted(false);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleToggleMuteAudio = async () => {
    if (player.current) {
      try {
        const volume: number = await player.current.getVolume();
        if (typeof volume === 'number') {
          const volumeFromPromise = await player.current.setVolume(volume != 0 ? 0 : 0.7);
          if (typeof volumeFromPromise === 'number') {
            const mutedResponse = await player.current.setMuted(volume != 0 ? true : false);
            if (typeof mutedResponse === 'boolean') {
              setVolume(volume != 0 ? 0 : 0.7);
              setIsSoundMuted(volume != 0 ? true : false);
            }
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleToggleFullScreen = async () => {
    if (videoContainerRef.current && player.current) {
      try {
        if (document.fullscreenElement === null) {
          if (isIOS) return player.current.requestFullscreen();
          return videoContainerRef.current.requestFullscreen();
        }
        if (isIOS) {
          player.current.exitFullscreen();
          return setIsVideoPlayed(false);
        }
        return document.exitFullscreen();
      } catch (e) {
        console.log(e);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('fullscreenchange', () => {
      if (document.fullscreenElement === null) {
        setIconFontSize('sm');
        return setIsFullscreen(false);
      }
      setIconFontSize('1x');
      return setIsFullscreen(true);
    });
  }, []);

  // const handleOpenSubtitlesAndAudio = (event: React.MouseEvent<HTMLElement>) => {
  //   setSubtitlesAndAudioAnchorEl(event.currentTarget);
  //   setIsSubtitlesAndAudioOpen(true);
  // };

  // const handleCloseSubtitlesAndAudio = () => {
  //   setSubtitlesAndAudioAnchorEl(null);
  //   setIsSubtitlesAndAudioOpen(false);
  // };

  const handleOpenQualitiesList = (event: React.MouseEvent<HTMLElement>) => {
    setQualitiesListAnchorEl(event.currentTarget);
    setIsQualitiesListOpen(true);
  };

  const handleCloseQualitieslist = () => {
    setQualitiesListAnchorEl(null);
    setIsQualitiesListOpen(false);
  };

  const handleMouseMove = () => {
    setDisplayControls(true);
    const timeoutId = setTimeout(() => {
      setLastTimeoutId(timeoutId);
    }, 4000);
    setCurrentTimeoutId(timeoutId);

    return () => clearTimeout(timeoutId);
  };

  const handleMouseLeaveFromTheExtraLayer = () => {
    setDisplayControls(false);
    handleCloseQualitieslist();
    // handleCloseSubtitlesAndAudio();
  };

  // const handleChangeSubtitle = async (language: string) => {
  //   if (player.current) {
  //     if (language === 'off') {
  //       return player.current.disableTextTrack().then(async () => {
  //         setSubTitle(language);
  //       });
  //     }

  //     player.current.enableTextTrack(language).then(() => {
  //       setSubTitle(language);
  //     });
  //   }
  // };

  // const handleChangeAudio = async (langauge: string) => {
  //   const movieSrcObject = getMovieVimeoId(movie as Movie, langauge);
  //   setAudio(langauge);
  //   if (langauge === audio) {
  //     return handleCloseSubtitlesAndAudio();
  //   }

  //   if (movieSrcObject) {
  //     if (player.current) {
  //       player.current.loadVideo(movieSrcObject).then((videoIdResponse) => {
  //         setMovieVimeoId(videoIdResponse);
  //         const promiseTasks: Promise<any>[] = [player.current!!.setCurrentTime(videoCurrentTime)];
  //         if (subtitle !== 'off') {
  //           promiseTasks.push(player.current!!.enableTextTrack(subtitle));
  //         }

  //         Promise.all(promiseTasks).then(() => {
  //           if (player.current) {
  //             player.current.play();
  //             handleCloseSubtitlesAndAudio();
  //           }
  //         });
  //       });
  //     }
  //   }
  // };

  const handleChangeQuality = async (quality: Vimeo.VimeoVideoQuality) => {
    if (player.current) {
      try {
        setQuality(quality);
        await player.current.setQuality(quality);
        handleCloseQualitieslist();
      } catch (e) {
        console.log(e);
      }
    }
  };

  useEffect(() => {
    (async () => {
      let options: Vimeo.Options = {
        id: movieVimeoId,
        loop: false,
        quality: 'auto',
        dnt: true,
        autoplay: false,
        controls: false,
        autopause: true,
        muted: true,
        responsive: true,
      };
      if (playerRef.current !== null) {
        const playerInit = new Vimeo(playerRef.current, options);
        // @ts-ignore
        player.current = playerInit;

        if (movieVimeoId !== initialMovieVimeoId) {
          playerInit
            .loadVideo(movieVimeoId)
            .then(function () {
              setInitialMovieVimeoId(movieVimeoId);
            })
            .catch(function (error) {
              switch (error.name) {
                case 'TypeError':
                  // the id was not a number
                  break;

                case 'PasswordError':
                  // the video is password-protected and the viewer needs to enter the
                  // password first
                  break;

                case 'PrivacyError':
                  // the video is password-protected or private
                  break;

                default:
                  // some other error occurred
                  break;
              }
            });
        }

        playerInit.getQualities().then((qualities: Vimeo.VimeoVideoQualityObject[]) => {
          setQualities(qualities);
        });

        playerInit.setQuality('auto').then(function (quality) {
          setQuality(quality);
        });

        playerInit.setVolume(0.7);

        // playerInit.getTextTracks().then((textTracks: Vimeo.VimeoTextTrack[]) => {
        //   setSubtitles(textTracks);
        // });

        // if (subtitle === 'off') {
        //   playerInit.disableTextTrack().then(() => setSubTitle('off'));
        // } else {
        //   playerInit.enableTextTrack(subtitle).then((enabledTextTrack) => setSubTitle(enabledTextTrack.language));
        // }

        playerInit.setCurrentTime(videoCurrentTime).then(function () {});

        playerInit.on('timeupdate', function (time: Vimeo.TimeEvent) {
          handleUpdateTime(time.seconds);
        });

        playerInit.on('bufferstart', async () => {
          setIsVideoBuffering(true);
        });

        playerInit.on('play', async () => {
          setIsVideoPlayed(true);
        });

        playerInit.on('pause', async () => {
          setIsVideoPlayed(false);
        });

        playerInit.on('bufferend', async () => {
          setIsVideoBuffering(false);
        });

        playerInit.on('ended', () => {
          handleVideoEnded();
        });

        playerInit.on('loaded', () => {
          handleLoaded(playerInit);
        });
      }
    })();
  }, [playerRef.current, movieVimeoId]);

  useEffect(() => {
    if (currentTimeoutId === lastTimeoutId && currentTimeoutId !== null && lastTimeoutId !== null && isVideoPlayed) {
      setDisplayControls(false);
      handleCloseQualitieslist();
      // handleCloseSubtitlesAndAudio();
    }
    // probably isVideoLoaded because somethings video is loading and we have to reset it
  }, [currentTimeoutId, lastTimeoutId, isVideoLoaded]);

  useEffect(() => {
    if (
      currentMiddleControlRightTimeoutId === lastMiddleControlRightTimeoutId &&
      currentMiddleControlRightTimeoutId !== null &&
      lastMiddleControlRightTimeoutId !== null
    ) {
      setShowMiddleControlRightAnimation(false);
    }
    // probably isVideoLoaded because somethings video is loading and we have to reset it
  }, [
    currentMiddleControlRightTimeoutId,
    lastMiddleControlRightTimeoutId,
    isVideoLoaded,
    isVideoPlayed,
    isVideoBuffering,
    videoCurrentTime,
  ]);

  useEffect(() => {
    if (
      currentMiddleControlLeftTimeoutId === lastMiddleControlLeftTimeoutId &&
      currentMiddleControlLeftTimeoutId !== null &&
      lastMiddleControlLeftTimeoutId !== null
    ) {
      setShowMiddleControlLeftAnimation(false);
    }
  }, [
    currentMiddleControlLeftTimeoutId,
    lastMiddleControlLeftTimeoutId,
    isVideoLoaded,
    isVideoPlayed,
    isVideoBuffering,
    videoCurrentTime,
  ]);

  useEffect(() => {
    if (
      currentMiddleControlCenterTimeoutId === lastMiddleControlCenterTimeoutId &&
      currentMiddleControlCenterTimeoutId !== null &&
      lastMiddleControlCenterTimeoutId !== null
    ) {
      setShowMiddleControlCenterAnimation(false);
    }
  }, [currentMiddleControlCenterTimeoutId, lastMiddleControlCenterTimeoutId, isVideoLoaded]);

  useEffect(() => {
    return () => {
      if (player.current) {
        player.current.destroy();
      }
    };
  }, []);

  const qualityResolver = (quality: Vimeo.VimeoVideoQuality) => {
    switch (quality) {
      case 'auto':
        return `player.quality.adaptive.label`;
      case '1080p':
        return `Full HD (1080p)`;
      case '720p':
        return `HD (720p)`;
      case '540p':
        return `SD (540p)`;
      default:
        return '';
    }
  };

  const toggleDisplayControls = () =>
    setDisplayControls((prevState: boolean) => {
      return !prevState;
    });

  const handleClickMiddleLeftControls = (event: React.MouseEvent<HTMLDivElement>) => {
    if (leftPendingClick.current) {
      clearTimeout(leftPendingClick.current);
    }

    switch (event.detail) {
      case 1:
        leftPendingClick.current = setTimeout(() => {
          if (!mdUp) return toggleDisplayControls();
          isVideoEnded ? handleWatchAgain() : isVideoPlayed ? handleStopVideo() : handleStartVideo();
        }, 700);
        break;
      case 2:
        if (player.current && videoCurrentTime - 10 >= 0) {
          handleClickBackTenSeconds();
          const middleControlLeftTimeoutId = setTimeout(() => {
            setLastMiddleControlLeftTimeoutId(middleControlLeftTimeoutId);
          }, 700);
          setCurrentMiddleControlLeftTimeoutId(middleControlLeftTimeoutId);
          return () => clearTimeout(middleControlLeftTimeoutId);
        }
        break;
      case 3:
        if (player.current && videoCurrentTime - 10 >= 0) {
          handleClickBackTenSeconds();
          const middleControlLeftTimeoutId = setTimeout(() => {
            setLastMiddleControlLeftTimeoutId(middleControlLeftTimeoutId);
          }, 700);
          setCurrentMiddleControlLeftTimeoutId(middleControlLeftTimeoutId);
          return () => clearTimeout(middleControlLeftTimeoutId);
        }
        break;
      default:
        if (player.current && videoCurrentTime - 10 >= 0) {
          handleClickBackTenSeconds();
          const middleControlLeftTimeoutId = setTimeout(() => {
            setLastMiddleControlLeftTimeoutId(middleControlLeftTimeoutId);
          }, 700);
          setCurrentMiddleControlLeftTimeoutId(middleControlLeftTimeoutId);
          return () => clearTimeout(middleControlLeftTimeoutId);
        }
        break;
    }
  };

  const handleClickMiddleCenterControls = (event: React.MouseEvent<HTMLDivElement>) => {
    switch (event.detail) {
      default:
        (() => {
          if (!mdUp) return toggleDisplayControls();
          if (isVideoBuffering) return;
          isVideoEnded ? handleWatchAgain() : isVideoPlayed ? handleStopVideo() : handleStartVideo();
          setShowMiddleControlCenterAnimation(true);
          // @ts-ignore
          const middleControlCenterTimeoutId = setTimeout(() => {
            setLastMiddleControlCenterTimeoutId(middleControlCenterTimeoutId);
          }, 1500);

          setCurrentMiddleControlCenterTimeoutId(middleControlCenterTimeoutId);
          return () => clearTimeout(middleControlCenterTimeoutId);
        })();
    }
  };

  const handleClickMiddleRightControls = (event: React.MouseEvent<HTMLDivElement>) => {
    if (rightPendingClick.current) {
      clearTimeout(rightPendingClick.current);
    }

    switch (event.detail) {
      case 1:
        rightPendingClick.current = setTimeout(() => {
          if (!mdUp) return toggleDisplayControls();
          isVideoEnded ? handleWatchAgain() : isVideoPlayed ? handleStopVideo() : handleStartVideo();
        }, 700);
        break;
      case 2:
        if (player.current && videoCurrentTime + 10 <= videoDuration) {
          handleClickForwardTenSeconds();
          const middleControlRightTimeoutId = setTimeout(() => {
            setLastMiddleControlRightTimeoutId(middleControlRightTimeoutId);
          }, 700);
          setCurrentMiddleControlRightTimeoutId(middleControlRightTimeoutId);
          return () => clearTimeout(middleControlRightTimeoutId);
        }
        break;
      case 3:
        if (player.current && videoCurrentTime + 10 <= videoDuration) {
          handleClickForwardTenSeconds();
          const middleControlRightTimeoutId = setTimeout(() => {
            setLastMiddleControlRightTimeoutId(middleControlRightTimeoutId);
          }, 700);
          setCurrentMiddleControlRightTimeoutId(middleControlRightTimeoutId);
          return () => clearTimeout(middleControlRightTimeoutId);
        }
        break;
      default:
        if (player.current && videoCurrentTime + 10 <= videoDuration) {
          handleClickForwardTenSeconds();
          const middleControlRightTimeoutId = setTimeout(() => {
            setLastMiddleControlRightTimeoutId(middleControlRightTimeoutId);
          }, 700);
          setCurrentMiddleControlRightTimeoutId(middleControlRightTimeoutId);
          return () => clearTimeout(middleControlRightTimeoutId);
        }
        break;
    }
  };

  const eventHandler = (event: React.KeyboardEvent<HTMLDivElement>) => {
    event.stopPropagation();
    if (mdUp) handleMouseMove();
    switch (event.key) {
      case 'ArrowDown':
        // Do something for "down arrow" key press.
        break;
      case 'ArrowUp':
        // Do something for "up arrow" key press.
        break;
      case 'ArrowLeft':
        if (player.current && videoCurrentTime - 10 >= 0) {
          handleClickBackTenSeconds();
          const middleControlLeftTimeoutId = setTimeout(() => {
            setLastMiddleControlLeftTimeoutId(middleControlLeftTimeoutId);
          }, 700);
          setCurrentMiddleControlLeftTimeoutId(middleControlLeftTimeoutId);
          return () => clearTimeout(middleControlLeftTimeoutId);
        }
        break;
      case 'ArrowRight':
        if (player.current && videoCurrentTime + 10 <= videoDuration) {
          handleClickForwardTenSeconds();
          const middleControlRightTimeoutId = setTimeout(() => {
            setLastMiddleControlRightTimeoutId(middleControlRightTimeoutId);
          }, 700);
          setCurrentMiddleControlRightTimeoutId(middleControlRightTimeoutId);
          return () => clearTimeout(middleControlRightTimeoutId);
        }
        break;
      case 'f':
        handleToggleFullScreen();
        break;
      case 'F':
        handleToggleFullScreen();
        break;
      case 'Enter':
        // Do something for "enter" or "return" key press.
        break;
      case ' ':
        isVideoEnded ? handleWatchAgain() : isVideoPlayed ? handleStopVideo() : handleStartVideo();
        setShowMiddleControlCenterAnimation(true);
        setTimeout(() => {
          setShowMiddleControlCenterAnimation(false);
        }, 1500);
        break;
      case 'Escape':
        // Do something for "Escape" or "return" key press.
        break;
      default:
        return; // Quit when this doesn't handle the key event.
    }
  };

  return (
    <Container
      isFullscreen={isFullscreen}
      onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => eventHandler(event)}>
      <VideoWrapper ref={videoContainerRef}>
        <VideoWrapper2>
          <VideoWrapper3>
            <div
              style={{
                width: '100%',
                position: 'absolute',
              }}
              ref={playerRef}></div>
            <img
              style={{
                width: '100%',
              }}
              alt='DATA_BASE_64_STRING_CONTENT_16_9'
              src={`data:image/webp;base64,${DATA_BASE_64_STRING_CONTENT_16_9}`}
            />
          </VideoWrapper3>
        </VideoWrapper2>
        {isVideoLoaded && (
          <ExtraLayer
            onMouseLeave={mdUp ? handleMouseLeaveFromTheExtraLayer : () => {}}
            onMouseMove={mdUp ? handleMouseMove : () => {}}>
            <ControlsLayer displayControls={displayControls}>
              <TopControls>
                <Box sx={{ display: 'flex', alignItems: 'center', width: '60%' }}></Box>
                <Box>
                  {/* <IconButton
                    title={t('player.audioAndSubtitles.label')}
                    sx={{ margin: '0 7px 0 0' }}
                    onClick={handleOpenSubtitlesAndAudio}
                    id='subtitles-and-audio-list-button'
                    aria-controls={isSubtitlesAndAudioOpen ? 'subtitles-and-audio-list-menu' : undefined}
                    aria-expanded={isSubtitlesAndAudioOpen ? 'true' : undefined}
                    aria-haspopup='true'
                    size='medium'>
                    <FontAwesomeIcon size={iconFontSize} icon={faClosedCaptioning} />
                  </IconButton>
                  <StyledMenu
                    container={videoContainerRef.current}
                    anchorEl={subtitlesAndAudioAnchorEl}
                    MenuListProps={{
                      'aria-labelledby': 'subtitles-and-audio-list-button',
                    }}
                    id='audio-and-subtitles-menu'
                    open={isSubtitlesAndAudioOpen}
                    onClose={handleCloseSubtitlesAndAudio}>
                    <Stack direction='row'>
                      <Box>
                        <Typography fontSize='18px' fontWeight={700} variant='h4' color='text.primary'>
                          {t('player.subtitles.label')}
                        </Typography>
                        {subtitles && (
                          <MenuList sx={{ maxWidth: '350px', maxHeight: '400px' }}>
                            <MenuItem onClick={() => handleChangeSubtitle('off')} key='off' value='off'>
                              {subtitle === 'off' && (
                                <ListItemIcon>
                                  <FontAwesomeIcon icon={faCheck} />
                                </ListItemIcon>
                              )}
                              <ListItemText
                                disableTypography
                                sx={{ fontWeight: subtitle === 'off' ? 700 : 300 }}
                                inset={subtitle !== 'off'}>
                                {t('shared.select.off')}
                              </ListItemText>
                            </MenuItem>
                            {subtitles.map((subtitleOption: Vimeo.VimeoTextTrack) => (
                              <MenuItem
                                onClick={() => handleChangeSubtitle(subtitleOption.language)}
                                key={subtitleOption.language}
                                value={subtitleOption.language}>
                                {subtitle === subtitleOption.language && (
                                  <ListItemIcon>
                                    <FontAwesomeIcon icon={faCheck} />
                                  </ListItemIcon>
                                )}
                                <ListItemText
                                  disableTypography
                                  sx={{ fontWeight: subtitle === subtitleOption.language ? 700 : 400 }}
                                  inset={subtitle !== subtitleOption.language}>
                                  {subtitleOption.label}
                                </ListItemText>
                              </MenuItem>
                            ))}
                          </MenuList>
                        )}
                      </Box>
                    </Stack>
                  </StyledMenu> */}
                  <IconButton
                    aria-label='quality'
                    title={t('player.quality.label')}
                    sx={{ margin: '0 7px 0 0' }}
                    onClick={handleOpenQualitiesList}
                    id='qualities-list-button'
                    aria-controls={isQualitiesListOpen ? 'qualities-list-menu' : undefined}
                    aria-expanded={isQualitiesListOpen ? 'true' : undefined}
                    aria-haspopup='true'
                    size='medium'>
                    <FontAwesomeIcon size={iconFontSize} icon={faGear} />
                  </IconButton>
                  <StyledMenu
                    container={videoContainerRef.current}
                    anchorEl={qualitiesListAnchorEl}
                    MenuListProps={{
                      'aria-labelledby': 'qualities-list-button',
                    }}
                    id='qualities-list-menu'
                    open={isQualitiesListOpen}
                    onClose={handleCloseQualitieslist}>
                    <Box>
                      <Typography fontSize='18px' fontWeight={700} variant='h4' color='text.primary'>
                        {t('player.quality.label')}
                      </Typography>
                      {qualities && (
                        <MenuList sx={{ maxWidth: '350px', maxHeight: '400px' }}>
                          {qualities.map((qualityOption: Vimeo.VimeoVideoQualityObject) => {
                            if (!qualityResolver(qualityOption.id as Vimeo.VimeoVideoQuality)) return null;
                            return (
                              <MenuItem
                                onClick={() => handleChangeQuality(qualityOption.id as Vimeo.VimeoVideoQuality)}
                                key={qualityOption.id}
                                value={qualityOption.id}>
                                {quality === qualityOption.id && (
                                  <ListItemIcon>
                                    <FontAwesomeIcon icon={faCheck} />
                                  </ListItemIcon>
                                )}
                                <ListItemText
                                  disableTypography
                                  sx={{ fontWeight: quality === qualityOption.id ? 700 : 300 }}
                                  inset={quality !== qualityOption.id}>
                                  {t(qualityResolver(qualityOption.id as Vimeo.VimeoVideoQuality))}
                                </ListItemText>
                              </MenuItem>
                            );
                          })}
                        </MenuList>
                      )}
                    </Box>
                  </StyledMenu>
                </Box>
              </TopControls>
              <CenterControls>
                <CenterControlsLeft onClick={handleClickMiddleLeftControls}>
                  <Box
                    sx={{
                      position: 'relative',
                      opacity: showMiddleControlLeftAnimation ? 1 : 0,
                      transition: 'opacity 0.3s',
                    }}>
                    <FontAwesomeIcon
                      color={theme.palette.common.white}
                      size={isFullscreen ? '3x' : '2x'}
                      icon={faRotateLeft}
                    />
                    <BackTenSecondsBadge top={isFullscreen ? '55%' : '52%'} fontSize={isFullscreen ? '14px' : '11px'}>
                      10
                    </BackTenSecondsBadge>
                  </Box>
                </CenterControlsLeft>
                <CenterControlsMiddle onClick={handleClickMiddleCenterControls}>
                  {(!isVideoPlayed || showMiddleControlCenterAnimation || !mdUp) && !isVideoBuffering && (
                    <IconButton
                      aria-label={
                        !mdUp
                          ? isVideoEnded
                            ? 'watch again'
                            : isVideoPlayed
                              ? 'pause'
                              : 'play'
                          : isVideoEnded
                            ? 'watch again'
                            : isVideoPlayed
                              ? 'pause'
                              : 'play'
                      }
                      sx={{ minWidth: '88px' }}
                      onClick={(event) => {
                        if (!mdUp) event.stopPropagation();
                        !mdUp
                          ? isVideoEnded
                            ? handleWatchAgain()
                            : isVideoPlayed
                              ? handleStopVideo()
                              : handleStartVideo()
                          : () => {};
                      }}>
                      <FontAwesomeIcon
                        color={theme.palette.common.white}
                        size={isFullscreen ? '3x' : '2x'}
                        icon={isVideoEnded ? faArrowsRotate : isVideoPlayed ? faPause : faPlay}
                      />
                    </IconButton>
                  )}
                </CenterControlsMiddle>
                <CenterControlsRight onClick={handleClickMiddleRightControls}>
                  <Box
                    sx={{
                      position: 'relative',
                      opacity: showMiddleControlRightAnimation ? 1 : 0,
                      transition: 'opacity 0.3s',
                    }}>
                    <FontAwesomeIcon
                      color={theme.palette.common.white}
                      size={isFullscreen ? '3x' : '2x'}
                      icon={faRotateRight}
                    />
                    <ForwardTenSecondsBadge
                      left='47%'
                      top={isFullscreen ? '54%' : '52%'}
                      fontSize={isFullscreen ? '14px' : '11px'}>
                      10
                    </ForwardTenSecondsBadge>
                  </Box>
                </CenterControlsRight>
              </CenterControls>
              <BottomControls>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  {!smUp && (
                    <IconButton
                      aria-label={isVideoEnded ? 'play video again' : isVideoPlayed ? 'pause' : 'play'}
                      size='large'
                      ref={playPauseButton}
                      sx={{ minWidth: '52px', margin: '0 15px 0 0' }}
                      onClick={isVideoEnded ? handleWatchAgain : isVideoPlayed ? handleStopVideo : handleStartVideo}>
                      <FontAwesomeIcon
                        size={iconFontSize}
                        icon={isVideoEnded ? faArrowsRotate : isVideoPlayed ? faPause : faPlay}
                      />
                    </IconButton>
                  )}
                  <Typography sx={{ fontSize: '14px', minWidth: '55px', userSelect: 'none' }} color='text.primary'>
                    {formatDuration(videoCurrentTime)}
                  </Typography>
                  <Slider
                    onChange={handleChangeSlider}
                    size={isFullscreen ? 'medium' : 'small'}
                    max={videoDuration}
                    value={videoCurrentTime}
                    aria-label='Movie timeline'
                  />
                  <Typography
                    sx={{ fontSize: '14px', minWidth: '55px', textAlign: 'right', userSelect: 'none' }}
                    color='text.primary'>
                    {formatDuration(videoDuration)}
                  </Typography>
                  {!smUp && (
                    <IconButton
                      aria-label={isFullscreen ? 'minimalize' : 'fullscreen'}
                      sx={{ position: 'relative', margin: '0 0 0 15px' }}
                      onClick={handleToggleFullScreen}>
                      <FontAwesomeIcon size={iconFontSize} icon={isFullscreen ? faCompress : faExpand} />
                    </IconButton>
                  )}
                </Box>
                {smUp && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      flexWrap: 'wrap',
                      width: '100%',
                      padding: '0 50px',
                    }}>
                    <Stack direction='row' spacing={0}>
                      <IconButton
                        aria-label='back 10 seconds'
                        size='large'
                        sx={{ position: 'relative' }}
                        onClick={() => {
                          if (player.current && videoCurrentTime - 10 >= 0) {
                            handleClickBackTenSeconds();
                            const middleControlLeftTimeoutId = setTimeout(() => {
                              setLastMiddleControlLeftTimeoutId(middleControlLeftTimeoutId);
                            }, 700);
                            setCurrentMiddleControlLeftTimeoutId(middleControlLeftTimeoutId);
                            return () => clearTimeout(middleControlLeftTimeoutId);
                          }
                        }}>
                        <FontAwesomeIcon size={iconFontSize} icon={faRotateLeft} />
                        <BackTenSecondsBadge fontSize={isFullscreen ? '9px' : '8px'}>10</BackTenSecondsBadge>
                      </IconButton>
                      <IconButton
                        aria-label={isVideoEnded ? 'play video again' : isVideoPlayed ? 'pause' : 'play'}
                        disabled={isVideoBuffering}
                        ref={playPauseButton}
                        size='large'
                        sx={{ minWidth: '52px' }}
                        onClick={isVideoEnded ? handleWatchAgain : isVideoPlayed ? handleStopVideo : handleStartVideo}>
                        <FontAwesomeIcon
                          size={iconFontSize}
                          icon={isVideoEnded ? faArrowsRotate : isVideoPlayed ? faPause : faPlay}
                        />
                      </IconButton>
                      <IconButton
                        size='large'
                        aria-label='forward 10 seconds'
                        sx={{ position: 'relative' }}
                        onClick={() => {
                          if (player.current && videoCurrentTime + 10 <= videoDuration) {
                            handleClickForwardTenSeconds();
                            const middleControlRightTimeoutId = setTimeout(() => {
                              setLastMiddleControlRightTimeoutId(middleControlRightTimeoutId);
                            }, 700);
                            setCurrentMiddleControlRightTimeoutId(middleControlRightTimeoutId);
                            return () => clearTimeout(middleControlRightTimeoutId);
                          }
                        }}>
                        <FontAwesomeIcon size={iconFontSize} icon={faRotateRight} />
                        <ForwardTenSecondsBadge fontSize={isFullscreen ? '9px' : '8px'}>10</ForwardTenSecondsBadge>
                      </IconButton>
                    </Stack>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <IconButton
                        aria-label='toggle audio'
                        sx={{ margin: '0 20px 0 0' }}
                        onClick={handleToggleMuteAudio}>
                        <FontAwesomeIcon size={iconFontSize} icon={isSoundMuted ? faVolumeXmark : faVolumeHigh} />
                      </IconButton>
                      <VolumeSlider
                        onChange={handleChangeVolumeSlider}
                        sx={{ margin: '0 20px 0 0', width: '100%', maxWidth: '100px', minWidth: '90px' }}
                        size='small'
                        min={0.0}
                        step={0.01}
                        max={1.0}
                        value={volume}
                        aria-label='Movie timeline'
                      />
                      {smUp && (
                        <IconButton
                          aria-label={isFullscreen ? 'minimalize' : 'fullscreen'}
                          sx={{ position: 'relative' }}
                          onClick={handleToggleFullScreen}>
                          <FontAwesomeIcon size={iconFontSize} icon={isFullscreen ? faCompress : faExpand} />
                        </IconButton>
                      )}
                    </Box>
                  </Box>
                )}
              </BottomControls>
            </ControlsLayer>
          </ExtraLayer>
        )}
      </VideoWrapper>
    </Container>
  );
};

interface OwnProps {
  movieVimeoId: number;
}
