export class BasketMovie {
  id: string;
  title: string;
  price: number;
  priceId: string;
  imageSmall: Record<string, string>;
  currency: string;

  constructor(
    id: string,
    title: string,
    price: number,
    priceId: string,
    imageSmall: Record<string, string>,
    currency: string,
  ) {
    this.id = id;
    this.title = title;
    this.price = price;
    this.priceId = priceId;
    this.imageSmall = imageSmall;
    this.currency = currency;
  }
}
