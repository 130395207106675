import { LoadingButton } from '@mui/lab';
import { Alert, TextField, Typography } from '@mui/material';
import { User } from 'firebase/auth';
import { firebaseSendPasswordResetEmail } from 'firestore';
import { SectionLayout } from 'layouts/SectionLayout/SectionLayout';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { isEmailValid } from 'utils/Common/is-email-valid/is-email-valid';
import { Container, Form, FormContainer } from './ResetPassword.styled';
import { HelmetContent } from 'components/Common/HelmetContent/HelmetContent';
import { scrollTop } from 'utils/Common/scroll-top/scroll-top';

export const ResetPassword: React.FC<OwnProps> = ({ user }: { user: null | User }) => {
  const { t, i18n } = useTranslation();

  const [email, setEmail] = useState<string>(user && user.email ? user.email : '');
  const [emailError, setEmailError] = useState<string | null>(null);
  const [isSendingResetPasswordEmailLoading, setIsSendingResetPasswordEmailLoading] = useState<boolean>(false);
  const [sendingResetPasswordEmailError, setSendingResetPasswordEmailError] = useState<string | null>(null);
  const [haveResetPasswordEmailBeenSendSuccessfully, setHaveResetPasswordEmailBeenSendSuccessfully] = useState<
    string | null
  >(null);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setEmail(event.target.value);
    if (event.target.value.length === 0) return setEmailError(t('shared.textField.email.error.empty'));
    if (!isEmailValid(event.target.value)) return setEmailError(t('shared.textField.email.error.invalid'));
    return setEmailError(null);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    let errors: string[] = [];

    if (email.length === 0) {
      setEmailError(t('shared.textField.email.error.empty'));
      errors.push(t('shared.textField.email.error.empty'));
    }

    if (email.length > 0 && !isEmailValid(email)) {
      setEmailError(t('shared.textField.email.error.invalid'));
      errors.push(t('shared.textField.email.error.invalid'));
    }

    if (errors.length > 0) return;

    try {
      setSendingResetPasswordEmailError(null);
      setIsSendingResetPasswordEmailLoading(true);
      await firebaseSendPasswordResetEmail(
        email,
        i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language,
      );
      setHaveResetPasswordEmailBeenSendSuccessfully(email);
      setEmail('');
    } catch (e: any) {
      if (e.message === 'shared.firebaseErrorCodes.auth/user-not-found')
        return setHaveResetPasswordEmailBeenSendSuccessfully(email);
      setSendingResetPasswordEmailError(e.message);
    } finally {
      setIsSendingResetPasswordEmailLoading(false);
    }
  };

  useEffect(() => {
    scrollTop();
  }, []);

  return (
    <SectionLayout title={t('resetPassword.title')}>
      <HelmetContent title={t('resetPassword.pageTitle')} description={t('resetPassword.pageDescription')} />
      <Container>
        <FormContainer>
          <Form noValidate onSubmit={(event: React.FormEvent<HTMLFormElement>) => handleSubmit(event)}>
            <TextField
              onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleEmailChange(event)}
              value={email}
              error={!!emailError}
              helperText={emailError ? emailError : null}
              id='email'
              sx={{ margin: '0 0 20px 0', width: '100%' }}
              label={t('shared.textField.email.label')}
              variant='standard'
            />
            <LoadingButton
              sx={{ margin: '0 0 40px 0', width: '100%', textTransform: 'none' }}
              variant='contained'
              loading={isSendingResetPasswordEmailLoading}
              type='submit'
              size='large'>
              {t('shared.buttons.resetPassword')}
            </LoadingButton>
            {!!sendingResetPasswordEmailError && (
              <Alert sx={{ margin: '0 0 30px 0' }} severity='error'>
                {t(sendingResetPasswordEmailError)}
              </Alert>
            )}
            {!!haveResetPasswordEmailBeenSendSuccessfully && (
              <Alert sx={{ margin: '0 0 30px 0' }} severity='success'>
                <Trans
                  i18nKey='resetPassword.alerts.success.linkWasSend'
                  values={{
                    email: haveResetPasswordEmailBeenSendSuccessfully,
                  }}
                  components={[
                    <Typography
                      key={0}
                      fontWeight={700}
                      sx={{ display: 'inline-block', margin: '0 10px 0 0', wordBreak: 'break-word' }}
                      component='span'
                    />,
                  ]}
                />
              </Alert>
            )}
          </Form>
        </FormContainer>
      </Container>
    </SectionLayout>
  );
};

interface OwnProps {
  user: null | User;
}
