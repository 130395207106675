import React from 'react';
import { GlobalStyles, Theme, ThemeProvider, createTheme } from '@mui/material';
import { ErrorScreen } from 'components/Shared/ErrorScreen/ErrorScreen';
import { LoadingScreen } from 'components/Shared/LoadingScreen/LoadingScreen';
import { BasketContextProvider } from 'context/BasketContext';
import { MoviesContextProvider } from 'context/MoviesContext';
import { WhishlistContextProvider } from 'context/WhishlistContext';
import { QuerySnapshot, Unsubscribe } from 'firebase/firestore';
import { getMovies, listenBasketItemsChange, listenWhishlistItemsChange } from 'firestore';
import { useAuth } from 'hooks/useAuth/useAuth';
import { MainLayout } from 'layouts/MainLayout/MainLayout';
import { BasketMovie } from 'models/Movie/basket-movie/basket-movie';
import { WhishlistMovie } from 'models/Movie/whishlist-movie/whishlist-movie';
import { Account } from 'pages/Account/Account';
import { Basket } from 'pages/Basket/Basket';
import { Home } from 'pages/Home/Home';
import { Login } from 'pages/Login/Login';
import { Movie } from 'pages/Movie/Movie';
import { MyMovies } from 'pages/MyMovies/MyMovies';
import { NotFound } from 'pages/NotFound/NotFound';
import { PlayerVimeoFinal } from 'pages/PlayerVimeoFinal/PlayerVimeoFinal';
import { Register } from 'pages/Register/Register';
import { ResetPassword } from 'pages/ResetPassword/ResetPassword';
import { SetPassword } from 'pages/SetPassword/SetPassword';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { GlobalStylesMUI } from 'styles/global';
import { getDesign } from 'styles/theme';
import { Movie as MovieType } from 'types/shared/movie-type';
import { SnackbarProvider } from 'notistack';
import { ChristianMovies } from 'pages/ChristianMovies/ChristianMovies';
import { CasualMovies } from 'pages/CasualMovies/ChristianMovies';
import { BasedOnTheTrueStoryMovies } from 'pages/BasedOnTheTrueStoryMovies/BasedOnTheTrueStoryMovies';
import { MenFightForRighteousnessMovies } from 'pages/MenFightForRighteousnessMovies/MenFightForRighteousnessMovies';
import { Help } from 'pages/Help/Help';

export const App = () => {
  const theme: Theme = createTheme(getDesign);
  const { user, isLoading } = useAuth();
  const [basketItems, setBasketItems] = useState<BasketMovie[]>([]);
  const [whishlistItems, setWhishlistItems] = useState<WhishlistMovie[]>([]);
  const [movies, setMovies] = useState<MovieType[] | null>(null);
  const [moviesError, setMoviesError] = useState<boolean>(false);
  const { i18n, t } = useTranslation();

  useEffect(() => {
    let unsubscribe: Unsubscribe | null = null;
    if (user && !isLoading) {
      unsubscribe = listenBasketItemsChange(user.uid, (snapshot: QuerySnapshot<BasketMovie>) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === 'added') {
            setBasketItems((prevState: BasketMovie[]) => [...prevState, change.doc.data()]);
          }
          if (change.type === 'removed') {
            setBasketItems((prevState: BasketMovie[]) =>
              prevState.filter((basketItem: BasketMovie) => basketItem.id !== change.doc.data().id),
            );
          }
        });
      });
    } else {
      setBasketItems([]);
    }

    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [user, isLoading]);

  useEffect(() => {
    let unsubscribe: Unsubscribe | null = null;
    if (user && !isLoading) {
      unsubscribe = listenWhishlistItemsChange(user.uid, (snapshot: QuerySnapshot<WhishlistMovie>) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === 'added') {
            setWhishlistItems((prevState: WhishlistMovie[]) => [...prevState, change.doc.data()]);
          }
          if (change.type === 'removed') {
            setWhishlistItems((prevState: WhishlistMovie[]) =>
              prevState.filter((whishlistItem: WhishlistMovie) => whishlistItem.id !== change.doc.data().id),
            );
          }
        });
      });
    } else {
      setWhishlistItems([]);
    }

    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [user, isLoading]);

  useEffect(() => {
    (async () => {
      try {
        const moviesResponse = await getMovies();
        if (moviesResponse) {
          setMovies(moviesResponse as MovieType[]);
        }
      } catch (e) {
        setMoviesError(true);
      }
    })();
  }, []);

  if (moviesError)
    return (
      <ThemeProvider theme={theme}>
        <GlobalStyles styles={GlobalStylesMUI} />
        <ErrorScreen errorTitle={t('app.errorTitle')} errorDescription={t('app.errorDescription')} />
      </ThemeProvider>
    );
  if (!movies || isLoading)
    return (
      <ThemeProvider theme={theme}>
        <GlobalStyles styles={GlobalStylesMUI} />
        <LoadingScreen addSpinner={false} />
      </ThemeProvider>
    );
  return (
    <MoviesContextProvider initialMovies={movies}>
      <BasketContextProvider basketMovies={basketItems}>
        <WhishlistContextProvider whishlistMovies={whishlistItems}>
          <ThemeProvider theme={theme}>
            <GlobalStyles styles={GlobalStylesMUI} />
            <BrowserRouter>
              <SnackbarProvider maxSnack={3} anchorOrigin={{ horizontal: 'right', vertical: 'top' }}>
                <Routes>
                  <Route element={<MainLayout user={user} />}>
                    <Route path={`/`} element={<Home />} />
                    <Route path={`/:language`} element={<Home />} />
                    <Route
                      path={`/:language/login`}
                      element={
                        user ? (
                          <Navigate
                            to={`/${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}/`}
                          />
                        ) : (
                          <Login />
                        )
                      }
                    />
                    <Route
                      path={`/:language/register`}
                      element={
                        user ? (
                          <Navigate
                            to={`/${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}/`}
                          />
                        ) : (
                          <Register />
                        )
                      }
                    />
                    <Route path={`/:language/help`} element={<Help />} />
                    <Route path={`/:language/christian-movies`} element={<ChristianMovies />} />
                    <Route path={`/:language/casual-movies`} element={<CasualMovies />} />
                    <Route path={`/:language/based-on-the-true-story-movies`} element={<BasedOnTheTrueStoryMovies />} />
                    <Route
                      path={`/:language/men-fight-for-righteousness-movies`}
                      element={<MenFightForRighteousnessMovies />}
                    />
                    <Route path={`/:language/movies/:movieId`} element={<Movie user={user} />} />
                    <Route path={`/:language/movies/:movieName/:movieId`} element={<Movie user={user} />} />
                    <Route
                      path={`/:language/basket`}
                      element={
                        user ? (
                          <Basket user={user} />
                        ) : (
                          <Navigate
                            to={`/${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}/login`}
                          />
                        )
                      }
                    />
                    <Route
                      path={`/:language/my-movies`}
                      element={
                        user ? (
                          <MyMovies user={user} />
                        ) : (
                          <Navigate
                            to={`/${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}/login`}
                          />
                        )
                      }
                    />
                    <Route
                      path={`/:language/account`}
                      element={
                        user ? (
                          <Account user={user} />
                        ) : (
                          <Navigate
                            to={`/${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}/login`}
                          />
                        )
                      }
                    />
                    <Route path={`/:language/set-password`} element={<SetPassword />} />
                    <Route path={`/:language/reset-password`} element={<ResetPassword user={user} />} />
                    <Route path={`*`} element={<NotFound />} />
                  </Route>
                  <Route
                    path={`/:language/movies/:movieId/player`}
                    element={
                      user ? (
                        <PlayerVimeoFinal user={user} />
                      ) : (
                        <Navigate
                          to={`/${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}/login`}
                        />
                      )
                    }
                  />
                  <Route
                    path={`/:language/movies/:movieName/:movieId/player`}
                    element={
                      user ? (
                        <PlayerVimeoFinal user={user} />
                      ) : (
                        <Navigate
                          to={`/${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}/login`}
                        />
                      )
                    }
                  />
                </Routes>
              </SnackbarProvider>
            </BrowserRouter>
          </ThemeProvider>
        </WhishlistContextProvider>
      </BasketContextProvider>
    </MoviesContextProvider>
  );
};
