import React from 'react';
import {
  Container,
  Copyright,
  FooterList,
  FooterListContainer,
  FooterListItem,
  FooterListsWrapper,
} from './Footer.styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarsProgress, faCircleQuestion, faPerson } from '@fortawesome/free-solid-svg-icons';
import { Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { Link as MUILink } from '@mui/material';
import { NavLink } from 'react-router-dom';

export const Footer = () => {
  const { t, i18n } = useTranslation();

  return (
    <Container>
      <FooterListsWrapper>
        <FooterListContainer>
          <Typography
            sx={{ margin: '0 0 10px 0', display: 'flex', alignItems: 'center' }}
            variant='h2'
            color='text.primary'>
            <FontAwesomeIcon size='xs' style={{ margin: '0 10px 0 0', fontSize: '22px' }} icon={faPerson} />
            <Typography component='span'>{t('footer.aboutMe.title')}</Typography>
          </Typography>
          <FooterList>
            <FooterListItem>
              <Typography component='span'>{t('footer.aboutMe.element1')}</Typography>
            </FooterListItem>
          </FooterList>
        </FooterListContainer>
        <FooterListContainer>
          <Typography
            sx={{ margin: '0 0 10px 0', display: 'flex', alignItems: 'center' }}
            variant='h2'
            color='text.primary'>
            <FontAwesomeIcon size='xs' style={{ margin: '0 10px 0 0', fontSize: '22px' }} icon={faBarsProgress} />
            <Typography component='span'>{t('footer.aboutProject.title')}</Typography>
          </Typography>
          <FooterList>
            <FooterListItem>
              <Typography component='span'>{t('footer.aboutProject.element1')}</Typography>
            </FooterListItem>
            <FooterListItem>
              <Typography component='span'>{t('footer.aboutProject.element2')}</Typography>
            </FooterListItem>
            <FooterListItem>
              <Typography component='span'>{t('footer.aboutProject.element3')}</Typography>
            </FooterListItem>
          </FooterList>
        </FooterListContainer>
        <FooterListContainer>
          <Typography
            sx={{ margin: '0 0 10px 0', display: 'flex', alignItems: 'center' }}
            variant='h2'
            color='text.primary'>
            <FontAwesomeIcon size='xs' style={{ margin: '0 10px 0 0', fontSize: '22px' }} icon={faCircleQuestion} />
            <Typography component='span'>{t('footer.help.title')}</Typography>
          </Typography>
          <FooterList>
            <FooterListItem>
              <Typography component='span'>
                <Trans
                  component='span'
                  i18nKey='footer.help.element1'
                  components={[
                    <MUILink
                      key={0}
                      component={NavLink}
                      to={`/${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}/help`}
                    />,
                  ]}
                />
              </Typography>
            </FooterListItem>
          </FooterList>
        </FooterListContainer>
      </FooterListsWrapper>
      <Copyright>{t('footer.copyRight')}</Copyright>
    </Container>
  );
};
