import { Movies } from 'components/Movies/Movies';
import React from 'react';

export const MenFightForRighteousnessMovies: React.FC = () => {
  return (
    <Movies
      initialTag='men_fight_for_righteousness'
      title='menFightForRighteousnessMovies.title'
      titlePrefix='menFightForRighteousnessMovies.titlePrefix'
      pageTitle='menFightForRighteousnessMovies.pageTitle'
      pageDescription='menFightForRighteousnessMovies.pageDescription'
      keywords='menFightForRighteousnessMovies.pageKeywords'
    />
  );
};
