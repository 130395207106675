import { styled } from '@mui/material';

export const Container = styled('div')(() => ({
  width: '100%',
}));

export const FormContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
}));

export const Form = styled('form')(() => ({
  width: '100%',
  maxWidth: '245px',
}));
