import { PaymentItem } from './payment-item/payment-item';

export class Payment {
  amount: number;
  id: string;
  created: number;
  items: PaymentItem[];
  status: string;
  currency: string;

  constructor(amount: number, id: string, created: number, items: PaymentItem[], status: string, currency: string) {
    this.amount = amount;
    this.id = id;
    this.created = created;
    this.items = items;
    this.status = status;
    this.currency = currency;
  }
}
