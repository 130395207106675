import { styled } from '@mui/material';

export const Container = styled('li')(({ theme }) => ({
  padding: '26px',
  background: theme.palette.background.background4,
  display: 'flex',
  margin: '0 0 50px 0',
  borderRadius: '5px',
  boxShadow: theme.shadows[10],
  border: `1px solid ${theme.palette.border.color2}`,

  '&:last-of-type': {
    margin: '0',
  },

  [theme.breakpoints.up('lg')]: {
    justifyContent: 'space-between',
  },
}));

export const Image = styled('img')(() => ({
  width: '100%',
}));
