import {
  faArrowLeftLong,
  faArrowsRotate,
  faCheck,
  faClosedCaptioning,
  faCompress,
  faExpand,
  faGear,
  faPause,
  faPlay,
  faRotateLeft,
  faRotateRight,
  faVolumeHigh,
  faVolumeXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  IconButton,
  ListItemText,
  MenuItem,
  MenuList,
  Slider,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import Vimeo from '@vimeo/player';
import { StyledMenu } from 'components/Common/Header/Header.styled';
import { PlayerError } from 'components/Player/PlayerError/PlayerError';
import { ErrorScreen } from 'components/Shared/ErrorScreen/ErrorScreen';
import { LoadingScreen } from 'components/Shared/LoadingScreen/LoadingScreen';
import { MoviesContext } from 'context/MoviesContext';
import { Unsubscribe, User } from 'firebase/auth';
import {
  addOrOverrideBookmark,
  deleteBookmark,
  getBookmark,
  getUserIPAddress,
  getUserPayments,
  listenUserChange,
  updateUserActiveIpAddress,
} from 'firestore';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Movie } from 'types/shared/movie-type';
import { getMovie } from 'utils/Context/MoviesContext/get-movie/get-movie';
import { checkIfUserPaidForMovie } from 'utils/Movie/check-if-user-paid-for-movie/check-if-user-paid-for-movie';
import { formatDuration } from 'utils/Player/formatDuration/formatDuration';
import {
  BackTenSecondsBadge,
  BottomControls,
  CenterControls,
  CenterControlsLeft,
  CenterControlsMiddle,
  CenterControlsRight,
  Container,
  ControlsLayer,
  ExtraLayer,
  ForwardTenSecondsBadge,
  Title,
  TopControls,
  VideoWrapper,
  VideoWrapper2,
  VideoWrapper3,
  VolumeSlider,
} from './PlayerVimeoFinal.styled';
import { HelmetContent } from 'components/Common/HelmetContent/HelmetContent';
import { convertMovieTileToOnlyItsOwnLanguage } from 'utils/Common/convert-movie-title-to-only-its-own-language/convert-movie-title-to-only-its-own-language';
import { DATA_BASE_64_STRING_CONTENT_16_9 } from 'components/Common/ImageLoader/ImageLoader';
import { DocumentSnapshot } from '@firebase/firestore';
import { enqueueSnackbar } from 'notistack';
import { isIOS } from 'react-device-detect';

type PlayerParams = {
  movieId?: string;
};

export const PlayerVimeoFinal: React.FC<OwnProps> = ({ user }: { user: User }) => {
  const playerRef = useRef<HTMLDivElement>(null);
  const player = useRef<Vimeo>(null);
  const playPauseButton = useRef<HTMLButtonElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const videoContainerRef = useRef<HTMLDivElement>(null);

  const { movies } = useContext(MoviesContext);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const getMovieVimeoId = (movie: Movie, language: string): number => {
    const movieSrcObject = movie.movieSrc.find(
      (movieSrc) => movieSrc.language === (language.includes('-') ? language.split('-')[0] : language),
    );
    return movieSrcObject ? movieSrcObject.src : 0;
  };

  const getMovieAudio = (movie: Movie, language: string): string => {
    const movieSrcObject = movie.movieSrc.find(
      (movieSrc) => movieSrc.language === (language.includes('-') ? language.split('-')[0] : language),
    );
    return movieSrcObject ? movieSrcObject.language : '';
  };
  let { movieId } = useParams<PlayerParams>();
  const [movie] = useState<Movie | undefined>(getMovie(movies, movieId));
  const [isUserPaidForCurrentMovie, setIsUserPaidForCurrentMovie] = useState<boolean>(false);
  const [isUserPaidForCurrentMovieLoading, setIsUserPaidForCurrentMovieLoading] = useState<boolean>(true);
  const [isUserPaidForCurrentMovieError, setIsUserPaidForCurrentMovieError] = useState<boolean>(false);
  const [isBookmarkLoading, setIsBookmarkLoading] = useState<boolean>(true);
  const [, setBookmarkError] = useState<boolean>(false);
  const [isPlayerInitilized, setIsPlayerInitilized] = useState<boolean>(false);

  const [isVideoBuffering, setIsVideoBuffering] = useState<boolean>(false);
  const [isVideoLoaded, setIsVideoLoaded] = useState<boolean>(false);
  const [isVideoPlayed, setIsVideoPlayed] = useState<boolean>(false);
  const [isVideoEnded, setIsVideoEnded] = useState<boolean>(false);
  const [videoCurrentTime, setVideoCurrentTime] = useState<number>(0);
  const [videoDuration, setVideoDuration] = useState<number>(0);
  const [volume, setVolume] = useState<number>(1);
  const [isSoundMuted, setIsSoundMuted] = useState<boolean>(false);
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
  const [displayControls, setDisplayControls] = useState<boolean>(true);
  const [lastTimeoutId, setLastTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const [currentTimeoutId, setCurrentTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const [lastSliderTimeoutId, setLastSliderTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const [currentSliderTimeoutId, setCurrentSliderTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const [subtitle, setSubTitle] = useState<string>('off');
  const [subtitles, setSubtitles] = useState<Vimeo.VimeoTextTrack[] | null>(null);
  const [audio, setAudio] = useState<string>(getMovieAudio(movie as Movie, i18n.language));
  const [audioes] = useState((movie as Movie).movieSrc);
  const [qualities, setQualities] = useState<null | Vimeo.VimeoVideoQualityObject[]>(null);
  const [quality, setQuality] = useState<Vimeo.VimeoVideoQuality | null>(null);
  const [movieVimeoId, setMovieVimeoId] = useState(getMovieVimeoId(movie as Movie, i18n.language));

  const [showMiddleControlLeftAnimation, setShowMiddleControlLeftAnimation] = useState<boolean>(false);
  const [lastMiddleControlLeftTimeoutId, setLastMiddleControlLeftTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const [currentMiddleControlLeftTimeoutId, setCurrentMiddleControlLeftTimeoutId] = useState<NodeJS.Timeout | null>(
    null,
  );

  const [showMiddleControlCenterAnimation, setShowMiddleControlCenterAnimation] = useState<boolean>(false);
  const [lastMiddleControlCenterTimeoutId, setLastMiddleControlCenterTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const [currentMiddleControlCenterTimeoutId, setCurrentMiddleControlCenterTimeoutId] = useState<NodeJS.Timeout | null>(
    null,
  );

  const [showMiddleControlRightAnimation, setShowMiddleControlRightAnimation] = useState<boolean>(false);
  const [lastMiddleControlRightTimeoutId, setLastMiddleControlRightTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const [currentMiddleControlRightTimeoutId, setCurrentMiddleControlRightTimeoutId] = useState<NodeJS.Timeout | null>(
    null,
  );

  const leftPendingClick = useRef<NodeJS.Timeout | null>(null);
  const rightPendingClick = useRef<NodeJS.Timeout | null>(null);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));

  const [subtitlesAndAudioAnchorEl, setSubtitlesAndAudioAnchorEl] = useState<null | HTMLElement>(null);
  const [isSubtitlesAndAudioOpen, setIsSubtitlesAndAudioOpen] = useState<boolean>(false);

  const [qualitiesListAnchorEl, setQualitiesListAnchorEl] = useState<null | HTMLElement>(null);
  const [isQualitiesListOpen, setIsQualitiesListOpen] = useState<boolean>(false);

  const handleStartVideo = async () => {
    if (player.current) {
      try {
        await Promise.all([
          addOrOverrideBookmark(user.uid, movieId!!, Math.floor(videoCurrentTime), audio, subtitle, videoDuration),
          player.current.play(),
        ]);
        setIsVideoPlayed(true);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleWatchAgain = () => {
    if (player.current) {
      player.current.play();
      setIsVideoPlayed(true);
      setIsVideoEnded(false);
    }
  };

  const handleStopVideo = async () => {
    if (player.current) {
      try {
        await Promise.all([
          addOrOverrideBookmark(user.uid, movieId!!, Math.floor(videoCurrentTime), audio, subtitle, videoDuration),
          player.current.pause(),
        ]);
        setIsVideoPlayed(false);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleUpdateTime = (currentTimeInSeconds: number) => {
    if (player.current) {
      setVideoCurrentTime(currentTimeInSeconds);
    }
  };

  const handleLoaded = async (playerInit: Vimeo) => {
    if (playerInit) {
      try {
        const [duration, volume] = await Promise.all([playerInit.getDuration(), playerInit.setVolume(0.7)]);
        if (typeof duration === 'number' && typeof volume === 'number') {
          setVideoDuration(duration);
          setVolume(volume);
          setIsVideoLoaded(true);
          setIsVideoPlayed(
            // @ts-ignore
            isIOS
              ? false
              : // @ts-ignore
                typeof navigator.userActivation.hasBeenActive === 'boolean'
                ? // @ts-ignore
                  navigator.userActivation.hasBeenActive
                : true,
          );
          setIsSoundMuted(false);
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleChangeSlider = async (event: Event, newValue: number | number[]) => {
    if (player.current && typeof newValue === 'number') {
      try {
        setVideoCurrentTime(newValue);

        const currentTime = await player.current.setCurrentTime(newValue);
        if (currentTime) {
          const sliderTimeoutId = setTimeout(() => {
            setLastSliderTimeoutId(sliderTimeoutId);
          }, 2000);
          setCurrentSliderTimeoutId(sliderTimeoutId);

          await player.current.play();
          setIsVideoEnded(false);
          setIsVideoPlayed(true);
          return () => clearTimeout(sliderTimeoutId);
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleVideoEnded = async () => {
    await deleteBookmark(user.uid, movieId!!);
    setIsVideoEnded(true);
    if (isIOS) {
      enqueueSnackbar(t('player.info.videoEnded'), { variant: 'info' });
      navigate(`..`, { relative: 'path' });
    }
  };

  const handleClickBackTenSeconds = async () => {
    if (player.current && videoCurrentTime - 10 >= 0) {
      const currentTimeResponse = await player.current.setCurrentTime(videoCurrentTime - 10);

      if (currentTimeResponse) {
        setShowMiddleControlLeftAnimation(true);
        await addOrOverrideBookmark(
          user.uid,
          movieId!!,
          Math.floor(videoCurrentTime - 10),
          audio,
          subtitle,
          videoDuration,
        );
        await player.current.play();
        setIsVideoEnded(false);
        setIsVideoPlayed(true);
      }
    }
  };

  const handleClickForwardTenSeconds = async () => {
    if (player.current && videoCurrentTime + 10 <= videoDuration) {
      const currentTimeResponse = await player.current.setCurrentTime(videoCurrentTime + 10);

      if (currentTimeResponse) {
        setShowMiddleControlRightAnimation(true);
        await addOrOverrideBookmark(
          user.uid,
          movieId!!,
          Math.floor(videoCurrentTime + 10),
          audio,
          subtitle,
          videoDuration,
        );
        await player.current.play();
        setIsVideoEnded(false);
        setIsVideoPlayed(true);
      }
    }
  };

  const handleChangeVolumeSlider = async (event: Event, newValue: number | number[]) => {
    if (player.current && typeof newValue === 'number') {
      try {
        setVolume(newValue);
        await player.current.setVolume(newValue);
        if (newValue === 0) {
          await player.current.setMuted(true);
          return setIsSoundMuted(true);
        }
        await player.current.setMuted(false);
        return setIsSoundMuted(false);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleToggleMuteAudio = async () => {
    if (player.current) {
      try {
        const volume: number = await player.current.getVolume();
        if (typeof volume === 'number') {
          const volumeFromPromise = await player.current.setVolume(volume != 0 ? 0 : 0.7);
          if (typeof volumeFromPromise === 'number') {
            const mutedResponse = await player.current.setMuted(volume != 0 ? true : false);
            if (typeof mutedResponse === 'boolean') {
              setVolume(volume != 0 ? 0 : 0.7);
              setIsSoundMuted(volume != 0 ? true : false);
            }
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleToggleFullScreen = async () => {
    if (videoContainerRef.current && player.current) {
      try {
        if (document.fullscreenElement === null) {
          if (isIOS) return player.current.requestFullscreen();
          return videoContainerRef.current.requestFullscreen();
        }
        await addOrOverrideBookmark(user.uid, movieId!!, Math.floor(videoCurrentTime), audio, subtitle, videoDuration);
        if (isIOS) {
          return player.current.exitFullscreen();
        }
        return document.exitFullscreen();
      } catch (e) {
        console.log(e);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('fullscreenchange', () => {
      if (document.fullscreenElement === null) {
        return setIsFullscreen(false);
      }

      return setIsFullscreen(true);
    });
  }, []);

  const handleOpenSubtitlesAndAudio = (event: React.MouseEvent<HTMLElement>) => {
    setSubtitlesAndAudioAnchorEl(event.currentTarget);
    setIsSubtitlesAndAudioOpen(true);
  };

  const handleCloseSubtitlesAndAudio = () => {
    setSubtitlesAndAudioAnchorEl(null);
    setIsSubtitlesAndAudioOpen(false);
  };

  const handleOpenQualitiesList = (event: React.MouseEvent<HTMLElement>) => {
    setQualitiesListAnchorEl(event.currentTarget);
    setIsQualitiesListOpen(true);
  };

  const handleCloseQualitieslist = () => {
    setQualitiesListAnchorEl(null);
    setIsQualitiesListOpen(false);
  };

  const handleMouseMove = () => {
    setDisplayControls(true);
    const timeoutId = setTimeout(() => {
      setLastTimeoutId(timeoutId);
    }, 4000);
    setCurrentTimeoutId(timeoutId);

    return () => clearTimeout(timeoutId);
  };

  const handleMouseLeaveFromTheExtraLayer = () => {
    setDisplayControls(false);
    handleCloseQualitieslist();
    handleCloseSubtitlesAndAudio();
  };

  const handleClickMoveToMoviePage = async () => {
    if (!isVideoEnded)
      await addOrOverrideBookmark(user.uid, movieId!!, Math.floor(videoCurrentTime), audio, subtitle, videoDuration);
    navigate(`..`, { relative: 'path' });
  };

  const handleChangeSubtitle = async (language: string) => {
    if (player.current) {
      if (language === 'off') {
        return player.current.disableTextTrack().then(async () => {
          setSubTitle(language);
          addOrOverrideBookmark(user.uid, movieId!!, Math.floor(videoCurrentTime), audio, language, videoDuration);
        });
      }

      player.current.enableTextTrack(language).then(() => {
        setSubTitle(language);
        addOrOverrideBookmark(user.uid, movieId!!, Math.floor(videoCurrentTime), audio, language, videoDuration);
      });
    }
  };

  const handleChangeAudio = async (langauge: string) => {
    const movieSrcObject = getMovieVimeoId(movie as Movie, langauge);
    setAudio(langauge);
    if (langauge === audio) {
      return handleCloseSubtitlesAndAudio();
    }

    if (movieSrcObject) {
      if (player.current) {
        player.current.loadVideo(movieSrcObject).then((videoIdResponse) => {
          setMovieVimeoId(videoIdResponse);
          const promiseTasks: Promise<any>[] = [
            player.current!!.setCurrentTime(videoCurrentTime),
            addOrOverrideBookmark(user.uid, movieId!!, Math.floor(videoCurrentTime), langauge, subtitle, videoDuration),
          ];
          if (subtitle !== 'off') {
            promiseTasks.push(player.current!!.enableTextTrack(subtitle));
          }
          if (subtitle === 'off') {
            promiseTasks.push(player.current!!.disableTextTrack());
          }

          Promise.all(promiseTasks).then(() => {
            if (player.current) {
              player.current.play();
              handleCloseSubtitlesAndAudio();
            }
          });
        });

        //
        // const videoIdResponse = await player.current.loadVideo(movieSrcObject);
        // setMovieVimeoId(videoIdResponse);

        // if (videoIdResponse) {
        //   const promiseTasks: Promise<any>[] = [
        //     player.current.setCurrentTime(videoCurrentTime),
        //     addOrOverrideBookmark(user.uid, movieId!!, videoCurrentTime, langauge, subtitle),
        //   ];
        //   if (subtitle !== 'off') {
        //     promiseTasks.push(player.current.enableTextTrack(subtitle));
        //   }
        //   Promise.all(promiseTasks).then(() => {
        //     if (player.current) {
        //       setAudio(langauge);
        //       player.current.play();
        //       handleCloseSubtitlesAndAudio();
        //     }
        //   });
        // }
      }
    }
  };

  const handleChangeQuality = async (quality: Vimeo.VimeoVideoQuality) => {
    if (player.current) {
      try {
        setQuality(quality);
        await player.current.setQuality(quality);
        handleCloseQualitieslist();
      } catch (e) {
        console.log(e);
      }
    }
  };

  useEffect(() => {
    // const obj = { hello: movieVimeoId };
    // const blob = new Blob([JSON.stringify(movieVimeoId, null, 2)], {
    //   type: 'application/json',
    // });
    // // const blob: Blob = new Blob();
    // const url4 = URL.createObjectURL(blob);
    // const text = new Response(blob).text();
    // text.then((data) => console.log(data));
    // console.log(url4);

    (async () => {
      let options: Vimeo.Options = {
        id: movieVimeoId,
        loop: false,
        quality: 'auto',
        dnt: true,
        autoplay: true,
        controls: false,
        muted: true,
        responsive: true,
        // background: true, // make also that captions disappear
        // width: 800,
      };
      if (playerRef.current !== null && !isPlayerInitilized) {
        const playerInit = new Vimeo(playerRef.current, options);
        // @ts-ignore
        player.current = playerInit;
        setIsPlayerInitilized(true);
        // Vimeo generates one problem
        // from videoduration function return rounded value let's say
        // 179 seconds
        // but actual length of the video is 179.96 which is played
        // from it
        // then when I at the end of the video move slider to the end bookmark is deltedeted
        // but because of the time of the slider bookmark update delay which is 2 seconds bookmark is generated after
        // was delated then because of the previously mentioned bug from vimeo
        // I set bookmark in bookmarks collection the videoduration as 179 and lastWhatchTime: 179.96
        // and then it coz the problem because when I refresh the page we have the bookmark with last
        // played video time longer than some coparasion that vimeo is making (again rounded)
        // then they got some 179 - 179.96 = -0.96 negative value which coz the error from their side

        playerInit.getQualities().then((qualities: Vimeo.VimeoVideoQualityObject[]) => {
          setQualities(qualities);
        });

        playerInit.setQuality('auto').then(function (quality) {
          setQuality(quality);
        });

        playerInit.setVolume(0.7);

        playerInit.getTextTracks().then((textTracks: Vimeo.VimeoTextTrack[]) => {
          setSubtitles(textTracks);
        });

        if (subtitle === 'off') {
          playerInit.disableTextTrack().then(() => setSubTitle('off'));
        } else {
          playerInit.enableTextTrack(subtitle).then((enabledTextTrack) => setSubTitle(enabledTextTrack.language));
        }

        playerInit.setCurrentTime(videoCurrentTime).then(function () {});

        playerInit.on('timeupdate', function (time: Vimeo.TimeEvent) {
          handleUpdateTime(time.seconds);
        });

        playerInit.on('bufferstart', async () => {
          setIsVideoBuffering(true);
        });

        playerInit.on('play', async () => {
          setIsVideoPlayed(true);
        });

        playerInit.on('pause', async () => {
          setIsVideoPlayed(false);
        });

        playerInit.on('bufferend', async () => {
          setIsVideoBuffering(false);
        });

        playerInit.on('ended', () => {
          handleVideoEnded();
        });

        playerInit.on('loaded', () => {
          handleLoaded(playerInit);
        });
      }
    })();
  }, [playerRef.current, isUserPaidForCurrentMovie, isBookmarkLoading]);

  useEffect(() => {
    if (currentTimeoutId === lastTimeoutId && currentTimeoutId !== null && lastTimeoutId !== null && isVideoPlayed) {
      setDisplayControls(false);
      handleCloseQualitieslist();
      handleCloseSubtitlesAndAudio();
    }
    // probably isVideoLoaded because somethings video is loading and we have to reset it
  }, [currentTimeoutId, lastTimeoutId, isVideoLoaded]);

  useEffect(() => {
    (async () => {
      if (
        currentSliderTimeoutId === lastSliderTimeoutId &&
        currentSliderTimeoutId !== null &&
        lastSliderTimeoutId !== null
      ) {
        await addOrOverrideBookmark(user.uid, movieId!!, Math.floor(videoCurrentTime), audio, subtitle, videoDuration);
      }
    })();
  }, [currentSliderTimeoutId, lastSliderTimeoutId, isVideoLoaded]);

  useEffect(() => {
    if (
      currentMiddleControlRightTimeoutId === lastMiddleControlRightTimeoutId &&
      currentMiddleControlRightTimeoutId !== null &&
      lastMiddleControlRightTimeoutId !== null
    ) {
      setShowMiddleControlRightAnimation(false);
    }
    // probably isVideoLoaded because somethings video is loading and we have to reset it
  }, [
    currentMiddleControlRightTimeoutId,
    lastMiddleControlRightTimeoutId,
    isVideoLoaded,
    isVideoPlayed,
    isVideoBuffering,
    videoCurrentTime,
  ]);

  useEffect(() => {
    if (
      currentMiddleControlLeftTimeoutId === lastMiddleControlLeftTimeoutId &&
      currentMiddleControlLeftTimeoutId !== null &&
      lastMiddleControlLeftTimeoutId !== null
    ) {
      setShowMiddleControlLeftAnimation(false);
    }
  }, [
    currentMiddleControlLeftTimeoutId,
    lastMiddleControlLeftTimeoutId,
    isVideoLoaded,
    isVideoPlayed,
    isVideoBuffering,
    videoCurrentTime,
  ]);

  useEffect(() => {
    if (
      currentMiddleControlCenterTimeoutId === lastMiddleControlCenterTimeoutId &&
      currentMiddleControlCenterTimeoutId !== null &&
      lastMiddleControlCenterTimeoutId !== null
    ) {
      setShowMiddleControlCenterAnimation(false);
    }
  }, [currentMiddleControlCenterTimeoutId, lastMiddleControlCenterTimeoutId, isVideoLoaded]);

  useEffect(() => {
    (async () => {
      try {
        const userPayments = await getUserPayments(user.uid);
        if (movie && userPayments) {
          if (checkIfUserPaidForMovie(movie, userPayments)) {
            setIsUserPaidForCurrentMovie(true);

            const bookmark = await getBookmark(user.uid, movieId!!);
            if (bookmark) {
              setVideoCurrentTime(bookmark.lastWatchTime);
              setAudio(bookmark.audio);
              setSubTitle(bookmark.subtitle);
              setMovieVimeoId(getMovieVimeoId(movie as Movie, bookmark.audio));
            }
          }
        }
      } catch (e) {
        setIsUserPaidForCurrentMovieError(true);
        setBookmarkError(true);
      } finally {
        setIsUserPaidForCurrentMovieLoading(false);
        setIsBookmarkLoading(false);
      }
    })();
  }, [user, movieId]);

  useEffect(() => {
    return () => {
      if (player.current) {
        player.current.destroy();
      }
    };
  }, []);

  useEffect(() => {
    if (buttonRef.current) {
      buttonRef.current.focus();
    }
  }, [buttonRef.current, videoCurrentTime]);

  useEffect(() => {
    let unsubscribe: Unsubscribe | null = null;
    (async () => {
      try {
        await updateUserActiveIpAddress(user.uid);

        unsubscribe = await listenUserChange(user.uid, async (snapshot: DocumentSnapshot) => {
          try {
            const userCurrentIpAddress = await getUserIPAddress();
            const user: any = snapshot.data();
            if (user.activeIpAddress !== userCurrentIpAddress) {
              enqueueSnackbar(t('player.errors.parallelWatching'), { variant: 'error' });
              navigate(`..`, { relative: 'path' });
            }
          } catch (e) {
            // don't have to be worry about filling activeIpAddress in catch block
            // because if some of the async methods failed it will go into some of the catch blocks
            // So the condition which moves user away from player will not be triggered
            // does is only saver for me but if not works not involved in user watching possibility.
            console.log(e);
          }
        });
      } catch (e) {
        console.log(e);
      }
    })();
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, []);

  const qualityResolver = (quality: Vimeo.VimeoVideoQuality) => {
    switch (quality) {
      case 'auto':
        return `player.quality.adaptive.label`;
      case '1080p':
        return `Full HD (1080p)`;
      case '720p':
        return `HD (720p)`;
      case '540p':
        return `SD (540p)`;
      default:
        return '';
    }
  };

  // document.addEventListener('keydown', (event) => {
  //   // if (event.defaultPrevented) {
  //   //   return; // Do nothing if the event was already processed
  //   // }

  //   switch (event.key) {
  //     case 'ArrowDown':
  //       // Do something for "down arrow" key press.
  //       break;
  //     case 'ArrowUp':
  //       // Do something for "up arrow" key press.
  //       break;
  //     case 'ArrowLeft':
  //       handleClickBackTenSeconds();
  //       break;
  //     case 'ArrowRight':
  //       handleClickForwardTenSeconds();
  //       break;
  //     case 'f':
  //       handleToggleFullScreen();
  //       break;
  //     case 'Enter':
  //       // Do something for "enter" or "return" key press.
  //       break;
  //     case ' ':
  //       // Do something for "space" key press.
  //       break;
  //     case 'Escape':
  //       // Do something for "esc" key press.
  //       break;
  //     default:
  //       return; // Quit when this doesn't handle the key event.
  //   }

  //   // Cancel the default action to avoid it being handled twice
  //   // event.preventDefault();
  // });

  const toggleDisplayControls = () =>
    setDisplayControls((prevState: boolean) => {
      return !prevState;
    });

  const handleClickMiddleLeftControls = (event: React.MouseEvent<HTMLDivElement>) => {
    if (leftPendingClick.current) {
      clearTimeout(leftPendingClick.current);
    }

    switch (event.detail) {
      case 1:
        leftPendingClick.current = setTimeout(() => {
          if (!mdUp) return toggleDisplayControls();
          isVideoEnded ? handleWatchAgain() : isVideoPlayed ? handleStopVideo() : handleStartVideo();
        }, 300);
        break;
      case 2:
        if (player.current && videoCurrentTime - 10 >= 0) {
          handleClickBackTenSeconds();
          const middleControlLeftTimeoutId = setTimeout(() => {
            setLastMiddleControlLeftTimeoutId(middleControlLeftTimeoutId);
          }, 300);
          setCurrentMiddleControlLeftTimeoutId(middleControlLeftTimeoutId);
          return () => clearTimeout(middleControlLeftTimeoutId);
        }
        break;
      case 3:
        if (player.current && videoCurrentTime - 10 >= 0) {
          handleClickBackTenSeconds();
          const middleControlLeftTimeoutId = setTimeout(() => {
            setLastMiddleControlLeftTimeoutId(middleControlLeftTimeoutId);
          }, 300);
          setCurrentMiddleControlLeftTimeoutId(middleControlLeftTimeoutId);
          return () => clearTimeout(middleControlLeftTimeoutId);
        }
        break;
      default:
        if (player.current && videoCurrentTime - 10 >= 0) {
          handleClickBackTenSeconds();
          const middleControlLeftTimeoutId = setTimeout(() => {
            setLastMiddleControlLeftTimeoutId(middleControlLeftTimeoutId);
          }, 300);
          setCurrentMiddleControlLeftTimeoutId(middleControlLeftTimeoutId);
          return () => clearTimeout(middleControlLeftTimeoutId);
        }
        break;
    }
  };

  const handleClickMiddleCenterControls = (event: React.MouseEvent<HTMLDivElement>) => {
    switch (event.detail) {
      default:
        (() => {
          if (!mdUp) return toggleDisplayControls();
          if (isVideoBuffering) return;
          isVideoEnded ? handleWatchAgain() : isVideoPlayed ? handleStopVideo() : handleStartVideo();
          setShowMiddleControlCenterAnimation(true);
          const middleControlCenterTimeoutId = setTimeout(() => {
            setLastMiddleControlCenterTimeoutId(middleControlCenterTimeoutId);
          }, 1500);

          setCurrentMiddleControlCenterTimeoutId(middleControlCenterTimeoutId);
          return () => clearTimeout(middleControlCenterTimeoutId);
        })();
    }
  };

  const handleClickMiddleRightControls = (event: React.MouseEvent<HTMLDivElement>) => {
    if (rightPendingClick.current) {
      clearTimeout(rightPendingClick.current);
    }

    switch (event.detail) {
      case 1:
        rightPendingClick.current = setTimeout(() => {
          if (!mdUp) return toggleDisplayControls();
          isVideoEnded ? handleWatchAgain() : isVideoPlayed ? handleStopVideo() : handleStartVideo();
        }, 300);
        break;
      case 2:
        if (player.current && videoCurrentTime + 10 <= videoDuration) {
          handleClickForwardTenSeconds();
          const middleControlRightTimeoutId = setTimeout(() => {
            setLastMiddleControlRightTimeoutId(middleControlRightTimeoutId);
          }, 300);
          setCurrentMiddleControlRightTimeoutId(middleControlRightTimeoutId);
          return () => clearTimeout(middleControlRightTimeoutId);
        }
        break;
      case 3:
        if (player.current && videoCurrentTime + 10 <= videoDuration) {
          handleClickForwardTenSeconds();
          const middleControlRightTimeoutId = setTimeout(() => {
            setLastMiddleControlRightTimeoutId(middleControlRightTimeoutId);
          }, 300);
          setCurrentMiddleControlRightTimeoutId(middleControlRightTimeoutId);
          return () => clearTimeout(middleControlRightTimeoutId);
        }
        break;
      default:
        if (player.current && videoCurrentTime + 10 <= videoDuration) {
          handleClickForwardTenSeconds();
          const middleControlRightTimeoutId = setTimeout(() => {
            setLastMiddleControlRightTimeoutId(middleControlRightTimeoutId);
          }, 300);
          setCurrentMiddleControlRightTimeoutId(middleControlRightTimeoutId);
          return () => clearTimeout(middleControlRightTimeoutId);
        }
        break;
    }
  };

  const eventHandler = (event: React.KeyboardEvent<HTMLDivElement>) => {
    event.stopPropagation();
    if (mdUp) handleMouseMove();
    switch (event.key) {
      case 'ArrowDown':
        // Do something for "down arrow" key press.
        break;
      case 'ArrowUp':
        // Do something for "up arrow" key press.
        break;
      case 'ArrowLeft':
        if (player.current && videoCurrentTime - 10 >= 0) {
          handleClickBackTenSeconds();
          const middleControlLeftTimeoutId = setTimeout(() => {
            setLastMiddleControlLeftTimeoutId(middleControlLeftTimeoutId);
          }, 300);
          setCurrentMiddleControlLeftTimeoutId(middleControlLeftTimeoutId);
          return () => clearTimeout(middleControlLeftTimeoutId);
        }
        break;
      case 'ArrowRight':
        if (player.current && videoCurrentTime + 10 <= videoDuration) {
          handleClickForwardTenSeconds();
          const middleControlRightTimeoutId = setTimeout(() => {
            setLastMiddleControlRightTimeoutId(middleControlRightTimeoutId);
          }, 300);
          setCurrentMiddleControlRightTimeoutId(middleControlRightTimeoutId);
          return () => clearTimeout(middleControlRightTimeoutId);
        }
        break;
      case 'f':
        handleToggleFullScreen();
        break;
      case 'F':
        handleToggleFullScreen();
        break;
      case 'Enter':
        // Do something for "enter" or "return" key press.
        break;
      case ' ':
        isVideoEnded ? handleWatchAgain() : isVideoPlayed ? handleStopVideo() : handleStartVideo();
        setShowMiddleControlCenterAnimation(true);
        setTimeout(() => {
          setShowMiddleControlCenterAnimation(false);
        }, 1500);
        break;
      case 'Escape':
        // Do something for "Escape" or "return" key press.
        break;
      default:
        return; // Quit when this doesn't handle the key event.
    }
  };

  if (!movie || !movieId) return <ErrorScreen errorTitle='Film, który chcesz zobaczyć nie istnieje' />;
  if (isUserPaidForCurrentMovieError || (!isUserPaidForCurrentMovie && !isUserPaidForCurrentMovieLoading))
    return <PlayerError movieId={movieId} user={user} movie={movie} />;
  if (isUserPaidForCurrentMovieLoading || isBookmarkLoading) return <LoadingScreen />;
  return (
    <Container onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => eventHandler(event)}>
      <HelmetContent
        title={t('player.pageTitle', { movieTitle: convertMovieTileToOnlyItsOwnLanguage(t(movie.title)) })}
        description={t('player.pageDescription')}
      />
      <VideoWrapper ref={videoContainerRef}>
        <VideoWrapper2>
          <VideoWrapper3>
            <div
              style={{
                width: '100%',
                position: 'absolute',
              }}
              ref={playerRef}></div>
            <img
              style={{
                width: 'auto',
                height: '100%',
                maxWidth: '100vw',
              }}
              alt='DATA_BASE_64_STRING_CONTENT_16_9'
              src={`data:image/webp;base64,${DATA_BASE_64_STRING_CONTENT_16_9}`}
            />
            <button style={{ opacity: 0, position: 'absolute', left: '50%', top: 0 }} ref={buttonRef} />
          </VideoWrapper3>
        </VideoWrapper2>
        {isVideoLoaded && (
          <ExtraLayer
            onMouseLeave={mdUp ? handleMouseLeaveFromTheExtraLayer : () => {}}
            onMouseMove={mdUp ? handleMouseMove : () => {}}>
            <ControlsLayer displayControls={displayControls}>
              <TopControls>
                <Box sx={{ display: 'flex', alignItems: 'center', width: '60%' }}>
                  <IconButton
                    onClick={handleClickMoveToMoviePage}
                    aria-label='move to movie page'
                    sx={{ margin: '0 15px 0 0', display: 'inline-block' }}>
                    <FontAwesomeIcon icon={faArrowLeftLong} />
                  </IconButton>
                  <Title>{t(movie.title)}</Title>
                </Box>
                <Box>
                  <IconButton
                    title={t('player.audioAndSubtitles.label')}
                    sx={{ margin: '0 7px 0 0' }}
                    onClick={handleOpenSubtitlesAndAudio}
                    id='subtitles-and-audio-list-button'
                    aria-controls={isSubtitlesAndAudioOpen ? 'subtitles-and-audio-list-menu' : undefined}
                    aria-expanded={isSubtitlesAndAudioOpen ? 'true' : undefined}
                    aria-haspopup='true'
                    size='medium'>
                    <FontAwesomeIcon icon={faClosedCaptioning} />
                  </IconButton>
                  <StyledMenu
                    container={videoContainerRef.current}
                    anchorEl={subtitlesAndAudioAnchorEl}
                    MenuListProps={{
                      'aria-labelledby': 'subtitles-and-audio-list-button',
                    }}
                    id='audio-and-subtitles-menu'
                    open={isSubtitlesAndAudioOpen}
                    onClose={handleCloseSubtitlesAndAudio}>
                    <Stack direction='row'>
                      <Box>
                        <Typography fontSize='18px' fontWeight={700} variant='h4' color='text.primary'>
                          {t('player.audio.label')}
                        </Typography>
                        <MenuList>
                          {audioes.map((audioOption) => (
                            <MenuItem
                              onClick={() => handleChangeAudio(audioOption.language)}
                              key={audioOption.language}
                              value={audioOption.language}>
                              {audio === audioOption.language && (
                                <ListItemIcon>
                                  <FontAwesomeIcon icon={faCheck} />
                                </ListItemIcon>
                              )}
                              <ListItemText
                                disableTypography
                                sx={{ fontWeight: audio === audioOption.language ? 700 : 300 }}
                                inset={audio !== audioOption.language}>
                                {t(audioOption.label)}
                              </ListItemText>
                            </MenuItem>
                          ))}
                        </MenuList>
                      </Box>
                      <Box>
                        <Typography fontSize='18px' fontWeight={700} variant='h4' color='text.primary'>
                          {t('player.subtitles.label')}
                        </Typography>
                        {subtitles && (
                          <MenuList sx={{ maxWidth: '350px', maxHeight: '400px' }}>
                            <MenuItem onClick={() => handleChangeSubtitle('off')} key='off' value='off'>
                              {subtitle === 'off' && (
                                <ListItemIcon>
                                  <FontAwesomeIcon icon={faCheck} />
                                </ListItemIcon>
                              )}
                              <ListItemText
                                disableTypography
                                sx={{ fontWeight: subtitle === 'off' ? 700 : 300 }}
                                inset={subtitle !== 'off'}>
                                {t('shared.select.off')}
                              </ListItemText>
                            </MenuItem>
                            {subtitles.map((subtitleOption: Vimeo.VimeoTextTrack) => (
                              <MenuItem
                                onClick={() => handleChangeSubtitle(subtitleOption.language)}
                                key={subtitleOption.language}
                                value={subtitleOption.language}>
                                {subtitle === subtitleOption.language && (
                                  <ListItemIcon>
                                    <FontAwesomeIcon icon={faCheck} />
                                  </ListItemIcon>
                                )}
                                <ListItemText
                                  disableTypography
                                  sx={{ fontWeight: subtitle === subtitleOption.language ? 700 : 400 }}
                                  inset={subtitle !== subtitleOption.language}>
                                  {subtitleOption.label}
                                </ListItemText>
                              </MenuItem>
                            ))}
                          </MenuList>
                        )}
                      </Box>
                    </Stack>
                  </StyledMenu>
                  <IconButton
                    title={t('player.quality.label')}
                    sx={{ margin: '0 7px 0 0' }}
                    onClick={handleOpenQualitiesList}
                    id='qualities-list-button'
                    aria-controls={isQualitiesListOpen ? 'qualities-list-menu' : undefined}
                    aria-expanded={isQualitiesListOpen ? 'true' : undefined}
                    aria-haspopup='true'
                    size='medium'>
                    <FontAwesomeIcon icon={faGear} />
                  </IconButton>
                  <StyledMenu
                    container={videoContainerRef.current}
                    anchorEl={qualitiesListAnchorEl}
                    MenuListProps={{
                      'aria-labelledby': 'qualities-list-button',
                    }}
                    id='qualities-list-menu'
                    open={isQualitiesListOpen}
                    onClose={handleCloseQualitieslist}>
                    <Box>
                      <Typography fontSize='18px' fontWeight={700} variant='h4' color='text.primary'>
                        {t('player.quality.label')}
                      </Typography>
                      {qualities && (
                        <MenuList sx={{ maxWidth: '350px', maxHeight: '400px' }}>
                          {qualities.map((qualityOption: Vimeo.VimeoVideoQualityObject) => {
                            if (!qualityResolver(qualityOption.id as Vimeo.VimeoVideoQuality)) return null;
                            return (
                              <MenuItem
                                onClick={() => handleChangeQuality(qualityOption.id as Vimeo.VimeoVideoQuality)}
                                key={qualityOption.id}
                                value={qualityOption.id}>
                                {quality === qualityOption.id && (
                                  <ListItemIcon>
                                    <FontAwesomeIcon icon={faCheck} />
                                  </ListItemIcon>
                                )}
                                <ListItemText
                                  disableTypography
                                  sx={{ fontWeight: quality === qualityOption.id ? 700 : 300 }}
                                  inset={quality !== qualityOption.id}>
                                  {t(qualityResolver(qualityOption.id as Vimeo.VimeoVideoQuality))}
                                </ListItemText>
                              </MenuItem>
                            );
                          })}
                        </MenuList>
                      )}
                    </Box>
                  </StyledMenu>

                  {/* {quality && qualities && (
                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                      <InputLabel id='demo-simple-select-label'>Quality</InputLabel>
                      <Select
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        value={quality}
                        size='small'
                        variant='outlined'
                        label='Qualities'
                        onChange={handleChangeQuality}>
                        {qualities.map((quality: Vimeo.VimeoVideoQualityObject) => (
                          <MenuItem key={quality.id} value={quality.id}>
                            {quality.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  {subtitles && (
                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                      <InputLabel id='demo-simple-select-helper-label'>{t('player.subtitles.label')}</InputLabel>
                      <Select
                        labelId='demo-simple-select-helper-label'
                        id='demo-simple-select-helper'
                        value={subtitle}
                        label='Subtitles'
                        size='small'
                        onChange={handleChangeSubtitle}>
                        <MenuItem value=''>None</MenuItem>
                        {subtitles.map((subtitle: Vimeo.VimeoTextTrack) => (
                          <MenuItem key={subtitle.language} value={subtitle.language}>
                            {subtitle.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id='demo-simple-select-helper-label'>{t('player.audio.label')}</InputLabel>
                    <Select
                      labelId='demo-simple-select-helper-label'
                      id='demo-simple-select-helper'
                      value={audio}
                      sx={{ zIndex: 9999 }}
                      label='Audio'
                      size='small'
                      onChange={handleChangeAudio}>
                      {audioes.map((audio) => (
                        <MenuItem key={audio.language} value={audio.language}>
                          {audio.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl> */}
                </Box>
              </TopControls>
              <CenterControls isFullscreen={isFullscreen}>
                <CenterControlsLeft onClick={handleClickMiddleLeftControls}>
                  <Box
                    sx={{
                      position: 'relative',
                      opacity: showMiddleControlLeftAnimation ? 1 : 0,
                      transition: 'opacity 0.3s',
                    }}>
                    <FontAwesomeIcon color={theme.palette.common.white} size='3x' icon={faRotateLeft} />
                    <BackTenSecondsBadge left='53%' top='55%' fontSize='14px'>
                      10
                    </BackTenSecondsBadge>
                  </Box>
                </CenterControlsLeft>
                <CenterControlsMiddle onClick={handleClickMiddleCenterControls}>
                  {(!isVideoPlayed || showMiddleControlCenterAnimation || !mdUp) && !isVideoBuffering && (
                    <IconButton
                      sx={{ minWidth: '88px' }}
                      onClick={(event) => {
                        if (!mdUp) event.stopPropagation();
                        !mdUp
                          ? isVideoEnded
                            ? handleWatchAgain()
                            : isVideoPlayed
                              ? handleStopVideo()
                              : handleStartVideo()
                          : () => {};
                      }}>
                      <FontAwesomeIcon
                        color={theme.palette.common.white}
                        size='3x'
                        icon={isVideoEnded ? faArrowsRotate : isVideoPlayed ? faPause : faPlay}
                      />
                    </IconButton>
                  )}
                </CenterControlsMiddle>
                <CenterControlsRight onClick={handleClickMiddleRightControls}>
                  <Box
                    sx={{
                      position: 'relative',
                      opacity: showMiddleControlRightAnimation ? 1 : 0,
                      transition: 'opacity 0.3s',
                    }}>
                    <FontAwesomeIcon color={theme.palette.common.white} size='3x' icon={faRotateRight} />
                    <ForwardTenSecondsBadge left='47%' top='54%' fontSize='14px'>
                      10
                    </ForwardTenSecondsBadge>
                  </Box>
                </CenterControlsRight>
              </CenterControls>
              <BottomControls>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  {!smUp && (
                    <IconButton
                      size='large'
                      ref={playPauseButton}
                      sx={{ minWidth: '52px', margin: '0 15px 0 0' }}
                      onClick={isVideoEnded ? handleWatchAgain : isVideoPlayed ? handleStopVideo : handleStartVideo}>
                      <FontAwesomeIcon icon={isVideoEnded ? faArrowsRotate : isVideoPlayed ? faPause : faPlay} />
                    </IconButton>
                  )}
                  <Typography sx={{ fontSize: '14px', minWidth: '55px', userSelect: 'none' }} color='text.primary'>
                    {formatDuration(videoCurrentTime)}
                  </Typography>
                  <Slider
                    onChange={handleChangeSlider}
                    size='medium'
                    max={videoDuration}
                    value={videoCurrentTime}
                    aria-label='Movie timeline'
                  />
                  <Typography
                    sx={{ fontSize: '14px', minWidth: '55px', textAlign: 'right', userSelect: 'none' }}
                    color='text.primary'>
                    {formatDuration(videoDuration)}
                  </Typography>
                  {!smUp && (
                    <IconButton sx={{ position: 'relative', margin: '0 0 0 15px' }} onClick={handleToggleFullScreen}>
                      <FontAwesomeIcon icon={isFullscreen ? faCompress : faExpand} />
                    </IconButton>
                  )}
                </Box>
                {smUp && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      flexWrap: 'wrap',
                      width: '100%',
                      padding: '0 50px',
                    }}>
                    <Stack direction='row' spacing={0}>
                      <IconButton
                        size='large'
                        sx={{ position: 'relative' }}
                        onClick={() => {
                          if (player.current && videoCurrentTime - 10 >= 0) {
                            handleClickBackTenSeconds();
                            const middleControlLeftTimeoutId = setTimeout(() => {
                              setLastMiddleControlLeftTimeoutId(middleControlLeftTimeoutId);
                            }, 300);
                            setCurrentMiddleControlLeftTimeoutId(middleControlLeftTimeoutId);
                            return () => clearTimeout(middleControlLeftTimeoutId);
                          }
                        }}>
                        <FontAwesomeIcon icon={faRotateLeft} />
                        <BackTenSecondsBadge>10</BackTenSecondsBadge>
                      </IconButton>
                      <IconButton
                        disabled={isVideoBuffering}
                        ref={playPauseButton}
                        size='large'
                        sx={{ minWidth: '52px' }}
                        onClick={isVideoEnded ? handleWatchAgain : isVideoPlayed ? handleStopVideo : handleStartVideo}>
                        <FontAwesomeIcon icon={isVideoEnded ? faArrowsRotate : isVideoPlayed ? faPause : faPlay} />
                      </IconButton>
                      <IconButton
                        size='large'
                        sx={{ position: 'relative' }}
                        onClick={() => {
                          if (player.current && videoCurrentTime + 10 <= videoDuration) {
                            handleClickForwardTenSeconds();
                            const middleControlRightTimeoutId = setTimeout(() => {
                              setLastMiddleControlRightTimeoutId(middleControlRightTimeoutId);
                            }, 300);
                            setCurrentMiddleControlRightTimeoutId(middleControlRightTimeoutId);
                            return () => clearTimeout(middleControlRightTimeoutId);
                          }
                        }}>
                        <FontAwesomeIcon icon={faRotateRight} />
                        <ForwardTenSecondsBadge>10</ForwardTenSecondsBadge>
                      </IconButton>
                    </Stack>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <IconButton sx={{ margin: '0 20px 0 0' }} onClick={handleToggleMuteAudio}>
                        <FontAwesomeIcon icon={isSoundMuted ? faVolumeXmark : faVolumeHigh} />
                      </IconButton>
                      <VolumeSlider
                        onChange={handleChangeVolumeSlider}
                        sx={{ margin: '0 20px 0 0', width: '100%', maxWidth: '100px', minWidth: '90px' }}
                        size='small'
                        min={0.0}
                        step={0.01}
                        max={1.0}
                        value={volume}
                        aria-label='Movie timeline'
                      />
                      {smUp && (
                        <IconButton sx={{ position: 'relative' }} onClick={handleToggleFullScreen}>
                          <FontAwesomeIcon icon={isFullscreen ? faCompress : faExpand} />
                        </IconButton>
                      )}
                    </Box>
                  </Box>
                )}
              </BottomControls>
            </ControlsLayer>
          </ExtraLayer>
        )}
      </VideoWrapper>
    </Container>
  );
};

interface OwnProps {
  user: User;
}
