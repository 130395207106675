import React, { useEffect } from 'react';
import { Container } from './NotFound.styled';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { HelmetContent } from 'components/Common/HelmetContent/HelmetContent';
import { scrollTop } from 'utils/Common/scroll-top/scroll-top';

export const NotFound: React.FC = () => {
  const { t } = useTranslation();

  useEffect(() => {
    scrollTop();
  }, []);

  return (
    <Container>
      <HelmetContent title={t('notFound.pageTitle')} description={t('notFound.pageDescription')} />
      <Typography color='text.primary' component='h1' fontSize='36px'>
        {t('notFound.title')}
      </Typography>
    </Container>
  );
};
