import { styled } from '@mui/material';

export const Container = styled('ul')(({ theme }) => ({
  width: '100%',
  display: 'grid',

  gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
  justifyItems: 'center',
  gridTemplateRows: '1fr',
  gridRowGap: '50px',
  gridColumnGap: '50px',
  [theme.breakpoints.up('md')]: {
    gridTemplateRows: 'auto',
    // gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 300px))',
  },

  [theme.breakpoints.up('lg')]: {
    gridColumnGap: '40px',
    gridRowGap: '40px',
    gridTemplateRows: 'auto',
    gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
  },
}));
