import React, { useEffect } from 'react';
import { Container } from './MainLayout.styled';
import { Header } from 'components/Common/Header/Header';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Footer } from 'components/Common/Footer/Footer';
import { User } from 'firebase/auth';
import { Locale, locales, switchLanguage } from 'utils/Common/i18n';
import { useTranslation } from 'react-i18next';

type ParamsType = {
  language: string | undefined;
};

export const MainLayout: React.FC<OwnProps> = ({ user }: { user: User | null }) => {
  const { language } = useParams<ParamsType>();
  const navigate = useNavigate();
  const location = useLocation();
  const { i18n } = useTranslation();

  useEffect(() => {
    if (!language && location.pathname === '/') {
      const i18nLanguage = i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language;
      if (i18nLanguage === 'en') return;
      return navigate(`/${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}`);
    }
    if (language) {
      if (!locales.some((locale: Locale) => locale.value === language)) return navigate(`/${locales[0].value}`);
      switchLanguage(language);
    }
  }, []);

  return (
    <Container>
      <Header user={user} />
      <Outlet />
      <Footer />
    </Container>
  );
};

interface OwnProps {
  user: User | null;
}
