import {
  faCartPlus,
  faCheck,
  faFileSignature,
  faFilm,
  faGear,
  faHouse,
  faLanguage,
  faMagnifyingGlass,
  faPhotoFilm,
  faSignature,
  faTableList,
  faTrash,
  faUser,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Snackbar,
  TextField,
  Typography,
  createFilterOptions,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { SegmentDescriptor } from 'components/Common/SegmentDecriptor/SegmentDecriptor';
import { BasketContext } from 'context/BasketContext';
import { MoviesContext } from 'context/MoviesContext';
import { WhishlistContext } from 'context/WhishlistContext';
import { User } from 'firebase/auth';
import { firebaseSignOutUser, removeItemFromWhishlist } from 'firestore';
import { useRouteMatch } from 'hooks/useRouteMatch/useRouteMatch';
import { WhishlistMovie } from 'models/Movie/whishlist-movie/whishlist-movie';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { MovieSearchOption } from 'types/shared/movie-type';
import { convertMovieTileToDescriptivePath } from 'utils/Common/convert-movie-title-to-descriptive-path/convert-movie-title-to-descriptive-path';
import { Locale, locales, switchLanguage } from 'utils/Common/i18n';
import { resolveLanguages } from 'utils/Common/resolve-languages/resolve-languages';
import { convertMoviesToMovieSearchOptions } from 'utils/Header/convert-movies-to-movie-search-options/convert-movies-to-movie-search-options';
import { filterMoviesByCategory } from 'utils/Home/filter-movies-by-category/filter-movies-by-category';
import { ImageLoader } from '../ImageLoader/ImageLoader';
import {
  BottomNavWrapper,
  Container,
  CustomBottomNavigation,
  CustomBottomNavigationAction,
  ListItemNavLink,
  Logo,
  LogoWrapper,
  Search,
  SearchList,
  SearchListDesktop,
  SearchListItem,
  SearchListItemImageWrapper,
  SearchListItemWrapper,
  StyledBadge,
  StyledMenu,
  StyledMenuItemNavLink,
  StyledPopper,
  StyledPopperDesktop,
  TopNav,
  TopNavList,
  TopNavListItem,
  TopNavWrapper,
} from './Header.styled';

export const Header: React.FC<OwnProps> = ({ user }: { user: User | null }) => {
  const { items: whishlistItems, counter: whishlistItemsCounter } = useContext(WhishlistContext);
  const { movies } = useContext(MoviesContext);
  const [isSearchMoviesOpen, setIsSearchMoviesOpen] = useState<boolean>(false);
  const [isAccountMenuOpen, setIsAccountMenuOpen] = useState<boolean>(false);
  const [isMoviesWhishListOpen, setIsMoviesWhishListOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [whishListAnchorEl, setWhishListAnchorEl] = useState<null | HTMLElement>(null);
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const [languagesListAnchorEl, setLanguagesListAnchorEl] = useState<null | HTMLElement>(null);
  const [isLanguagesListOpen, setIsLanguagesListOpen] = useState<boolean>(false);

  const [signOutLoading, setSignOutLoading] = useState<boolean>(false);
  const [signOutError, setSignOutError] = useState<null | string>(null);
  const [signOutSuccess, setSignOutSuccess] = useState<null | string>(null);

  const [movieSearchOptions] = useState<MovieSearchOption[]>(
    convertMoviesToMovieSearchOptions(filterMoviesByCategory(movies, 'all')),
  );

  const [i18nLanguage, setI18nLanguage] = useState(
    i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language,
  );

  const routeMatch = useRouteMatch([
    i18nLanguage === 'en' ? '/' : `/${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}`,
    `/${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}/my-movies`,
    `/${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}/login`,
    `/${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}/account?open=settings`,
  ]);
  const currentTab = routeMatch?.pattern?.path;
  const navigate = useNavigate();
  const { counter } = useContext(BasketContext);

  useEffect(() => {
    setI18nLanguage(i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language);
  }, [i18n.language]);

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    limit: 10,
    stringify: (option: MovieSearchOption) => t(option.title),
  });

  const theme = useTheme();
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'));

  const inputRef: React.MutableRefObject<null | HTMLInputElement> = useRef<null | HTMLInputElement>(null);

  const handleOpenSearchMovies = () => {
    setIsSearchMoviesOpen(true);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleCloseSearchMovies = () => {
    setIsSearchMoviesOpen(false);
    if (inputRef.current) {
      inputRef.current.blur();
    }
  };

  const handleClickSignIn = () => {
    navigate(`/${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}/login`);
  };

  const handleClickRegister = () => {
    navigate(`${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}/register`);
  };

  const handleOpenAccountMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsAccountMenuOpen(true);
  };

  const handleCloseAccountMenu = () => {
    setAnchorEl(null);
    setIsAccountMenuOpen(false);
  };

  const handleOpenLanguagesList = (event: React.MouseEvent<HTMLElement>) => {
    setLanguagesListAnchorEl(event.currentTarget);
    setIsLanguagesListOpen(true);
  };

  const handleCloseLangugaeslist = () => {
    setLanguagesListAnchorEl(null);
    setIsLanguagesListOpen(false);
  };

  const handleOpenMoviesWhishlist = (event: React.MouseEvent<HTMLElement>) => {
    setWhishListAnchorEl(event.currentTarget);
    setIsMoviesWhishListOpen(true);
  };

  const handleCloseMoviesWhishlist = () => {
    setWhishListAnchorEl(null);
    setIsMoviesWhishListOpen(false);
  };

  const handleClickBasket = () => {
    navigate(`/${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}/basket`);
  };

  const handleClickSignOut = async () => {
    try {
      setSignOutSuccess(null);
      setSignOutLoading(true);
      setSignOutError(null);
      await firebaseSignOutUser();
      setSignOutSuccess(t('shared.requestMessages.logOutSuccess'));
    } catch (e: any) {
      setSignOutError(t('shared.requestMessages.logOutError'));
    } finally {
      setSignOutLoading(false);
      handleCloseAccountMenu();
    }
  };

  const handleClickRemoveMovieFromWhishlist = async (uid: string, itemId: string) => {
    try {
      await removeItemFromWhishlist(uid, itemId);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Container>
      <TopNavWrapper>
        <Box sx={{ display: 'flex' }}>
          <LogoWrapper
            to={
              i18nLanguage === 'en'
                ? '/'
                : `/${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}`
            }>
            <Logo alt={t('shared.header.logo')} src='/assets/images/logo/logo.svg' />
          </LogoWrapper>
          {lgUp && (
            <TopNav>
              <TopNavList>
                <TopNavListItem>
                  <ListItemNavLink
                    className={({ isActive }: { isActive: boolean }) => (isActive ? 'active' : '')}
                    end
                    to={
                      i18nLanguage === 'en'
                        ? '/'
                        : `/${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}`
                    }>
                    {t('shared.header.navigation.element1')}
                  </ListItemNavLink>
                </TopNavListItem>
                <TopNavListItem>
                  {user ? (
                    <ListItemNavLink
                      className={({ isActive }: { isActive: boolean }) => (isActive ? 'active' : '')}
                      end
                      to={`/${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}/my-movies`}>
                      {t('shared.header.navigation.element2')}
                    </ListItemNavLink>
                  ) : (
                    <ListItemNavLink
                      className={({ isActive }: { isActive: boolean }) => (isActive ? '' : '')}
                      end
                      to={`/${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}/login`}>
                      {t('shared.header.navigation.element2')}
                    </ListItemNavLink>
                  )}
                </TopNavListItem>
              </TopNavList>
            </TopNav>
          )}
        </Box>
        {lgUp && (
          <Autocomplete
            forcePopupIcon={true}
            noOptionsText={t('shared.header.searchBar.movieNotFound')}
            popupIcon={
              <FontAwesomeIcon
                size='xs'
                icon={faMagnifyingGlass}
                style={{ rotate: '0 !important', transform: 'none' }}
              />
            }
            PopperComponent={StyledPopperDesktop}
            sx={{
              flexGrow: 1,
              maxWidth: '500px',
              margin: '0 40px',
              transition: '0.3s color',
              '& .MuiAutocomplete-popupIndicatorOpen': {
                transform: 'none',
                color: theme.palette.primary.main,
                transition: '0.3s color',
              },
            }}
            options={movieSearchOptions}
            ListboxComponent={SearchListDesktop}
            id='filter-movie'
            getOptionLabel={(option) => option.title}
            filterOptions={filterOptions}
            renderOption={(a, element: MovieSearchOption) => (
              <SearchListItem key={element.id}>
                <SearchListItemImageWrapper
                  onClick={handleCloseSearchMovies}
                  to={`/${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}/movies/${convertMovieTileToDescriptivePath(t(element.title))}/${element.id}`}>
                  <ImageLoader
                    imageFirebasePath={
                      element.imageSmall[i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language]
                    }
                  />
                </SearchListItemImageWrapper>
                <SearchListItemWrapper>
                  <SegmentDescriptor
                    segmentDescriptorSize='small'
                    title={t('shared.segmentDescriptor.title')}
                    description={t(element.title)}
                    icon={faSignature}
                  />
                  <SegmentDescriptor
                    segmentDescriptorSize='small'
                    title={t('shared.segmentDescriptor.description')}
                    description={`${t(element.description).substring(0, 80)} ...`}
                    icon={faFileSignature}
                  />
                </SearchListItemWrapper>
              </SearchListItem>
            )}
            renderInput={(params) => (
              <TextField
                variant='standard'
                {...params}
                inputRef={inputRef}
                placeholder={t('shared.header.searchBar.placeholder')}
              />
            )}
          />
        )}
        <Box sx={{ display: { xs: 'flex' } }}>
          {lgUp && !user && (
            <>
              <Button
                onClick={handleClickSignIn}
                variant='outlined'
                sx={{ margin: '0 15px 0 0', textTransform: 'none' }}>
                {t('shared.buttons.logIn')}
              </Button>
              <Button
                onClick={handleClickRegister}
                variant='contained'
                sx={{ margin: '0 15px 0 0', textTransform: 'none' }}>
                {t('shared.buttons.register')}
              </Button>
            </>
          )}
          {!lgUp && (
            <IconButton
              onClick={handleOpenSearchMovies}
              sx={{ margin: '0 7px 0 0' }}
              size='small'
              aria-label='Search movies'>
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </IconButton>
          )}
          {lgUp && user && (
            <>
              <IconButton
                onClick={handleOpenAccountMenu}
                sx={{ margin: '0 7px 0 0' }}
                size='small'
                id='demo-customized-button'
                aria-controls={isAccountMenuOpen ? 'account-menu' : undefined}
                aria-haspopup='true'
                aria-label='open user account menu'
                aria-expanded={isAccountMenuOpen ? 'true' : undefined}>
                <FontAwesomeIcon icon={faUser} />
                {/* {user.displayName && (
                  <Typography
                    component='p'
                    sx={{
                      position: 'absolute',
                      left: '50%',
                      top: '100%',
                      width: '50px',
                      transform: 'translateX(-50%)',
                    }}
                    fontSize={lgUp ? '12px' : '8px'}
                    color='primary.main'>
                    {user.displayName}
                  </Typography>
                )} */}
              </IconButton>
              <StyledMenu
                anchorEl={anchorEl}
                MenuListProps={{
                  'aria-labelledby': 'demo-customized-button',
                }}
                id='account-menu'
                open={isAccountMenuOpen}
                onClose={handleCloseAccountMenu}>
                <MenuItem sx={{ margin: '0 0 10px 0', padding: 0 }}>
                  <StyledMenuItemNavLink
                    to={`/${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}/account?open=shopping`}>
                    <FontAwesomeIcon style={{ margin: '0 15px 0 0' }} icon={faTableList} />{' '}
                    {t('shared.header.navigation.account.shopping')}
                  </StyledMenuItemNavLink>
                </MenuItem>

                <Divider sx={{ margin: '0 0 10px 0 !important' }} />
                <MenuItem sx={{ margin: user ? '0 0 40px 0' : '0', padding: 0 }}>
                  <StyledMenuItemNavLink
                    to={`/${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}/account?open=settings`}>
                    <FontAwesomeIcon style={{ margin: '0 15px 0 0' }} icon={faGear} />{' '}
                    {t('shared.header.navigation.account.accountSettings')}
                  </StyledMenuItemNavLink>
                </MenuItem>
                <LoadingButton
                  loading={signOutLoading}
                  onClick={handleClickSignOut}
                  fullWidth
                  variant='contained'
                  sx={{ textTransform: 'none' }}>
                  {t('shared.buttons.logOut')}
                </LoadingButton>
              </StyledMenu>
            </>
          )}
          <IconButton
            title={t('shared.header.navigation.languageSelector.label')}
            sx={{ margin: '0 7px 0 0' }}
            onClick={handleOpenLanguagesList}
            id='languages-list-button'
            aria-controls={isLanguagesListOpen ? 'languages-list-menu' : undefined}
            aria-expanded={isLanguagesListOpen ? 'true' : undefined}
            aria-haspopup='true'
            aria-label='open language menu'
            size='small'>
            <Typography
              component='p'
              sx={{
                position: 'absolute',
                left: '50%',
                top: '100%',
                width: '50px',
                transform: 'translateX(-50%)',
              }}
              fontSize={lgUp ? '12px' : '10px'}
              color='primary.main'>
              {resolveLanguages([i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language], t)[0]}
            </Typography>
            <FontAwesomeIcon icon={faLanguage} />
          </IconButton>
          <IconButton
            title={t('shared.header.navigation.whishlist.label')}
            sx={{ margin: '0 7px 0 0' }}
            onClick={
              user
                ? handleOpenMoviesWhishlist
                : () => navigate(`/${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}/login`)
            }
            id='movies-whishlist-button'
            aria-controls={isMoviesWhishListOpen ? 'movies-whishlist-menu' : undefined}
            aria-expanded={isMoviesWhishListOpen ? 'true' : undefined}
            aria-haspopup='true'
            aria-label='whishlist'
            size='small'>
            {whishlistItemsCounter === 0 ? (
              <FontAwesomeIcon icon={faPhotoFilm} />
            ) : (
              <StyledBadge color='primary' badgeContent={whishlistItemsCounter}>
                <FontAwesomeIcon icon={faPhotoFilm} />
              </StyledBadge>
            )}
          </IconButton>
          <StyledMenu
            anchorEl={languagesListAnchorEl}
            MenuListProps={{
              'aria-labelledby': 'languages-list-button',
            }}
            id='languages-list-menu'
            open={isLanguagesListOpen}
            onClose={handleCloseLangugaeslist}>
            <Box>
              <Typography fontSize='18px' fontWeight={700} variant='h4' color='text.primary'>
                {t('shared.header.navigation.languageSelector.label')}
              </Typography>
              <MenuList sx={{ maxWidth: '350px', maxHeight: '400px' }}>
                {locales.map((element: Locale) => (
                  <MenuItem
                    key={t(element.label)}
                    onClick={() => {
                      switchLanguage(element.value, () => {
                        if (
                          (location.pathname.split('/').length === 1 || location.pathname.split('/').length === 2) &&
                          element.value === 'en'
                        )
                          return navigate('/');
                        return navigate(
                          `/${element.value}${location.pathname.split('/').slice(2).join('/') ? `/${location.pathname.split('/').slice(2).join('/')}` : ''}${location.search ? location.search : ''}`,
                        );
                      });
                      handleCloseLangugaeslist();
                    }}>
                    {element.value === (i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language) && (
                      <ListItemIcon>
                        <FontAwesomeIcon style={{ margin: '0 10px 0 0' }} icon={faCheck} />
                      </ListItemIcon>
                    )}
                    <ListItemText
                      disableTypography
                      sx={{
                        fontWeight:
                          element.value === (i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language)
                            ? 700
                            : 300,
                      }}
                      inset={
                        element.value !== (i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language)
                      }>
                      {t(element.label)}
                    </ListItemText>
                  </MenuItem>
                ))}
              </MenuList>
            </Box>
          </StyledMenu>
          {user && whishlistItems.length > 0 && (
            <StyledMenu
              anchorEl={whishListAnchorEl}
              MenuListProps={{
                'aria-labelledby': 'movies-whishlist-button',
              }}
              id='movies-whishlist-menu'
              open={isMoviesWhishListOpen}
              onClose={handleCloseMoviesWhishlist}>
              <MenuList sx={{ maxWidth: '350px', maxHeight: '400px' }}>
                {whishlistItems.map((element: WhishlistMovie) => (
                  <SearchListItem key={element.id}>
                    <SearchListItemImageWrapper
                      onClick={handleCloseMoviesWhishlist}
                      to={`/${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}/movies/${convertMovieTileToDescriptivePath(t(element.title))}/${element.id}`}>
                      <ImageLoader
                        imageFirebasePath={
                          element.imageSmall[i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language]
                        }
                      />
                    </SearchListItemImageWrapper>
                    <SearchListItemWrapper>
                      <SegmentDescriptor
                        segmentDescriptorSize='small'
                        title={t('shared.segmentDescriptor.title')}
                        description={t(element.title)}
                        icon={faSignature}
                      />
                      <Button
                        size='small'
                        variant='outlined'
                        onClick={async () => await handleClickRemoveMovieFromWhishlist(user.uid, element.id)}
                        sx={{
                          width: '100%',
                          margin: '40px 0 0 0',
                          maxWidth: '200px',
                          display: 'flex',
                          textTransform: 'none',
                        }}
                        startIcon={<FontAwesomeIcon icon={faTrash} />}>
                        {t('shared.buttons.removeFromWhishlist')}
                      </Button>
                    </SearchListItemWrapper>
                  </SearchListItem>
                ))}
              </MenuList>
            </StyledMenu>
          )}

          <IconButton
            onClick={
              user
                ? handleClickBasket
                : () => navigate(`/${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}/login`)
            }
            size='small'
            aria-label='Basket'>
            {counter === 0 ? (
              <FontAwesomeIcon icon={faCartPlus} />
            ) : (
              <StyledBadge badgeContent={counter} color='primary'>
                <FontAwesomeIcon icon={faCartPlus} />
              </StyledBadge>
            )}
          </IconButton>
        </Box>
        {!lgUp && (
          <Search isSearchMoviesOpen={isSearchMoviesOpen}>
            <IconButton
              sx={{ margin: '0 10px 0 0' }}
              aria-label='close search movies'
              onClick={handleCloseSearchMovies}>
              <FontAwesomeIcon icon={faXmark} />
            </IconButton>
            <Autocomplete
              noOptionsText={t('shared.autocomplete.searchBar.movieNotFound')}
              PopperComponent={StyledPopper}
              sx={{ flexGrow: 1 }}
              options={movieSearchOptions}
              id='filter-movie'
              getOptionLabel={(option) => option.title}
              filterOptions={filterOptions}
              ListboxComponent={SearchList}
              renderOption={(a, element) => (
                <SearchListItem key={element.id}>
                  <SearchListItemImageWrapper
                    onClick={handleCloseSearchMovies}
                    to={`/${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}/movies/${convertMovieTileToDescriptivePath(t(element.title))}/${element.id}`}>
                    <ImageLoader
                      imageFirebasePath={
                        element.imageSmall[i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language]
                      }
                    />
                  </SearchListItemImageWrapper>
                  <SearchListItemWrapper>
                    <SegmentDescriptor
                      segmentDescriptorSize='small'
                      title={t('shared.segmentDescriptor.title')}
                      description={t(element.title)}
                      icon={faSignature}
                    />
                    <SegmentDescriptor
                      segmentDescriptorSize='small'
                      title={t('shared.segmentDescriptor.description')}
                      description={`${t(element.description).substring(0, 80)} ...`}
                      icon={faFileSignature}
                    />
                  </SearchListItemWrapper>
                </SearchListItem>
              )}
              renderInput={(params) => (
                <TextField
                  variant='standard'
                  {...params}
                  inputRef={inputRef}
                  placeholder={t('shared.header.searchBar.placeholder')}
                />
              )}
            />
          </Search>
        )}
      </TopNavWrapper>
      {!lgUp && (
        <BottomNavWrapper>
          <CustomBottomNavigation
            showLabels
            value={currentTab}
            onChange={(event, newValue) => {
              navigate(newValue);
            }}>
            <CustomBottomNavigationAction
              label={t('shared.bottomNavigation.element1')}
              value={
                i18nLanguage === 'en'
                  ? '/'
                  : `/${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}`
              }
              icon={<FontAwesomeIcon icon={faHouse} />}
            />
            <CustomBottomNavigationAction
              label={t('shared.bottomNavigation.element2')}
              sx={{
                '&.Mui-selected': {
                  color: !user ? theme.palette.text.secondary : '',
                  span: { fontSize: !user ? '0.75rem' : '' },
                },
              }}
              value={
                user
                  ? `/${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}/my-movies`
                  : `/${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}/login`
              }
              icon={<FontAwesomeIcon icon={faFilm} />}
            />
            <CustomBottomNavigationAction
              label={
                user
                  ? t('shared.bottomNavigation.element3.userLogged')
                  : t('shared.bottomNavigation.element3.userLogOut')
              }
              value={
                user
                  ? `/${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}/account?open=settings`
                  : `/${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}/login`
              }
              icon={<FontAwesomeIcon icon={faUser} />}
            />
          </CustomBottomNavigation>
        </BottomNavWrapper>
      )}
      <Snackbar
        open={!!signOutError}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={() => setSignOutError(null)}>
        <Alert onClose={() => setSignOutError(null)} severity='error' sx={{ width: '100%' }}>
          {signOutError ? signOutError : ''}
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!signOutSuccess}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={() => setSignOutSuccess(null)}>
        <Alert onClose={() => setSignOutSuccess(null)} severity='success' sx={{ width: '100%' }}>
          {signOutSuccess ? signOutSuccess : ''}
        </Alert>
      </Snackbar>
    </Container>
  );
};

interface OwnProps {
  user: User | null;
}
