import { styled } from '@mui/material';

export const Container = styled('section')(({ theme }) => ({
  width: '100%',
  padding: '270px 20px 60px 20px',
  background: theme.palette.background.background3,
  position: 'relative',
  overflow: 'hidden',
  backgroundImage: 'url("/assets/images/backgrounds/background_image_2.svg")',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100%',
  backgroundPositionX: '250px',
  backgroundPositionY: '60%',

  [theme.breakpoints.up('sm')]: {
    padding: '270px 60px 60px 60px',
  },

  [theme.breakpoints.up('md')]: {
    padding: '270px 80px 100px 80px',
  },

  [theme.breakpoints.up('xl')]: {
    padding: '270px 120px 270px 120px',
  },
}));

export const ExtraBackgroundElement = styled('div')(({ theme }) => ({
  width: '140%',
  height: '270px',
  backgroundColor: theme.palette.background.background2,

  zIndex: 0,
  position: 'absolute',
  transform: 'rotate(-8deg)',
  top: '-175px',
  left: '-100px',
}));

export const TitleWrapper = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  margin: '0 0 60px 0',
}));

export const CarouselWrapper = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
}));
