import React from 'react';
import { Main } from './Home.styled';
import { WatchMovies } from 'components/Home/WatchMovies/WatchMovies';
import { Slider } from 'components/Home/Slider/Slider';
import { HelmetContent } from 'components/Common/HelmetContent/HelmetContent';
import { useTranslation } from 'react-i18next';

export const Home: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Main>
      <HelmetContent
        title={t('home.pageTitle')}
        description={t('home.pageDescription')}
        keywords={t('home.pageKeywords')}
        ogTitle={t('home.pageTitle')}
        ogDescription={t('home.pageDescription')}
      />
      <Slider />
      <WatchMovies />
    </Main>
  );
};
