import { styled } from '@mui/material';

export const Container = styled('div')(({ theme }) => ({
  background: theme.palette.background.default,
  width: '100%',
  padding: '30px 20px',

  [theme.breakpoints.up('sm')]: {
    padding: '30px 60px',
  },

  [theme.breakpoints.up('md')]: {
    padding: '50px 80px',
  },

  [theme.breakpoints.up('xl')]: {
    padding: '100px 120px',
  },
}));
