import { Movie, MovieSearchOption } from 'types/shared/movie-type';

export const convertMoviesToMovieSearchOptions = (movies: Movie[]): MovieSearchOption[] => {
  return movies.map((movie: Movie) => ({
    id: movie.id,
    title: movie.title,
    description: movie.description,
    description2: movie.description2,
    imageSmall: movie.imageSmall,
  }));
};
