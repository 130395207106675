import React, { useEffect, useState } from 'react';
import { Container } from './Actors.styled';
import { ActorsCarousel } from './ActorsCarousel/ActorsCarousel';
import { Actor } from 'types/shared/actor.type';
import { getActors } from 'firestore';
import { filterActors } from 'utils/Movie/filter-actors/filter-actors';
import { ErrorScreen } from 'components/Shared/ErrorScreen/ErrorScreen';
import { LoadingScreen } from 'components/Shared/LoadingScreen/LoadingScreen';
import { useTranslation } from 'react-i18next';
import { sortedActors } from 'utils/Movie/sorted-actors/sorted-actors';

export const Actors: React.FC<OwnProps> = ({ movieId }: { movieId: string }) => {
  const [actors, setActors] = useState<Actor[] | null>(null);
  const [actorsError, setActorsError] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      try {
        const actorsArray = await getActors();
        setActors(sortedActors(filterActors(actorsArray as Actor[], movieId), movieId));
      } catch (e) {
        setActorsError(true);
      }
    })();
  }, [movieId]);

  if (actorsError) return <ErrorScreen errorTitle={t('movie.actors.error')} />;
  if (!actors) return <LoadingScreen />;
  return (
    <Container>
      <ActorsCarousel movieId={movieId} actors={actors} category={t('movie.actors.title')} />
    </Container>
  );
};

interface OwnProps {
  movieId: string;
}
