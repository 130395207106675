import { getAnalytics } from 'firebase/analytics';
import { FirebaseApp, FirebaseError, initializeApp } from 'firebase/app';
import { ReCaptchaV3Provider, initializeAppCheck } from 'firebase/app-check';
import {
  ErrorFn,
  GoogleAuthProvider,
  NextOrObserver,
  User,
  UserCredential,
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  getAuth,
  isSignInWithEmailLink,
  onAuthStateChanged,
  sendPasswordResetEmail,
  sendSignInLinkToEmail,
  signInWithEmailAndPassword,
  signInWithEmailLink,
  signInWithPopup,
  signOut,
  updatePassword,
  updateProfile,
} from 'firebase/auth';
import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Firestore,
  QueryDocumentSnapshot,
  QuerySnapshot,
  SnapshotOptions,
  Unsubscribe,
  collection,
  collectionGroup,
  deleteDoc,
  deleteField,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import { StorageReference, getBlob, getBytes, getDownloadURL, getStorage, ref } from 'firebase/storage';
import { TFunction } from 'i18next';
import { Payment } from 'models/Account/Shopping/payment/payment';
import { PaymentItem } from 'models/Account/Shopping/payment/payment-item/payment-item';
import { BasketMovie } from 'models/Movie/basket-movie/basket-movie';
import { WhishlistMovie } from 'models/Movie/whishlist-movie/whishlist-movie';
import { Bookmark } from 'models/Player/bookmark';
import { convertActorImageLink } from 'utils/Common/convert-actor-image-link/convert-actor-image-link';
import { convertMainImageLink } from 'utils/Common/convert-main-image-link/convert-main-image-link';
import { firestoreErrorHandler } from 'utils/Firestore/firestore-error-handler/firestore-error-handler';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Initialize Firebase
const app: FirebaseApp = initializeApp(firebaseConfig);
getAnalytics(app);
if (process.env.REACT_APP_ENVIRONMENT !== 'PRODUCTION' && process.env.REACT_APP_ENVIRONMENT !== 'DEVELOPMENT') {
  // @ts-ignore
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
}
initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider(process.env.REACT_APP_RE_CAPTCHA_PUBLIC_KEY as string),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true,
});
export const db: Firestore = getFirestore(app);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);

// Get the default bucket from a custom firebase.app.App
const storage = getStorage(app);

export const getVideo = async (pathToVideo: string): Promise<string | undefined> => {
  const videoRef: StorageReference = ref(storage, pathToVideo);

  try {
    const url2 = await getBytes(videoRef);
    var binary: string = '';
    var bytes = new Uint8Array(url2);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }

    let src2: string = `data:video/mp4;base64,${window.btoa(binary)}`;
    return src2;
  } catch (e) {
    //@ts-ignore
    throw new Error(e);
  }
};

export const getVideo2 = async (pathToVideo: string): Promise<string | undefined> => {
  const videoRef: StorageReference = ref(storage, pathToVideo);
  try {
    const url2 = await getDownloadURL(videoRef);

    // This can be downloaded directly:
    // const xhr = new XMLHttpRequest();
    // xhr.responseType = 'blob';
    // xhr.onload = (event) => {
    //   const blob = xhr.response;
    // };
    // xhr.open('GET', url2);
    // xhr.send();
    // const blob: Blob = await getBlob(videoRef, 10000000);
    // const url4 = URL.createObjectURL(blob);
    // console.log(blob);
    // console.log(url4);
    // console.log(url2);
    return url2;
    // let src2: string = `data:video/mp4;base64,${window.btoa(binary)}`;
    // return src2;
  } catch (e) {
    //@ts-ignore
    throw new Error(e);
  }
};

export const getImageAsUrl = async (pathToImage: string): Promise<string> => {
  const imageRef: StorageReference = ref(storage, pathToImage);
  try {
    return getDownloadURL(imageRef);
  } catch (e) {
    //@ts-ignore
    throw new Error(e);
  }
};

export const getImage = async (pathToImage: string): Promise<string> => {
  const imageRef: StorageReference = ref(storage, pathToImage);
  try {
    // const url2 = await getDownloadURL(videoRef);
    // This can be downloaded directly:
    // const xhr = new XMLHttpRequest();
    // xhr.responseType = 'blob';
    // xhr.onload = (event) => {
    //   const blob = xhr.response;
    // };
    // xhr.open('GET', url2);
    // xhr.send();
    // const url0: any = await getStream(imageRef); // only in node js - run-time environment for js
    // return url0;
    // const url1 = await getBytes(imageRef);
    // var binary: string = '';
    // var bytes = new Uint8Array(url1);
    // var len = bytes.byteLength;
    // for (var i = 0; i < len; i++) {
    //   binary += String.fromCharCode(bytes[i]);
    // }
    // let src1: string = `data:video/mp4;base64,${window.btoa(binary)}`;
    // return src1;
    // const url2 = await getDownloadURL(imageRef);
    // return url2;
    const blob: Blob = await getBlob(imageRef, 200000);
    const url4 = URL.createObjectURL(blob);
    return url4;
    // console.log(blob);
    // console.log(url4);
    // console.log(url2);
    // return url2;
    // let src2: string = `data:video/mp4;base64,${window.btoa(binary)}`;
    // return src2;
  } catch (e) {
    //@ts-ignore
    throw new Error(e);
  }
};

export const firebaseCreateUserWithEmailAndPassword = async (
  email: string,
  password: string,
): Promise<User | Error> => {
  try {
    const userCredentials: UserCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user: User = userCredentials.user;
    return user;
  } catch (e) {
    console.log(e);
    // @ts-ignore
    throw new Error(e);
  }
};

export const getUserIPAddress = async (): Promise<string> => {
  try {
    const response = await fetch('https://api.ipify.org?format=json');
    const data = await response.json();
    return data.ip;
  } catch (e) {
    throw new Error(firestoreErrorHandler(e as FirebaseError));
  }
};

export const updateUserActiveIpAddress = async (uid: string): Promise<void> => {
  try {
    const userRef = doc(db, 'users', uid);
    const payload = await getUserIPAddress();
    await updateDoc(userRef, {
      activeIpAddress: payload,
    });
  } catch (e) {
    throw new Error(firestoreErrorHandler(e as FirebaseError));
  }
};

export const firebaseSignInWithEmailAndPassword = async (email: string, password: string): Promise<User> => {
  try {
    const userCredential: UserCredential = await signInWithEmailAndPassword(auth, email, password);
    const user: User = userCredential.user;

    return user;
  } catch (e) {
    throw new Error(firestoreErrorHandler(e as FirebaseError));
  }
};

export const firebaseCheckIfUserExistsByEmail = async (email: string): Promise<boolean> => {
  try {
    let signInMethods = await fetchSignInMethodsForEmail(auth, email);
    if (signInMethods.length > 0) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    throw new Error(firestoreErrorHandler(error as FirebaseError));
  }
};

export const firebaseSendSignInLinkToEmail = async (email: string, language: string): Promise<void | Error> => {
  try {
    auth.languageCode = language;
    await sendSignInLinkToEmail(auth, email, {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be in the authorized domains list in the Firebase Console.
      url: `${window.location.origin}/${language}/set-password`,
      // This must be true.
      handleCodeInApp: true,
      // iOS: {
      //   bundleId: 'com.example.ios',
      // },
      // android: {
      //   packageName: 'com.example.android',
      //   installApp: true,
      //   minimumVersion: '10',
      // },
      // dynamicLinkDomain: 'https://watch-movies-app.web.app/',
    });
    window.localStorage.setItem('emailForSignIn', email);
  } catch (e) {
    console.log(e);
    // @ts-ignore
    throw new Error(e);
  }
};

export const firebaseSignInWithEmailLink = async (t: TFunction<'translation', undefined>): Promise<User> => {
  try {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      let email: string | null = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        while (email === null) {
          email = window.prompt(t('shared.firebaseMessages.emailPrompt'));
        }
      }
      const userCredential: UserCredential = await signInWithEmailLink(auth, email as string, window.location.href);
      const user: User = userCredential.user;
      return user;
    } else {
      throw new FirebaseError('auth/invalid-action-code', 'message - auth/invalid-action-code');
    }
  } catch (e) {
    throw new Error(firestoreErrorHandler(e as FirebaseError));
  }
};

export const firebaseUpdatePassword = async (user: User, newPassword: string): Promise<void | Error> => {
  try {
    await updatePassword(user, newPassword);
    window.localStorage.removeItem('emailForSignIn');
  } catch (e) {
    throw new Error(firestoreErrorHandler(e as FirebaseError));
  }
};

export const firebaseSendPasswordResetEmail = async (email: string, language: string): Promise<void | Error> => {
  try {
    auth.languageCode = language;
    await sendPasswordResetEmail(auth, email);
  } catch (e) {
    throw new Error(firestoreErrorHandler(e as FirebaseError));
  }
};

export const checkIsUserAuth = (cb: NextOrObserver<User>, errorCb: ErrorFn | undefined) => {
  onAuthStateChanged(auth, cb, errorCb);
};

export const firebaseSignOutUser = async () => {
  try {
    await signOut(auth);
  } catch (e) {
    // @ts-ignore
    throw new Error(e);
  }
};

export const firebaseSignInWithGoogle = async (cb: () => void) => {
  const provider = new GoogleAuthProvider();
  try {
    await signInWithPopup(auth, provider);

    await cb();
  } catch (e) {
    // @ts-ignore
    throw new Error(e);
  }
};

export const getUserPayments = async (userUID: string): Promise<Payment[] | undefined> => {
  const userPaymentsCollectionRef: CollectionReference<DocumentData> = collection(db, 'users', userUID, 'payments');

  try {
    const userPaymentsSnapshot: QuerySnapshot<DocumentData> = await getDocs(userPaymentsCollectionRef);
    const userPaymentsList: Payment[] = userPaymentsSnapshot.docs.map((doc: QueryDocumentSnapshot<DocumentData>) => {
      const paymentItems: PaymentItem[] = [];

      const payment: any = doc.data();

      if (payment.items) {
        payment.items.forEach((element: any) => {
          const { id, description, amount_discount, amount_subtotal, amount_total, currency, price } = element;
          paymentItems.push(
            new PaymentItem(amount_discount, amount_subtotal, amount_total, currency, id, description, price.product),
          );
        });
      }

      return new Payment(
        payment.amount,
        payment.id,
        Number.parseInt(`${payment.created}000`),
        paymentItems,
        payment.status,
        payment.currency,
      );
    });
    return userPaymentsList;
  } catch (e) {
    // @ts-ignore
    throw Error(e);
  }
};

export const getMovies = async (): Promise<DocumentData[] | undefined> => {
  const moviesCollectionRef: CollectionReference<DocumentData> = collection(db, 'movies');

  try {
    const moviesSnapshot: QuerySnapshot<DocumentData> = await getDocs(moviesCollectionRef);
    const moviesList: DocumentData[] = moviesSnapshot.docs.map((doc: QueryDocumentSnapshot<DocumentData>) => ({
      ...doc.data(),
    }));
    return moviesList;
  } catch (e) {
    // @ts-ignore
    throw Error(e);
  }
};

export const getActors = async (): Promise<DocumentData[] | undefined> => {
  const actorsCollectionRef: CollectionReference<DocumentData> = collection(db, 'actors');

  try {
    const actorsSnapshot: QuerySnapshot<DocumentData> = await getDocs(actorsCollectionRef);

    const actorsList: DocumentData[] = actorsSnapshot.docs.map(
      async (actorDoc: QueryDocumentSnapshot<DocumentData>) => {
        const actorPlayedRolesCollectionRef: CollectionReference<DocumentData> = collection(
          db,
          'actors',
          actorDoc.data().id,
          'playedRoles',
        );
        let actorPlayedRolesList;
        try {
          const actorPlayedRolesSnapshot: QuerySnapshot<DocumentData> = await getDocs(actorPlayedRolesCollectionRef);
          actorPlayedRolesList = actorPlayedRolesSnapshot.docs.map(
            (playedRoleDoc: QueryDocumentSnapshot<DocumentData>) => ({
              ...playedRoleDoc.data(),
            }),
          );
        } catch (e) {
          // @ts-ignore
          throw Error(e);
        }

        return {
          ...actorDoc.data(),
          playedRoles: actorPlayedRolesList,
        };
      },
    );
    // TODO: temporary solution fix because the is problem that I get everyting as an promise when
    // async (actorDoc: QueryDocumentSnapshot<DocumentData>) => {... is an async function not normal
    // but Promise.all resolve this problem
    return Promise.all(actorsList);
  } catch (e) {
    // @ts-ignore
    throw Error(e);
  }
};

export const updateActors = async (): Promise<void> => {
  const actorsCollectionRef: CollectionReference<DocumentData> = collection(db, 'actors');

  try {
    const actorsSnapshot: QuerySnapshot<DocumentData> = await getDocs(actorsCollectionRef);

    actorsSnapshot.docs.forEach((actorDoc: QueryDocumentSnapshot<DocumentData>) => {
      const docRef = doc(db, 'actors', actorDoc.data().id);

      updateDoc(docRef, { image: convertActorImageLink(actorDoc.data().image) });
    });
  } catch (e) {
    // @ts-ignore
    throw Error(e);
  }
};

export const updateMovies = async (): Promise<void> => {
  const moviesCollectionRef: CollectionReference<DocumentData> = collection(db, 'movies');

  try {
    const moviesSnapshot: QuerySnapshot<DocumentData> = await getDocs(moviesCollectionRef);

    moviesSnapshot.docs.forEach((movieDoc: QueryDocumentSnapshot<DocumentData>) => {
      const docRef = doc(db, 'movies', movieDoc.data().id);

      const newPosters: any[] = [];
      movieDoc.data().posters.forEach((poster: any) => {
        newPosters.push({
          ...poster,
          src: {
            en: convertMainImageLink(poster.src.en),
            pl: convertMainImageLink(poster.src.pl),
          },
        });
      });

      updateDoc(docRef, {
        imageSmall: {
          en: convertMainImageLink(movieDoc.data().imageSmall.en),
          pl: convertMainImageLink(movieDoc.data().imageSmall.pl),
        },
        imageLarge: {
          en: movieDoc.data().imageLarge.en.includes('https://')
            ? convertMainImageLink(movieDoc.data().imageLarge.en)
            : '',
          pl: movieDoc.data().imageLarge.pl.includes('https://')
            ? convertMainImageLink(movieDoc.data().imageLarge.pl)
            : '',
        },
        posters: newPosters,
      });
    });
  } catch (e) {
    // @ts-ignore
    throw Error(e);
  }
};

export const updateMovies3 = async (): Promise<void> => {
  const moviesCollectionRef: CollectionReference<DocumentData> = collection(db, 'movies');

  try {
    const moviesSnapshot: QuerySnapshot<DocumentData> = await getDocs(moviesCollectionRef);

    moviesSnapshot.docs.forEach((movieDoc: QueryDocumentSnapshot<DocumentData>) => {
      const docRef = doc(db, 'movies', movieDoc.data().id);

      updateDoc(docRef, {
        description2: movieDoc.data().description2 ? movieDoc.data().description2 : '',
      });
    });
  } catch (e) {
    // @ts-ignore
    throw Error(e);
  }
};

export const updateMovies2 = async (): Promise<void> => {
  const moviesCollectionRef: CollectionReference<DocumentData> = collection(db, 'movies');

  try {
    const moviesSnapshot: QuerySnapshot<DocumentData> = await getDocs(moviesCollectionRef);

    moviesSnapshot.docs.forEach((movieDoc: QueryDocumentSnapshot<DocumentData>) => {
      const docRef = doc(db, 'movies', movieDoc.data().id);

      updateDoc(docRef, {
        imageMedium: {
          en:
            movieDoc.data().imageMedium && movieDoc.data().imageMedium.en.includes('images')
              ? movieDoc.data().imageMedium.en
              : '',
          pl:
            movieDoc.data().imageMedium && movieDoc.data().imageMedium.pl.includes('images')
              ? movieDoc.data().imageMedium.pl
              : '',
        },
      });
    });
  } catch (e) {
    // @ts-ignore
    throw Error(e);
  }
};

export const updateMovies4 = async (): Promise<void> => {
  const moviesCollectionRef: CollectionReference<DocumentData> = collection(db, 'movies');

  try {
    const moviesSnapshot: QuerySnapshot<DocumentData> = await getDocs(moviesCollectionRef);

    moviesSnapshot.docs.forEach((movieDoc: QueryDocumentSnapshot<DocumentData>) => {
      const docRef = doc(db, 'movies', movieDoc.data().id);

      updateDoc(docRef, {
        status: 'active',
      });
    });
  } catch (e) {
    // @ts-ignore
    throw Error(e);
  }
};

export const updateMovies5 = async (): Promise<void> => {
  const moviesCollectionRef: CollectionReference<DocumentData> = collection(db, 'movies');

  try {
    const moviesSnapshot: QuerySnapshot<DocumentData> = await getDocs(moviesCollectionRef);

    moviesSnapshot.docs.forEach((movieDoc: QueryDocumentSnapshot<DocumentData>, index: number) => {
      const docRef = doc(db, 'movies', movieDoc.data().id);

      updateDoc(docRef, {
        categories: [...movieDoc.data().categories, { value: 'all', order: index + 1 }],
      });
    });
  } catch (e) {
    // @ts-ignore
    throw Error(e);
  }
};

export const updateMovies6 = async (): Promise<void> => {
  const moviesCollectionRef: CollectionReference<DocumentData> = collection(db, 'movies');

  try {
    const moviesSnapshot: QuerySnapshot<DocumentData> = await getDocs(moviesCollectionRef);

    moviesSnapshot.docs.forEach((movieDoc: QueryDocumentSnapshot<DocumentData>) => {
      const docRef = doc(db, 'movies', movieDoc.data().id);
      const tags = movieDoc.data().categories.map((category: any) => category.value);

      updateDoc(docRef, {
        category: deleteField(),
        categoryTags: tags,
      });
    });
  } catch (e) {
    // @ts-ignore
    throw Error(e);
  }
};

export const updateMovies7 = async (): Promise<void> => {
  const moviesCollectionRef: CollectionReference<DocumentData> = collection(db, 'movies');

  try {
    const moviesSnapshot: QuerySnapshot<DocumentData> = await getDocs(moviesCollectionRef);

    moviesSnapshot.docs.forEach((movieDoc: QueryDocumentSnapshot<DocumentData>) => {
      const docRef = doc(db, 'movies', movieDoc.data().id);

      const newMovieExtras: any[] = [];
      movieDoc.data().movieExtras.forEach((movieExtra: any) => {
        newMovieExtras.push({
          ...movieExtra,
          src: {
            en: +movieExtra.src.en,
            pl: +movieExtra.src.pl,
          },
        });
      });

      const newMovieSources: any[] = [];
      movieDoc.data().movieSrc.forEach((movieSource: any) => {
        if (movieSource.language === 'pl') {
          return newMovieSources.push({
            ...movieSource,
            src: 988345495,
          });
        }
        newMovieSources.push({
          ...movieSource,
          src: 988345471,
        });
      });

      updateDoc(docRef, {
        movieSrc: newMovieSources,
        movieExtras: newMovieExtras,
      });
    });
  } catch (e) {
    // @ts-ignore
    throw Error(e);
  }
};

export const getProduct = async (productId: string): Promise<DocumentData | undefined> => {
  const productDocRef: DocumentReference<DocumentData> = doc(db, 'products', productId);
  const productPricesCollectionRef: CollectionReference<DocumentData> = collection(db, 'products', productId, 'prices');

  try {
    const docSnap = await getDoc(productDocRef);
    if (docSnap.exists()) {
      const productPricesSnapshot: QuerySnapshot<DocumentData> = await getDocs(productPricesCollectionRef);
      const productPricesList: DocumentData[] = productPricesSnapshot.docs.map(
        (doc: QueryDocumentSnapshot<DocumentData>) => ({
          id: doc.id,
          ...doc.data(),
        }),
      );

      return {
        ...docSnap.data(),
        prices: productPricesList,
      };
    } else {
      // docSnap.data() will be undefined in this case
      console.log('No such document!');
    }
  } catch (e) {
    // @ts-ignore
    throw Error(e);
  }
};

// documentation - https://firebase.google.com/docs/reference/js/v8/firebase.firestore.FirestoreDataConverter
const basketItemConverter = {
  toFirestore(basketMovie: BasketMovie): DocumentData {
    return {
      id: basketMovie.id,
      title: basketMovie.title,
      price: basketMovie.price,
      priceId: basketMovie.priceId,
      imageSmall: basketMovie.imageSmall,
      currency: basketMovie.currency,
    };
  },
  fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): BasketMovie {
    const data = snapshot.data(options)!;
    return new BasketMovie(data.id, data.title, data.price, data.priceId, data.imageSmall, data.currency);
  },
};

export const listenBasketItemsChange = (
  uid: string,
  // eslint-disable-next-line
  onNext: (snapshot: QuerySnapshot<BasketMovie>) => void,
): Unsubscribe => {
  const userBasketItemsCollectionRef: CollectionReference<BasketMovie> = collection(
    db,
    'users',
    uid,
    'basket_items',
  ).withConverter(basketItemConverter);

  return onSnapshot(userBasketItemsCollectionRef, onNext);
};

export const addItemToBasket = async (
  uid: string,
  itemId: string,
  title: string,
  price: number,
  priceId: string,
  imageSmall: Record<string, string>,
  currency: string,
): Promise<void> => {
  try {
    const basketItemRef = doc(db, 'users', uid, 'basket_items', itemId).withConverter(basketItemConverter);
    const payload = new BasketMovie(itemId, title, price, priceId, imageSmall, currency);
    await setDoc(basketItemRef, payload);
  } catch (e) {
    // @ts-ignore
    throw new Error(e);
  }
};

export const removeItemFromBasket = async (uid: string, itemId: string): Promise<void> => {
  try {
    const basketItemRef = doc(db, 'users', uid, 'basket_items', itemId);
    await deleteDoc(basketItemRef);
  } catch (e) {
    // @ts-ignore
    throw new Error(e);
  }
};

// documentation - https://firebase.google.com/docs/reference/js/v8/firebase.firestore.FirestoreDataConverter
const whishlistItemConverter = {
  toFirestore(basketMovie: WhishlistMovie): DocumentData {
    return {
      id: basketMovie.id,
      title: basketMovie.title,
      imageSmall: basketMovie.imageSmall,
    };
  },
  fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): WhishlistMovie {
    const data = snapshot.data(options)!;
    return new WhishlistMovie(data.id, data.title, data.imageSmall);
  },
};

export const listenWhishlistItemsChange = (
  uid: string,
  // eslint-disable-next-line
  onNext: (snapshot: QuerySnapshot<WhishlistMovie>) => void,
): Unsubscribe => {
  const userWhishlistItemsCollectionRef: CollectionReference<WhishlistMovie> = collection(
    db,
    'users',
    uid,
    'whishlist_items',
  ).withConverter(whishlistItemConverter);

  return onSnapshot(userWhishlistItemsCollectionRef, onNext);
};

export const addItemToWhishlist = async (
  uid: string,
  itemId: string,
  title: string,
  imageSmall: Record<string, string>,
): Promise<void> => {
  try {
    const basketItemRef = doc(db, 'users', uid, 'whishlist_items', itemId).withConverter(whishlistItemConverter);
    const payload = new WhishlistMovie(itemId, title, imageSmall);
    await setDoc(basketItemRef, payload);
  } catch (e) {
    // @ts-ignore
    throw new Error(e);
  }
};

export const removeItemFromWhishlist = async (uid: string, itemId: string): Promise<void> => {
  try {
    const basketItemRef = doc(db, 'users', uid, 'whishlist_items', itemId);
    await deleteDoc(basketItemRef);
  } catch (e) {
    // @ts-ignore
    throw new Error(e);
  }
};

export const updateUserName = async (user: User, name: string): Promise<void> => {
  try {
    await updateProfile(user, { displayName: name });
  } catch (e) {
    // @ts-ignore
    throw new Error(e);
  }
};

// export const deleteUserAccount = async (user: User): Promise<void> => {
//   try {
//     await deleteUser(user);
//   } catch (e) {
//     // @ts-ignore
//     throw new Error(e);
//   }
// };

// export const updateUserEmail = async (user: User, email: string): Promise<void> => {
//   try {
//     await updateEmail(user, email);
//   } catch (e) {
//     // @ts-ignore
//     throw new Error(e);
//   }
// };

// documentation - https://firebase.google.com/docs/reference/js/v8/firebase.firestore.FirestoreDataConverter
const bookmarksConverter = {
  toFirestore(bookmark: Bookmark): DocumentData {
    return {
      id: bookmark.id,
      lastWatchTime: bookmark.lastWatchTime,
      audio: bookmark.audio,
      subtitle: bookmark.subtitle,
      videoDuration: bookmark.videoDuration,
    };
  },
  fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): Bookmark {
    const data = snapshot.data(options)!;
    return new Bookmark(data.id, data.lastWatchTime, data.audio, data.subtitle, data.videoDuration);
  },
};

export const addOrOverrideBookmark = async (
  uid: string,
  bookmarkId: string,
  lastWatchTime: number,
  audio: string,
  subtitle: string,
  videoDuration: number,
): Promise<void> => {
  try {
    const bookmarkRef = doc(db, 'users', uid, 'bookmarks', bookmarkId).withConverter(bookmarksConverter);
    const payload = new Bookmark(bookmarkId, lastWatchTime, audio, subtitle, videoDuration);
    await setDoc(bookmarkRef, payload);
  } catch (e) {
    // @ts-ignore
    throw new Error(e);
  }
};

export const getBookmark = async (uid: string, bookmarkId: string): Promise<Bookmark | undefined> => {
  const bookmarkDocRef: DocumentReference<Bookmark> = doc(db, 'users', uid, 'bookmarks', bookmarkId).withConverter(
    bookmarksConverter,
  );
  try {
    const docSnap = await getDoc(bookmarkDocRef);
    if (docSnap.exists()) {
      return {
        ...docSnap.data(),
      };
    } else {
      console.log('undefined');
      return undefined;
    }
  } catch (e) {
    console.log(e);
    // @ts-ignore
    throw Error(e);
  }
};

export const deleteBookmark = async (uid: string, bookmarkId: string): Promise<void> => {
  try {
    const bookmarkDocRef: DocumentReference<DocumentData> = doc(db, 'users', uid, 'bookmarks', bookmarkId);
    await deleteDoc(bookmarkDocRef);
  } catch (e) {
    // @ts-ignore
    throw new Error(e);
  }
};

export const listenUserChange = (
  uid: string,
  // eslint-disable-next-line
  onNext: (snapshot: DocumentSnapshot) => void,
): Unsubscribe => {
  const userDoc = doc(db, 'users', uid);

  return onSnapshot(userDoc, onNext);
};

export const queryMovies = async () => {
  const moviesCollectionRef: CollectionReference<DocumentData> = collection(db, 'movies');
  const q = query(moviesCollectionRef, where('categoryTags', 'array-contains', 'christian'));

  try {
    const moviesSnapshot: QuerySnapshot<DocumentData> = await getDocs(q);
    const moviesList: DocumentData[] = moviesSnapshot.docs.map((doc: QueryDocumentSnapshot<DocumentData>) => ({
      ...doc.data(),
    }));
    return moviesList;
  } catch (e) {
    // @ts-ignore
    throw Error(e);
  }
};

export const testCities = async () => {
  try {
    const moviesCollectionRef = collectionGroup(db, 'playedRoles');
    const q = query(moviesCollectionRef, where('movieId', '==', 'O05meTX4gxdjnYCz07K4'));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc: any) => ({ ...doc.data() }));
  } catch (e) {
    console.error(e);
    // @ts-ignore
    throw Error(e);
  }
};
