import { faCartPlus, faSignature, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, useMediaQuery, useTheme } from '@mui/material';
import { ImageLoader } from 'components/Common/ImageLoader/ImageLoader';
import { SegmentDescriptor } from 'components/Common/SegmentDecriptor/SegmentDecriptor';
import { User } from 'firebase/auth';
import { removeItemFromBasket } from 'firestore';
import { BasketMovie as BasketMovieType } from 'models/Movie/basket-movie/basket-movie';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Container } from './BasketMovie.styled';
import { convertMovieTileToDescriptivePath } from 'utils/Common/convert-movie-title-to-descriptive-path/convert-movie-title-to-descriptive-path';

export const BasketMovie: React.FC<OwnProps> = (props: OwnProps) => {
  const { basketMovie, user } = props;
  const { id, title, price, imageSmall, currency } = basketMovie;

  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'));

  const handleClickRemoveFromBasket = async () => {
    try {
      await removeItemFromBasket(user.uid, id);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Container>
      <Box sx={{ display: 'flex' }}>
        <Box
          sx={{ width: '100%', maxWidth: '120px' }}
          to={`/${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}/movies/${convertMovieTileToDescriptivePath(t(title))}/${id}`}
          component={Link}>
          <ImageLoader
            imageFirebasePath={imageSmall[i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language]}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            padding: '0 0 0 20px',
            md: { justifyContent: 'flex-start' },
          }}>
          <Box>
            <SegmentDescriptor
              segmentDescriptorSize='small'
              icon={faSignature}
              title={t('shared.segmentDescriptor.title')}
              description={t(title)}
            />
            <SegmentDescriptor
              segmentDescriptorSize='small'
              icon={faCartPlus}
              title={t('shared.segmentDescriptor.purchasePrice')}
              description={`${(price * 0.01).toFixed(2)} ${currency.toUpperCase()}`}
            />
          </Box>
          {!lgUp && (
            <Button sx={{ textTransform: 'none' }} variant='outlined' startIcon={<FontAwesomeIcon icon={faTrash} />}>
              {t('shared.buttons.removeFromBasket')}
            </Button>
          )}
        </Box>
      </Box>
      {lgUp && (
        <Button
          onClick={async () => await handleClickRemoveFromBasket()}
          sx={{ textTransform: 'none', alignSelf: 'flex-start' }}
          variant='outlined'
          startIcon={<FontAwesomeIcon icon={faTrash} />}>
          {t('shared.buttons.removeFromBasket')}
        </Button>
      )}
    </Container>
  );
};

interface OwnProps {
  basketMovie: BasketMovieType;
  user: User;
}
