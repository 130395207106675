import React from 'react';
import { Container } from './BasketMoviesList.styled';
import { BasketMovie as BasketMovieType } from 'models/Movie/basket-movie/basket-movie';
import { BasketMovie } from './BasketMovie/BasketMovie';
import { User } from 'firebase/auth';

export const BasketMoviesList: React.FC<OwnProps> = (props: OwnProps) => {
  const { basketMovies, user } = props;

  return (
    <Container>
      {basketMovies.map((element: BasketMovieType) => (
        <BasketMovie user={user} key={element.id} basketMovie={element} />
      ))}
    </Container>
  );
};

interface OwnProps {
  basketMovies: BasketMovieType[];
  user: User;
}
