import { faSignature } from '@fortawesome/free-solid-svg-icons';
import { Link } from '@mui/material';
import { ImageLoader } from 'components/Common/ImageLoader/ImageLoader';
import { SegmentDescriptor } from 'components/Common/SegmentDecriptor/SegmentDecriptor';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Movie } from 'types/shared/movie-type';
import { convertMovieTileToDescriptivePath } from 'utils/Common/convert-movie-title-to-descriptive-path/convert-movie-title-to-descriptive-path';
import { Container, DescriptionBox, ImageWrapper, MovieNav } from './MovieListItem.styled';

export const MovieListItem: React.FC<OwnProps> = (props) => {
  const { movie } = props;
  const { title, imageSmall, id } = movie;
  const { i18n } = useTranslation();

  const { t } = useTranslation();

  return (
    <Container>
      <ImageWrapper>
        <MovieNav
          to={`/${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}/movies/${convertMovieTileToDescriptivePath(t(title))}/${id}`}></MovieNav>
        <ImageLoader
          imageFirebasePath={imageSmall[i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language]}
        />
      </ImageWrapper>
      <DescriptionBox>
        <SegmentDescriptor
          icon={faSignature}
          segmentDescriptorSize='small'
          title={t('shared.segmentDescriptor.title')}
          description={
            <Link
              component={NavLink}
              to={`/${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}/movies/${convertMovieTileToDescriptivePath(t(title))}/${id}`}
              color='inherit'>
              {t(title)}
            </Link>
          }
        />
      </DescriptionBox>
    </Container>
  );
};

interface OwnProps {
  movie: Movie;
}
