import { Box, Chip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ImageLoader, SkeletonResolutionEnum } from 'components/Common/ImageLoader/ImageLoader';
import { MoviesContext } from 'context/MoviesContext';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MoviesCarouselSlider, { ResponsiveType } from 'react-multi-carousel';
import { Movie } from 'types/shared/movie-type';
import { filterMoviesByCategory } from 'utils/Home/filter-movies-by-category/filter-movies-by-category';
import { sortMoviesByCategoryAndOrder } from 'utils/Home/sort_movies_by_category_and_order/sort_movies_by_category_and_order';
import { Container, MovieStyled, MovieWrapper } from './Slider.styled';
import { convertMovieTileToDescriptivePath } from 'utils/Common/convert-movie-title-to-descriptive-path/convert-movie-title-to-descriptive-path';

export const Slider: React.FC = () => {
  const theme = useTheme();
  const { movies } = useContext(MoviesContext);
  const [moviesToDisplayInSlider] = useState<Movie[]>(
    sortMoviesByCategoryAndOrder('main_slider', filterMoviesByCategory(movies, 'main_slider')),
  );
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'));
  const { i18n, t } = useTranslation();

  const responsive: ResponsiveType = {
    xl: {
      breakpoint: { max: 4000, min: 1536 },
      items: 1,
    },
    lg: {
      breakpoint: { max: 1535, min: 1200 },
      items: 1,
    },
    md: {
      breakpoint: { max: 1199, min: 900 },
      items: 1,
    },
    sm: {
      breakpoint: { max: 899, min: 600 },
      items: 1,
    },
    xs: {
      breakpoint: { max: 599, min: 0 },
      items: 1,
    },
  };

  return (
    <Container>
      <MoviesCarouselSlider
        itemClass='carousel-item'
        containerClass='carousel-container'
        showDots
        infinite
        swipeable={!lgUp}
        draggable={!lgUp}
        removeArrowOnDeviceType={['xs', 'sm', 'md']}
        responsive={responsive}>
        {moviesToDisplayInSlider.map((element: Movie) => (
          <MovieStyled
            aria-label={t(element.title)}
            key={element.id}
            to={`/${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}/movies/${convertMovieTileToDescriptivePath(t(element.title))}/${element.id}`}>
            {lgUp && (
              <Box sx={{ position: 'absolute', left: '70px', bottom: '40px', zIndex: 2 }}>
                <Chip label={t('shared.chip.recommended')} color='primary' size='small' sx={{ margin: '0 0 7px 0' }} />
                <Typography fontSize='28px' color='text.primary'>
                  {t(element.title)}
                </Typography>
                <Typography fontSize='18px' color='text.secondary' sx={{ maxWidth: '600px' }}>
                  {t(element.description2)}
                </Typography>
              </Box>
            )}
            <MovieWrapper>
              <ImageLoader
                skeletonImageResolution={
                  lgUp ? SkeletonResolutionEnum.CONTENT_24_5 : SkeletonResolutionEnum.CONTENT_16_9
                }
                imageFirebasePath={
                  element[lgUp ? 'imageLarge' : 'imageMedium'][
                    i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language
                  ]
                }
              />
            </MovieWrapper>
          </MovieStyled>
        ))}
      </MoviesCarouselSlider>
    </Container>
  );
};
