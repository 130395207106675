import React from 'react';
import { Movie } from 'types/shared/movie-type';
import { Container } from './MoviesList.styled';
import { MovieListItem } from './MoviesListItem/MovieListItem';

export const MoviesList: React.FC<OwnProps> = (props) => {
  const { movies } = props;

  return (
    <Container>
      {movies.map((element: Movie) => (
        <MovieListItem key={element.id} movie={element} />
      ))}
    </Container>
  );
};

interface OwnProps {
  movies: Movie[];
}
