import { styled } from '@mui/material';

export const Container = styled('footer')(({ theme }) => ({
  background: theme.palette.background.background3,
  width: '100%',
  padding: '60px 20px 100px 20px',
  [theme.breakpoints.up('lg')]: {
    padding: '60px 70px',
  },

  borderTop: `1px solid ${theme.palette.border.color1}`,
}));

export const FooterListsWrapper = styled('div')(({ theme }) => ({
  margin: '0 0 80px 0',

  [theme.breakpoints.up('sm')]: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

export const FooterListContainer = styled('div')(({ theme }) => ({
  margin: '0 0 50px 0',
  maxWidth: '350px',
  width: '100%',

  '&:last-of-type': {
    margin: '0',
  },

  [theme.breakpoints.up('sm')]: {
    margin: '0 20px 0 0',
  },
}));

export const FooterList = styled('ul')(({ theme }) => ({
  color: theme.palette.text.secondary,
  listStyleType: 'disc',
  listStyle: 'inside',
}));

export const FooterListItem = styled('li')(() => ({
  margin: '0 0 5px 0',

  '&:last-of-type': {
    margin: '0',
  },
}));

export const Copyright = styled('small')(({ theme }) => ({
  textAlign: 'center',
  display: 'block',
  width: '100%',
  color: theme.palette.text.primary,
}));
