import { styled } from '@mui/material';

export const Container = styled('article')(({ theme }) => ({
  width: '100%',

  '& .carousel-container': {
    padding: '30px 0',
    overflow: 'none',
  },

  '& .react-multiple-carousel__arrow--left': {
    left: '4px',
  },

  '& .react-multiple-carousel__arrow--right': {
    right: '4px',
  },

  '& .carousel-item': {
    padding: '0 30px 0 0',
    transition: 'all .2s ease-in-out',

    '&:last-of-type': {
      padding: '0',
    },
  },

  '& .carousel-item:hover': {
    transform: 'scale(1.2)',
    zIndex: theme.zIndex.modal,
  },
}));

export const ActorStyled = styled('div')(({ theme }) => ({
  display: 'block',
  width: '100%',
  maxWidth: '300px',
  minWidth: '190px',
  borderRadius: '5px',
  boxShadow: theme.shadows[10],
  overflow: 'hidden',
  position: 'relative',

  '&:last-of-type': {
    padding: '0',
  },

  [theme.breakpoints.up('sm')]: {
    maxWidth: '350px',
  },
}));

export const ActorDescription = styled('div')(({ theme }) => ({
  color: theme.palette.common.white,
  position: 'absolute',
  width: '100%',
  background: theme.palette.background.default,
  bottom: 0,
  left: 0,
  borderTop: '1px solid #C2E0FF',
  padding: '17px 26px',
  opacity: '0.95',
}));

export const Wrapper1 = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const Wrapper2 = styled('div')(() => ({}));

export const ActorImage = styled('img')({
  width: '100%',
});

export const TextModifier = styled('span')({
  fontWeight: 700,
});
