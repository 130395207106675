import { Chip, Typography, useTheme } from '@mui/material';
import React from 'react';
import { Container } from './OrderPrice.styled';

export const OrderPrice: React.FC<OwnProps> = (props) => {
  const { amount_discount, amount_subtotal, amount_total, currency } = props;

  const theme = useTheme();

  const countPercentage = (amount_discount: number, amount_subtotal: number) => {
    return (amount_discount / amount_subtotal) * 100;
  };

  return (
    <Container>
      {amount_discount > 0 && (
        <Typography
          component='span'
          sx={{
            fontSize: '14px',
            textDecoration: `${theme.palette.primary.main} line-through`,

            display: 'inline-block',
            position: 'relative',
            margin: '12px 30px 0 0',
          }}>
          {(amount_subtotal * 0.01).toFixed(2)} {currency.toUpperCase()}
          <Chip
            color='primary'
            size='small'
            label={`-${countPercentage(amount_discount, amount_subtotal)}%`}
            sx={{
              position: 'absolute',
              right: '0',
              top: '0',
              transform: 'translate(50%, -85%)',
              padding: '2px 1px',
              borderRadius: '2px',
              fontWeight: '700',
              height: 'auto',
              '& .MuiChip-label': {
                lineHeight: '13px',
                whiteSpace: 'normal',
              },
            }}
          />
        </Typography>
      )}
      <Typography component='span' sx={{ display: 'inline-block', fontWeight: 500 }}>
        {(amount_total * 0.01).toFixed(2)} {currency.toUpperCase()}
      </Typography>
    </Container>
  );
};

interface OwnProps {
  amount_discount: number;
  amount_subtotal: number;
  amount_total: number;
  currency: string;
}
