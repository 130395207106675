import { Movie, MoviePreview } from 'types/shared/movie-type';

export const convertMovieToMoviePreview = (movies: Movie[]): MoviePreview[] => {
  return movies.map((movie: Movie) => ({
    id: movie.id,
    title: movie.title,
    imageSmall: movie.imageSmall,
    categories: movie.categories,
    categoryTags: movie.categoryTags,
  }));
};
