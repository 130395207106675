import React, { useContext, useEffect, useState } from 'react';
import { Container } from './Movie.styled';
import { MovieDetails } from 'components/Movie/MovieDetails/MovieDetails';
import { Actors } from 'components/Movie/Actors/Actors';
import { MovieExtras } from 'components/Movie/MovieExtras/MovieExtras';
import { Posters } from 'components/Movie/Posters/Posters';
import { useParams } from 'react-router-dom';
import { MoviesContext } from 'context/MoviesContext';
import { getMovie } from 'utils/Context/MoviesContext/get-movie/get-movie';
import { Movie as MovieType } from 'types/shared/movie-type';
import { ErrorScreen } from 'components/Shared/ErrorScreen/ErrorScreen';
import { User } from 'firebase/auth';

type MovieParams = {
  movieId?: string;
};

export const Movie: React.FC<OwnProps> = ({ user }) => {
  const { movieId } = useParams<MovieParams>();
  const { movies } = useContext(MoviesContext);
  const [movie, setMovie] = useState<MovieType | undefined>(movieId ? getMovie(movies, movieId) : undefined);

  useEffect(() => {
    setMovie(movieId ? getMovie(movies, movieId) : undefined);
  }, [movieId]);

  if (!movie)
    return (
      <ErrorScreen
        errorTitle='Podany film nie istnieje'
        errorDescription='Najprawdopodobnie film, który szukasz nie istnieje. Sprawdź inne pozycje.'
      />
    );
  return (
    <Container>
      <MovieDetails user={user} movie={movie} />
      <Actors movieId={movie.id} />
      <MovieExtras movieExtras={movie.movieExtras} />
      <Posters posters={movie.posters} />
    </Container>
  );
};

interface OwnProps {
  user: User | null;
}
