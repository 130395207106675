import { styled } from '@mui/material';

export const Container = styled('aside')(({ theme }) => ({
  gridArea: 'basketPrice',

  [theme.breakpoints.up('md')]: {
    position: 'sticky',
    top: '100px',
  },
}));
