import { Actor, Role } from 'types/shared/actor.type';

export const filterActors = (actors: Actor[], movieId: string) => {
  let filteredActors: Actor[] = [];

  actors.forEach((actor: Actor) => {
    const ifActorPlayRoleAtCurrentMovie = actor.playedRoles.some((role: Role) => role.movieId === movieId);

    if (ifActorPlayRoleAtCurrentMovie) filteredActors.push(actor);
  });

  return filteredActors;
};
