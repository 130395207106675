import { Movies } from 'components/Movies/Movies';
import React from 'react';

export const CasualMovies: React.FC = () => {
  return (
    <Movies
      initialTag='casual'
      title='casualMovies.title'
      titlePrefix='casualMovies.titlePrefix'
      pageTitle='casualMovies.pageTitle'
      pageDescription='casualMovies.pageDescription'
      keywords='casualMovies.pageKeywords'
    />
  );
};
