import { styled } from '@mui/material';

interface ContainerProps {
  segmentDescriptorSize: 'large' | 'small';
}

export const Container = styled('div', {
  shouldForwardProp: (prop) => prop !== 'segmentDescriptorSize',
})<ContainerProps>(({ segmentDescriptorSize }) => ({
  margin: segmentDescriptorSize === 'large' ? '0 0 20px 0' : '0 0 20px 0',

  '&:last-of-type': {
    margin: '0',
  },
}));

interface WrapperProps {
  segmentDescriptorSize: 'large' | 'small';
}

export const Wrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'segmentDescriptorSize',
})<WrapperProps>(({ segmentDescriptorSize }) => ({
  display: 'flex',
  alignItems: 'center',
  margin: segmentDescriptorSize === 'large' ? '0 0 10px 0' : '0 0 4px 0',
}));

interface TitleProps {
  segmentDescriptorSize: 'large' | 'small';
}

export const Title = styled('h2', {
  shouldForwardProp: (prop) => prop !== 'segmentDescriptorSize',
})<TitleProps>(({ theme, segmentDescriptorSize }) => ({
  fontSize: segmentDescriptorSize === 'large' ? '20px' : '16px',
  color: theme.palette.text.primary,

  [theme.breakpoints.up('xl')]: {
    fontSize: segmentDescriptorSize === 'large' ? '22px' : '17px',
  },
}));

interface DescriptionProps {
  segmentDescriptorSize: 'small' | 'large';
}

export const Description = styled('div', {
  shouldForwardProp: (prop) => prop !== 'segmentDescriptorSize',
})<DescriptionProps>(({ theme, segmentDescriptorSize }) => ({
  fontSize: segmentDescriptorSize === 'large' ? '16px' : '12px',
  color: theme.palette.text.secondary,
  lineHeight: segmentDescriptorSize === 'large' ? '21px' : '17px',

  [theme.breakpoints.up('xl')]: {
    fontSize: segmentDescriptorSize === 'large' ? '18px' : '13px',
    lineHeight: segmentDescriptorSize === 'large' ? '23px' : '18px',
  },
}));
