import { styled } from '@mui/material';
import { NavLink } from 'react-router-dom';

export const Container = styled('li')(({ theme }) => ({
  display: 'block',
  width: '100%',
  maxWidth: '300px',
  minWidth: '190px',
  borderRadius: '5px',
  boxShadow: theme.shadows[10],
  overflow: 'hidden',
  position: 'relative',

  [theme.breakpoints.up('sm')]: {
    maxWidth: '350px',
  },
}));

export const ImageWrapper = styled('div')(() => ({
  width: '100%',
  display: 'block',
  position: 'relative',
  cursor: 'pointer',

  '&:hover > a': {
    transition: 'opacity 0.3s',
    opacity: 1,
  },
}));

export const MovieNav = styled(NavLink)(() => ({
  position: 'absolute',
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  background: 'rgba(0, 0, 0, 0.2)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 3,
  transition: 'opacity 0.3s',
  opacity: 0,
}));

export const Image = styled('img')(() => ({
  width: '100%',
  position: 'relative',
  zIndex: 2,
}));

export const DescriptionBox = styled('div')(({ theme }) => ({
  color: theme.palette.common.white,
  position: 'absolute',
  width: '100%',
  background: theme.palette.background.background4,
  bottom: 0,
  left: 0,
  borderTop: `1px solid ${theme.palette.border.color1}`,
  padding: '12px 17px',
  zIndex: 4,
}));
