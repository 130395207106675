import React, { ChangeEvent, FormEvent, useState } from 'react';
import { Container, ContentBox, DialogForm } from './Settings.styled';
import { Alert, Box, Button, Dialog, DialogTitle, TextField, Typography, useTheme } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock, faUser } from '@fortawesome/free-solid-svg-icons';
import { User } from 'firebase/auth';
import { updateUserName } from 'firestore';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { HelmetContent } from 'components/Common/HelmetContent/HelmetContent';

export const Settings: React.FC<OwnProps> = ({ user }: { user: User }) => {
  const [isPersonalDataDialogOpen, setIsPersonalDataDialogOpen] = useState<boolean>(false);
  const [isPersonalDataFormLoading, setIsPersonalDataFormLoading] = useState<boolean>(false);
  const [personalDataFormError, setPersonalDataFormError] = useState<string | null>(null);
  const [userName, setUserName] = useState<string>(user.displayName ? user.displayName : '');
  const [userNameError, setUserNameError] = useState<string | null>(null);
  // const [userEmail, setUserEmail] = useState<string>(user.email ? user.email : '');
  // const [userEmailError, setUserEmailError] = useState<string | null>(null);

  // const [isEmailDialogOpen, setIsEmailDialogOpen] = useState<boolean>(false);
  // const [isEmailFormLoading, setIsEmailFormLoading] = useState<boolean>(false);
  // const [emailFormError, setEmailFormError] = useState<string | null>(null);

  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  const handleClickOpenPersonalDataDialog = () => {
    setIsPersonalDataDialogOpen(true);
  };

  const handleClosePersonalDataDialog = () => {
    setIsPersonalDataDialogOpen(false);
  };

  // const handleClickOpenEmailDialog = () => {
  //   setIsEmailDialogOpen(true);
  // };

  const handleClickResetPassword = () => {
    navigate(`/${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}/reset-password`);
  };

  // const handleClickDeleteAccount = async () => {
  //   await deleteUserAccount(user);
  //   // window.location.reload();
  // };

  // const handleCloseEmailDialog = () => {
  //   setIsEmailDialogOpen(false);
  // };

  // const handleChangeUserEmail = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
  //   const { target } = event;
  //   const { value } = target;

  //   setUserEmail(value);
  //   if (value.length === 0) return setUserEmailError(t('account.accountSettings.email.dialog.textField.error.empty'));
  //   if (!isEmailValid(value))
  //     return setUserEmailError(t('account.accountSettings.email.dialog.textField.error.invalid'));
  //   setUserEmailError(null);
  // };

  const handleChangeUserName = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const { target } = event;
    const { value } = target;

    setUserName(value);
    // if (value.length === 0)
    //   return setUserNameError(t('account.accountSettings.personalData.dialog.textField.error.empty'));
    setUserNameError(null);
  };

  const handleSubmitUpdatePersonalDataForm = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    const errorTempArray: string[] = [];

    // if (userName.length === 0) {
    //   setUserNameError(t('account.accountSettings.personalData.dialog.textField.error.empty'));
    //   errorTempArray.push(t('account.accountSettings.personalData.dialog.textField.error.empty'));
    // }

    if (errorTempArray.length > 0) return;

    try {
      setPersonalDataFormError(null);
      setIsPersonalDataFormLoading(true);
      await updateUserName(user, userName);
      setIsPersonalDataDialogOpen(false);
      window.location.reload();
    } catch (e) {
      setPersonalDataFormError(t('shared.requestMessages.changeDataError'));
    } finally {
      setIsPersonalDataFormLoading(false);
    }
  };

  // const handleSubmitUpdateEmailForm = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
  //   event.preventDefault();
  //   const errorTempArray: string[] = [];

  //   if (userEmail.length === 0) {
  //     setUserEmailError(t('account.accountSettings.email.dialog.textField.error.empty'));
  //     errorTempArray.push(t('account.accountSettings.email.dialog.textField.error.empty'));
  //   }

  //   if (userEmail.length > 0 && !isEmailValid(userEmail)) {
  //     setUserEmailError(t('account.accountSettings.email.dialog.textField.error.invalid'));
  //     errorTempArray.push(t('account.accountSettings.email.dialog.textField.error.invalid'));
  //   }

  //   if (errorTempArray.length > 0) return;

  //   try {
  //     setEmailFormError(null);
  //     setIsEmailFormLoading(true);
  //     await updateUserEmail(user, userEmail);
  //     setIsEmailDialogOpen(false);
  //     window.location.reload();
  //   } catch (e) {
  //     setEmailFormError(t('shared.requestMessages.changeDataError'));
  //   } finally {
  //     setIsEmailFormLoading(false);
  //   }
  // };

  return (
    <Container>
      <HelmetContent
        title={t('account.accountSettings.pageTitle')}
        description={t('account.accountSettings.pageDescription')}
      />
      <ContentBox>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            margin: '0 0 20px 0',
            color: (theme) => theme.palette.text.primary,
          }}>
          <Box sx={{ width: '40px' }}>
            <FontAwesomeIcon
              color={theme.palette.common.white}
              size='xl'
              style={{ display: 'inline-block' }}
              icon={faUser}
            />
          </Box>
          <Typography fontWeight={700}>{t('account.accountSettings.personalData.title')}</Typography>
          <Button sx={{ textTransform: 'none' }} onClick={handleClickOpenPersonalDataDialog} variant='text'>
            {t('shared.buttons.edit')}
          </Button>
        </Box>
        <Box sx={{ padding: '0 0 0 40px', color: (theme) => theme.palette.text.primary }}>
          <Typography fontWeight={700} component='p'>
            <Trans
              i18nKey='account.accountSettings.personalData.name'
              values={{
                userName,
              }}
              components={[
                <Typography
                  key={0}
                  fontWeight='inherit'
                  sx={{ display: 'inline-block', margin: '0 10px 0 0' }}
                  component='span'
                />,
                <Typography
                  key={1}
                  component='span'
                  fontWeight='inherit'
                  color='text.secondary'
                  sx={{ display: 'inline-block' }}
                />,
              ]}
            />
          </Typography>
        </Box>
        <Dialog open={isPersonalDataDialogOpen} onClose={handleClosePersonalDataDialog}>
          <DialogTitle>{t('account.accountSettings.personalData.dialog.title')}</DialogTitle>
          <DialogForm onSubmit={(event: FormEvent<HTMLFormElement>) => handleSubmitUpdatePersonalDataForm(event)}>
            <TextField
              autoFocus
              margin='dense'
              id='name'
              value={userName}
              onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleChangeUserName(event)}
              error={!!userNameError}
              helperText={userNameError}
              label={t('account.accountSettings.personalData.dialog.textField.label')}
              type='text'
              fullWidth
              variant='standard'
            />
            {personalDataFormError && (
              <Alert sx={{ margin: '20px 0' }} severity='error'>
                {personalDataFormError}
              </Alert>
            )}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button onClick={handleClosePersonalDataDialog} sx={{ textTransform: 'none' }}>
                {t('shared.buttons.cancel')}
              </Button>
              <LoadingButton
                variant='contained'
                type='submit'
                loading={isPersonalDataFormLoading}
                sx={{ textTransform: 'none' }}
                disabled={isPersonalDataFormLoading}>
                {t('shared.buttons.save')}
              </LoadingButton>
            </Box>
          </DialogForm>
        </Dialog>
      </ContentBox>
      <ContentBox>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            margin: '0 0 20px 0',
            color: (theme) => theme.palette.text.primary,
          }}>
          <Box sx={{ width: '40px' }}>
            <FontAwesomeIcon
              color={theme.palette.common.white}
              size='xl'
              style={{ display: 'inline-block' }}
              icon={faEnvelope}
            />
          </Box>
          <Typography fontWeight={700}>{t('account.accountSettings.email.title')}</Typography>
          {/* <Button sx={{ textTransform: 'none' }} onClick={handleClickOpenEmailDialog} variant='text'>
            {t('shared.buttons.edit')}
          </Button> */}
        </Box>
        <Box sx={{ padding: '0 0 0 40px', color: (theme) => theme.palette.text.primary }}>
          <Typography fontWeight={700} component='p'>
            <Trans
              i18nKey='account.accountSettings.email.email'
              values={{
                email: user.email,
              }}
              components={[
                <Typography
                  key={0}
                  fontWeight='inherit'
                  sx={{ display: 'inline-block', margin: '0 10px 0 0' }}
                  component='span'
                />,
                <Typography
                  key={1}
                  component='span'
                  fontWeight='inherit'
                  color='text.secondary'
                  sx={{ display: 'inline-block' }}
                />,
              ]}
            />
          </Typography>
        </Box>
        {/* <Dialog open={isEmailDialogOpen} onClose={handleCloseEmailDialog}>
          <DialogTitle>{t('account.accountSettings.email.dialog.title')}</DialogTitle>
          <DialogForm onSubmit={(event: FormEvent<HTMLFormElement>) => handleSubmitUpdateEmailForm(event)}>
            <TextField
              autoFocus
              margin='dense'
              id='name'
              value={userEmail}
              onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleChangeUserEmail(event)}
              error={!!userEmailError}
              helperText={userEmailError}
              label={t('account.accountSettings.email.dialog.textField.label')}
              type='text'
              fullWidth
              variant='standard'
            />
            {emailFormError && (
              <Alert sx={{ margin: '20px 0' }} severity='error'>
                {emailFormError}
              </Alert>
            )}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button onClick={handleCloseEmailDialog} sx={{ textTransform: 'none' }}>
                {t('shared.buttons.cancel')}
              </Button>
              <LoadingButton
                variant='contained'
                type='submit'
                loading={isEmailFormLoading}
                sx={{ textTransform: 'none' }}
                disabled={isEmailFormLoading}>
                {t('shared.buttons.save')}
              </LoadingButton>
            </Box>
          </DialogForm>
        </Dialog> */}
      </ContentBox>
      <ContentBox>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            margin: '0 0 20px 0',
            color: (theme) => theme.palette.text.primary,
          }}>
          <Box sx={{ width: '40px' }}>
            <FontAwesomeIcon
              color={theme.palette.common.white}
              size='xl'
              style={{ display: 'inline-block' }}
              icon={faLock}
            />
          </Box>
          <Typography fontWeight={700}>{t('account.accountSettings.password.title')}</Typography>
          <Button sx={{ textTransform: 'none' }} onClick={handleClickResetPassword} variant='text'>
            {t('shared.buttons.resetPassword')}
          </Button>
        </Box>
      </ContentBox>
    </Container>
  );
};

interface OwnProps {
  user: User;
}
