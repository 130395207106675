import React from 'react';
import { Helmet } from 'react-helmet';

export const HelmetContent: React.FC<OwnProps> = (props) => {
  const { title, description, keywords, ogTitle, ogType, ogUrl, ogImage, ogDescription, ogSiteName } = props;
  return (
    <Helmet>
      <title>{title}</title>
      {description && <meta name='description' content={description} />}
      {keywords && <meta name='keywords' content={keywords} />}
      {ogTitle && <meta property='og:title' content={ogTitle} />}
      {ogType && <meta property='og:type' content={ogType} />}
      {ogUrl && <meta property='og:url' content={ogUrl} />}
      {ogImage && <meta property='og:image' content={ogImage} />}
      {ogDescription && <meta property='og:description' content={ogDescription} />}
      {ogSiteName && <meta property='og:site_name' content={ogSiteName} />}
    </Helmet>
  );
};

interface OwnProps {
  title: string;
  description: string;
  keywords?: string;
  ogTitle?: string;
  ogType?: string;
  ogUrl?: string;
  ogImage?: string;
  ogDescription?: string;
  ogSiteName?: string;
}
