import { Slider, styled } from '@mui/material';

export const Container = styled('div')(() => ({
  height: '100%',
}));

export const Title = styled('h1')(({ theme }) => ({
  fontSize: '16px',
  lineHeight: '20px',
  color: theme.palette.text.primary,
  width: '100%',
}));

export const VideoWrapper = styled('div')(() => ({
  height: '100%',
  width: '100%',
  position: 'fixed',
  left: 0,
  top: 0,
}));

export const VideoWrapper2 = styled('div')(() => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'contain',
  backgroundColor: 'black',
}));

export const VideoWrapper3 = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
  alignItems: 'center',
  height: '100%',
  width: 'auto',
});

export const ExtraLayer = styled('div')(() => ({
  left: 0,
  position: 'absolute',
  top: 0,
  width: '100%',
  height: '100%',
}));

interface ControlsLayerInterface {
  displayControls: boolean;
}

export const ControlsLayer = styled('div', {
  shouldForwardProp: (prop) => prop != 'displayControls',
})<ControlsLayerInterface>(({ displayControls }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: '20px',
  opacity: displayControls ? '1' : '0',
  backgroundColor: 'rgba(0,0,0, 0.35)',
  transition: 'opacity 0.3s',
  cursor: displayControls ? 'auto' : 'none',
}));

export const TopControls = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

interface CenterControlsInterface {
  isFullscreen: boolean;
}

export const CenterControls = styled('div')<CenterControlsInterface>(({ isFullscreen }) => ({
  width: '100%',
  flexGrow: 1,
  display: 'flex',
  paddingTop: isFullscreen ? '55px' : '0px',
}));

export const CenterControlsLeft = styled('div')(() => ({
  width: '25%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const CenterControlsMiddle = styled('div')(() => ({
  width: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const CenterControlsRight = styled('div')(() => ({
  width: '25%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const BottomControls = styled('div')(() => ({
  width: '100%',
  padding: '20px 0 0 0',
}));

interface BackTenSecondsBadgeInterface {
  fontSize?: string;
  fontWeight?: number;
  left?: string;
  top?: string;
}

export const BackTenSecondsBadge = styled('p', {
  shouldForwardProp: (prop) => prop != 'fontSize' && prop != 'fontWeight',
})<BackTenSecondsBadgeInterface>(({ theme, fontSize = '9px', fontWeight = 900, left = '51%', top = '50%' }) => ({
  left: left,
  top: top,
  position: 'absolute',
  userSelect: 'none',
  color: theme.palette.primary.main,
  transform: 'translate(-50%, -50%)',
  fontSize: fontSize,
  fontWeight: fontWeight,
}));

interface ForwardTenSecondsBadgeInterface {
  fontSize?: string;
  fontWeight?: number;
  left?: string;
  top?: string;
}

export const ForwardTenSecondsBadge = styled('p', {
  shouldForwardProp: (prop) => prop != 'fontSize' && prop != 'fontWeight',
})<ForwardTenSecondsBadgeInterface>(({ theme, fontSize = '9px', fontWeight = 900, left = '48%', top = '50%' }) => ({
  left: left,
  top: top,
  position: 'absolute',
  userSelect: 'none',
  color: theme.palette.primary.main,
  transform: 'translate(-50%, -50%)',
  fontSize: fontSize,
  fontWeight: fontWeight,
}));

export const VolumeSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.common.white,
  '& .MuiSlider-rail': {
    color: '#bfbfbf',
  },
}));
