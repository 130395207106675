import React, { PropsWithChildren, createContext, useEffect, useMemo, useState } from 'react';
import { calcSubtotalPrice } from 'utils/Context/BasketContext/calcSubtotalPrice/calcSubtotalPrice';
import { BasketMovie } from 'models/Movie/basket-movie/basket-movie';

export const BasketContext = createContext<BasketContextInterface>({
  subtotal: 0,
  counter: 0,
  items: [],
  currency: null,
});

export const BasketContextProvider: React.FC<BasketContextProviderInterface> = (
  props: PropsWithChildren<BasketContextProviderInterface>,
) => {
  const { children, basketMovies } = props;
  const [subtotal, setSubtotal] = useState<number>(0);
  const [counter, setCounter] = useState<number>(0);
  const [items, setItems] = useState<BasketMovie[]>(basketMovies);
  const [currency, setCurrency] = useState<null | string>(basketMovies.length > 0 ? basketMovies[0].currency : null);

  useEffect(() => {
    setSubtotal(calcSubtotalPrice(basketMovies));
    setCounter(basketMovies.length);
    setItems(basketMovies);
    setCurrency(basketMovies.length > 0 ? basketMovies[0].currency : null);
  }, [basketMovies]);

  const value = useMemo<BasketContextInterface>(
    () => ({
      subtotal,
      counter,
      items,
      currency,
    }),
    [subtotal, counter, items, currency],
  );

  return <BasketContext.Provider value={value}>{children}</BasketContext.Provider>;
};

interface BasketContextInterface {
  subtotal: number;
  counter: number;
  items: BasketMovie[];
  currency: null | string;
}

interface BasketContextProviderInterface {
  children: React.ReactNode;
  basketMovies: BasketMovie[];
}
