import React, { useContext, useState } from 'react';
import { Container } from './BasketPrice.styled';
import { Typography } from '@mui/material';
import { faMoneyBill } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BasketContext } from 'context/BasketContext';
import { createCheckoutSession } from 'stripe/createCheckoutSession';
import { LineItem } from 'types/stripe/stripe.type';
import { User } from 'firebase/auth';
import { removeItemFromBasket, removeItemFromWhishlist } from 'firestore';
import { LoadingButton } from '@mui/lab';
import { BasketMovie } from 'models/Movie/basket-movie/basket-movie';
import { Trans, useTranslation } from 'react-i18next';

export const BasketPrice = ({ user }: { user: User }) => {
  const { subtotal, counter, items, currency } = useContext(BasketContext);
  const [loading, setLoading] = useState<boolean>(false);

  const { t, i18n } = useTranslation();

  const handleClickPay = async () => {
    const lineItems: LineItem[] = items.map((basketItem: BasketMovie) => ({
      quantity: 1,
      price: basketItem.priceId,
    }));

    if (lineItems.length === 0) return;
    try {
      setLoading(true);
      await createCheckoutSession(
        user.uid,
        `${window.location.origin}/${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}/my-movies`,
        `${window.location.origin}/${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}/basket`,
        lineItems,
        i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language,
      );
    } catch (e) {
      console.log(e);
    } finally {
      const documentsToRemove: Promise<any>[] = [];
      items.forEach(async (item: BasketMovie) => {
        documentsToRemove.push(removeItemFromBasket(user.uid, item.id));
        documentsToRemove.push(removeItemFromWhishlist(user.uid, item.id));
      });
      await Promise.all(documentsToRemove);
    }
  };

  return (
    <Container>
      <Typography fontSize='36px' fontWeight={700} variant='h2' color='text.primary'>
        <Trans
          i18nKey='basket.basketPrice.sum'
          values={{ sum: (subtotal * 0.01).toFixed(2), currency: currency ? currency.toUpperCase() : '' }}
          components={[
            <Typography
              key={0}
              sx={{ display: 'inline-block', minWidth: '120px' }}
              fontSize='inherit'
              fontWeight='inherit'
              component='span'
              color='primary.main'
            />,
            <Typography key={1} fontSize='30px' fontWeight='inherit' component='span' />,
          ]}
        />
      </Typography>

      <Typography fontSize='16px' sx={{ margin: '0 0 30px 0' }} component='p' color='text.primary'>
        <Trans
          i18nKey='basket.basketPrice.moviesQuantity'
          values={{ quantity: counter }}
          components={[
            <Typography
              key={0}
              sx={{ display: 'inline-block', minWidth: '120px' }}
              component='span'
              fontSize='inherit'
            />,
            <Typography key={1} component='span' fontSize='inherit' />,
          ]}
        />
      </Typography>
      <LoadingButton
        loading={loading}
        sx={{ textTransform: 'none' }}
        disabled={loading || counter === 0}
        onClick={async () => await handleClickPay()}
        fullWidth
        startIcon={<FontAwesomeIcon icon={faMoneyBill} />}
        variant='contained'>
        {t('shared.buttons.pay')}
      </LoadingButton>
    </Container>
  );
};
