import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import { Alert, Button, Link as MUILink, TextField } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { HelmetContent } from 'components/Common/HelmetContent/HelmetContent';
import { firebaseSignInWithEmailAndPassword, firebaseSignInWithGoogle } from 'firestore';
import { SectionLayout } from 'layouts/SectionLayout/SectionLayout';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { isEmailValid } from 'utils/Common/is-email-valid/is-email-valid';
import { scrollTop } from 'utils/Common/scroll-top/scroll-top';
import { Container, Form, FormContainer, TabsContainer } from './Login.styled';

export const Login = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [value, setValue] = React.useState<number>(0);
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string | null>(null);
  const [password, setPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [isUserLoggingViaEmailAndPassword, setIsUserLoggingViaEmailAndPassword] = useState<boolean>(false);
  const [loginUserWithEmailAndPasswordError, setLoginUserWithEmailAndPasswordError] = useState<string | null>(null);

  const handleTabsChange = (event: React.SyntheticEvent, newValue: number): void => {
    setValue(newValue);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setEmail(event.target.value);
    if (event.target.value.length === 0) return setEmailError(t('shared.textField.email.error.empty'));
    if (!isEmailValid(event.target.value)) return setEmailError(t('shared.textField.email.error.invalid'));
    return setEmailError(null);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setPassword(event.target.value);
    if (event.target.value.length === 0) return setPasswordError(t('shared.textField.password.error.empty'));
    if (event.target.value.length < 6) return setPasswordError(t('shared.textField.password.error.tooShort'));
    return setPasswordError(null);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    let errors: string[] = [];

    if (email.length === 0) {
      setEmailError(t('shared.textField.email.error.empty'));
      errors.push(t('shared.textField.email.error.empty'));
    }

    if (email.length > 0 && !isEmailValid(email)) {
      setEmailError(t('shared.textField.email.error.invalid'));
      errors.push(t('shared.textField.email.error.invalid'));
    }

    if (password.length === 0) {
      setPasswordError(t('shared.textField.password.error.empty'));
      errors.push(t('shared.textField.password.error.empty'));
    }

    if (password.length > 0 && password.length < 6) {
      setPasswordError(t('shared.textField.password.error.tooShort'));
      errors.push(t('shared.textField.password.error.tooShort'));
    }

    if (errors.length > 0) return;

    try {
      setLoginUserWithEmailAndPasswordError(null);
      setIsUserLoggingViaEmailAndPassword(true);
      const user = await firebaseSignInWithEmailAndPassword(email, password);
      if (user) {
        navigate(`/${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}`);
      }
    } catch (e: any) {
      setLoginUserWithEmailAndPasswordError(e.message);
    } finally {
      setIsUserLoggingViaEmailAndPassword(false);
    }
  };

  const handleClickSignInWithGoogle = async () => {
    try {
      await firebaseSignInWithGoogle(() => {
        navigate(`/${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}`);
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    scrollTop();
  }, []);

  return (
    <SectionLayout title={t('login.title')}>
      <HelmetContent title={t('login.pageTitle')} description={t('login.pageDescription')} />
      <Container>
        <TabsContainer>
          <Tabs value={value} onChange={handleTabsChange} aria-label='nav tabs example'>
            <Tab
              label={t('shared.tabs.logIn')}
              value={0}
              to={`/${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}/login`}
              component={Link}
            />
            <Tab
              label={t('shared.tabs.register')}
              value={1}
              to={`/${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}/register`}
              component={Link}
            />
          </Tabs>
        </TabsContainer>
        <FormContainer>
          <Form noValidate onSubmit={(event: React.FormEvent<HTMLFormElement>) => handleSubmit(event)}>
            <TextField
              onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleEmailChange(event)}
              value={email}
              error={!!emailError}
              helperText={emailError ? emailError : null}
              id='email'
              sx={{ margin: '0 0 20px 0', width: '100%' }}
              label={t('shared.textField.email.label')}
              variant='standard'
            />
            <TextField
              onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                handlePasswordChange(event)
              }
              type='password'
              value={password}
              error={!!passwordError}
              helperText={passwordError ? passwordError : null}
              id='password'
              sx={{ margin: '0 0 20px 0', width: '100%' }}
              label={t('shared.textField.password.label')}
              variant='standard'
            />
            <MUILink
              textAlign='right'
              sx={{
                display: 'block',
                padding: '3px',
                margin: '0 0 40px 0',
                fontSize: '14px',
                background: (theme) => theme.palette.background.default,
              }}
              component={NavLink}
              to={`/${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}/reset-password`}>
              {t('shared.buttons.forgotPassword')}
            </MUILink>
            <LoadingButton
              loading={isUserLoggingViaEmailAndPassword}
              sx={{ margin: '0 0 40px 0', width: '100%', textTransform: 'none' }}
              variant='contained'
              type='submit'
              size='large'>
              {t('shared.buttons.logIn')}
            </LoadingButton>
            {!!loginUserWithEmailAndPasswordError && (
              <Alert sx={{ margin: '0 0 30px 0' }} severity='error'>
                {t(loginUserWithEmailAndPasswordError)}
              </Alert>
            )}
            <Button
              onClick={handleClickSignInWithGoogle}
              type='button'
              startIcon={<FontAwesomeIcon size='2xs' icon={faGoogle as IconProp} />}
              variant='outlined'
              size='large'
              sx={{
                width: '100%',
                textTransform: 'none',
                background: (theme) => theme.palette.background.default,
              }}>
              {t('shared.buttons.logInWithGoogle')}
            </Button>
          </Form>
        </FormContainer>
      </Container>
    </SectionLayout>
  );
};
