import React from 'react';
import { Container } from './Posters.styled';
import { Title } from 'components/Common/Title/Title';
import { TitleOptionEnum } from 'types/common/Title/title-option.type';
import { PostersCarousel } from './PostersCarousel/PostersCarousel';
import { Poster } from 'types/shared/poster.type';
import { useTranslation } from 'react-i18next';

export const Posters: React.FC<OwnProps> = ({ posters }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Title title={t('movie.posters.title')} addIcons={true} titleOption={TitleOptionEnum.SMALL} />
      <PostersCarousel posters={posters} />
    </Container>
  );
};

interface OwnProps {
  posters: Poster[];
}
