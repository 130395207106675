import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import { Alert, Box, IconButton, TextField } from '@mui/material';
import { HelmetContent } from 'components/Common/HelmetContent/HelmetContent';
import { LoadingScreen } from 'components/Shared/LoadingScreen/LoadingScreen';
import { User } from 'firebase/auth';
import { firebaseSignInWithEmailLink, firebaseUpdatePassword } from 'firestore';
import { SectionLayout } from 'layouts/SectionLayout/SectionLayout';
import { SnackbarKey, closeSnackbar, useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { NavLink, Navigate, useNavigate } from 'react-router-dom';
import { Container, Form, FormContainer } from './SetPassword.styled';
import { scrollTop } from 'utils/Common/scroll-top/scroll-top';
import { Link as MUILink } from '@mui/material';

export const SetPassword: React.FC = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [password, setPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [repeatedPassword, setRepeatedPassword] = useState<string>('');
  const [repeatedPasswordError, setRepeatedPasswordError] = useState<string | null>(null);
  const [isSignInWithEmailLinkLoading, setIsSignInWithEmailLinkLoading] = useState<boolean>(true);
  const [isUpdatePasswordLoading, setIsUpdatePasswordLoading] = useState<boolean>(false);
  const [isUpdatePasswordError, setIsUpdatePasswordError] = useState<null | string>(null);
  const [user, setUser] = useState<User | null>(null);

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setPassword(event.target.value);
    if (event.target.value.length === 0) return setPasswordError(t('shared.textField.password.error.empty'));
    if (event.target.value.length < 6) return setPasswordError(t('shared.textField.password.error.tooShort'));
    if (event.target.value === repeatedPassword) setRepeatedPasswordError(null);
    if (event.target.value !== repeatedPassword && repeatedPassword.length > 0)
      setRepeatedPasswordError(t('shared.textField.password.error.passwordsAreNotTheSame'));
    return setPasswordError(null);
  };

  const handleRepeatedPasswordChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setRepeatedPassword(event.target.value);
    if (event.target.value.length === 0) return setRepeatedPasswordError(t('shared.textField.password.error.empty'));
    if (event.target.value !== password)
      return setRepeatedPasswordError(t('shared.textField.password.error.passwordsAreNotTheSame'));
    if (event.target.value === password) setRepeatedPasswordError(null);
    return setRepeatedPasswordError(null);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>, user: User) => {
    event.preventDefault();

    let errors: string[] = [];

    if (password.length === 0) {
      setPasswordError(t('shared.textField.password.error.empty'));
      errors.push(t('shared.textField.password.error.empty'));
    }

    if (password.length > 0 && password.length < 6) {
      setPasswordError(t('shared.textField.password.error.tooShort'));
      errors.push(t('shared.textField.password.error.tooShort'));
    }

    if (repeatedPassword.length === 0) {
      setRepeatedPasswordError(t('shared.textField.password.error.empty'));
      errors.push(t('shared.textField.password.error.empty'));
    }

    if (repeatedPassword.length > 0 && password !== repeatedPassword) {
      setRepeatedPassword(t('shared.textField.password.error.passwordsAreNotTheSame'));
    }

    if (errors.length > 0) return;

    try {
      setIsUpdatePasswordLoading(true);
      setIsUpdatePasswordError(null);
      await firebaseUpdatePassword(user, password);
      navigate(`/${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}`);
      window.location.reload();
    } catch (e: any) {
      setIsUpdatePasswordError(e.message);
    } finally {
      setIsUpdatePasswordLoading(false);
    }
  };

  const action = (snackbarId: SnackbarKey) => (
    <IconButton
      onClick={() => {
        closeSnackbar(snackbarId);
      }}>
      <FontAwesomeIcon icon={faXmark} />
    </IconButton>
  );

  useEffect(() => {
    (async () => {
      try {
        setIsSignInWithEmailLinkLoading(true);
        const user = await firebaseSignInWithEmailLink(t);
        if (user) {
          setUser(user as User);
        }
      } catch (e: any) {
        if (e.message === 'shared.firebaseErrorCodes.auth/invalid-email')
          return enqueueSnackbar(
            <Box>
              <Trans
                i18nKey='shared.firebaseErrorCodes.auth/invalid-email'
                components={[
                  <MUILink
                    key={0}
                    sx={{ display: 'inline-block' }}
                    component={NavLink}
                    to={`/${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}/register`}
                  />,
                ]}
              />
            </Box>,
            { variant: 'error', persist: true, action },
          );
        enqueueSnackbar(t(e.message), { variant: 'error', persist: true, action });
      } finally {
        setIsSignInWithEmailLinkLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    scrollTop();
  }, []);

  if (!user && !isSignInWithEmailLinkLoading)
    return <Navigate to={`/${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}`} />;
  if (!user) return <LoadingScreen />;
  return (
    <SectionLayout title={t('setPassword.title')}>
      <HelmetContent title={t('setPassword.pageTitle')} description={t('setPassword.pageDescription')} />
      <Container>
        <FormContainer>
          <Form noValidate onSubmit={(event: React.FormEvent<HTMLFormElement>) => handleSubmit(event, user)}>
            <TextField
              onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                handlePasswordChange(event)
              }
              type='password'
              value={password}
              error={!!passwordError}
              helperText={passwordError ? passwordError : null}
              id='password'
              sx={{ margin: '0 0 20px 0', width: '100%' }}
              label={t('shared.textField.password.label')}
              variant='standard'
            />
            <TextField
              onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                handleRepeatedPasswordChange(event)
              }
              type='password'
              value={repeatedPassword}
              error={!!repeatedPasswordError}
              helperText={repeatedPasswordError ? repeatedPasswordError : null}
              id='repeatedPassword'
              sx={{ margin: '0 0 40px 0', width: '100%' }}
              label={t('shared.textField.password.label2')}
              variant='standard'
            />
            <LoadingButton
              sx={{ margin: '0 0 40px 0', width: '100%', textTransform: 'none' }}
              variant='contained'
              loading={isUpdatePasswordLoading}
              type='submit'
              size='large'>
              {t('shared.buttons.setPassword')}
            </LoadingButton>
            {!!isUpdatePasswordError && (
              <Alert sx={{ margin: '0 0 30px 0' }} severity='error'>
                {t(isUpdatePasswordError)}
              </Alert>
            )}
          </Form>
        </FormContainer>
      </Container>
    </SectionLayout>
  );
};
