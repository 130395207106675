import { styled } from '@mui/material';
import { Link } from 'react-router-dom';

export const Container = styled('main')(({ theme }) => ({
  minHeight: '100vh',
  background: theme.palette.background.background5,
  padding: '40px 20px',

  [theme.breakpoints.up('md')]: {
    padding: '40px',
  },

  [theme.breakpoints.up('lg')]: {
    padding: '60px 80px 40px 80px',
  },

  [theme.breakpoints.up('xl')]: {
    padding: '120px 100px 40px 100px',
  },
}));

export const ImageWrapper = styled(Link)(({ theme }) => ({
  gridArea: 'box1',
  width: '100%',
  maxWidth: '200px',

  [theme.breakpoints.up('md')]: {
    maxWidth: '400px',
  },
}));

export const Section = styled('section')(({ theme }) => ({
  display: 'grid',

  gridTemplateColumns: '1fr',
  gridTemplateRows: 'auto',
  gridRowGap: '30px',

  gridTemplateAreas: `"box2"
                      "box1"
                      "button"`,

  [theme.breakpoints.up('md')]: {
    gridColumnGap: '40px',
    gridTemplateColumns: 'minmax(300px, 400px) minmax(200px, 600px)',
    gridTemplateRows: 'auto',
    gridTemplateAreas: `"box1 box2" "box1 box2" "box1 button"`,
  },
}));

export const Image = styled('img')({
  width: '100%',
});
