import { Stripe, loadStripe } from '@stripe/stripe-js';

let stripePromise: Stripe | null = null;

export const initializeStripe = async (): Promise<Stripe | null> => {
  if (!stripePromise && process.env.REACT_APP_STRIPE_PUBLIC_KEY) {
    stripePromise = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY, { apiVersion: '2024-04-10' });
  }

  return stripePromise;
};
