import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const appLanguage: string | null = localStorage.getItem('appLanguage');

if (appLanguage) {
  i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
      debug: false,
      lng: appLanguage,
      interpolation: {
        escapeValue: false,
      },
    });
} else {
  i18n
    .use(Backend)
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
      debug: false,
      interpolation: {
        escapeValue: false,
      },
    });
}

export const switchLanguage = (language: string, callback?: () => void) => {
  localStorage.setItem('appLanguage', language);
  i18n.changeLanguage(language);
  if (callback) {
    callback();
  }
};

export interface Locale {
  label: string;
  value: string;
}

export const locales: Locale[] = [
  {
    label: 'shared.languages.pl',
    value: 'pl',
  },
  {
    label: 'shared.languages.en',
    value: 'en',
  },
];

export default i18n;
