import React, { PropsWithChildren, createContext, useEffect, useMemo, useState } from 'react';
import { Movie } from 'types/shared/movie-type';

export const MoviesContext = createContext<MoviesContextInterface>({
  movies: [],
});

export const MoviesContextProvider: React.FC<MoviesContextProviderInterface> = (
  props: PropsWithChildren<MoviesContextProviderInterface>,
) => {
  const { children, initialMovies } = props;
  const [movies, setMovies] = useState<Movie[]>(initialMovies);

  useEffect(() => {
    setMovies(initialMovies);
  }, [initialMovies]);

  const value = useMemo<MoviesContextInterface>(
    () => ({
      movies,
    }),
    [movies],
  );

  return <MoviesContext.Provider value={value}>{children}</MoviesContext.Provider>;
};

interface MoviesContextInterface {
  movies: Movie[];
}

interface MoviesContextProviderInterface {
  children: React.ReactNode;
  initialMovies: Movie[];
}
