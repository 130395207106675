export const convertMovieTileToDescriptivePath = (movieTitle: string): string => {
  if (movieTitle.includes(' (')) {
    return movieTitle
      .split(' (')[0]
      .replace(':', '')
      .replace(`'s`, ' is')
      .replace('&', 'and')
      .replace(',', '')
      .replace('?', '')
      .split(' ')
      .join('-');
  }
  return movieTitle
    .replace(':', '')
    .replace(`'s`, ' is')
    .replace('&', 'and')
    .replace(',', '')
    .replace('?', '')
    .split(' ')
    .join('-');
};
