import React, { useState } from 'react';
import {
  Container,
  TitleModifier,
  Text,
  IconsContainer,
  HoverAnimationTextContainer,
  HoverAnimationText,
} from './Title.styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { TitleOptionEnum } from 'types/common/Title/title-option.type';

export const Title: React.FC<OwnProps> = (props: OwnProps) => {
  const {
    titlePrefix,
    title,
    addIcons,
    defaultMargin,
    titleOption = TitleOptionEnum.LARGE,
    hoverAnimation = false,
    hoverAnimationText,
  } = props;

  const [, setUserHoverOverElement] = useState<boolean>(false);

  const handleMouseEnter = () => setUserHoverOverElement(true);
  const handleMouseLeave = () => setUserHoverOverElement(false);

  return (
    <Container
      hoverAnimation={hoverAnimation}
      defaultMargin={defaultMargin}
      titleOption={titleOption}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
      {!!titlePrefix && <TitleModifier>{titlePrefix}</TitleModifier>}
      <Text hoverAnimation={hoverAnimation}>{title}</Text>
      {addIcons && (
        <IconsContainer hoverAnimation={hoverAnimation}>
          <FontAwesomeIcon size='sm' icon={faChevronRight} />
          <FontAwesomeIcon size='sm' icon={faChevronRight} />
        </IconsContainer>
      )}

      {hoverAnimationText && (
        <HoverAnimationTextContainer>
          <HoverAnimationText>{hoverAnimationText}</HoverAnimationText>
        </HoverAnimationTextContainer>
      )}
    </Container>
  );
};

interface OwnProps {
  titlePrefix?: string;
  defaultMargin?: string;
  title: string;
  addIcons: boolean;
  titleOption?: TitleOptionEnum;
  hoverAnimation?: boolean;
  hoverAnimationText?: string;
}
