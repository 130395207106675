import { faCirclePlay, faSignature } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Link, useTheme } from '@mui/material';
import { ImageLoader } from 'components/Common/ImageLoader/ImageLoader';
import { SegmentDescriptor } from 'components/Common/SegmentDecriptor/SegmentDecriptor';
import { WatchedTimeIndicator } from 'components/Common/WatchedTimeIndicator/WatchedTimeIndicator';
import { User } from 'firebase/auth';
import { getBookmark } from 'firestore';
import { Bookmark } from 'models/Player/bookmark';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { MyMovie } from 'types/shared/movie-type';
import { Container, DescriptionBox, ImageWrapper, MovieNav } from './MyMovieListItem.styled';
import { convertMovieTileToDescriptivePath } from 'utils/Common/convert-movie-title-to-descriptive-path/convert-movie-title-to-descriptive-path';

export const MyMovieListItem: React.FC<OwnProps> = (props) => {
  const { myMovie, user } = props;
  const { title, imageSmall, id } = myMovie;
  const { i18n } = useTranslation();
  const [videoBookmark, setVideoBookmark] = useState<Bookmark | null>(null);
  const [isBookmarkLoading, setIsBookmarkLoading] = useState<boolean>(true);

  const { t } = useTranslation();
  const theme = useTheme();

  useEffect(() => {
    (async () => {
      try {
        const bookmark = await getBookmark(user.uid, id);

        if (bookmark) {
          setVideoBookmark(bookmark);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setIsBookmarkLoading(false);
      }
    })();
  }, []);

  if (isBookmarkLoading) return null;
  return (
    <Container>
      <ImageWrapper>
        <MovieNav
          to={`/${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}/movies/${convertMovieTileToDescriptivePath(t(title))}/${id}/player`}>
          <FontAwesomeIcon color={theme.palette.text.primary} icon={faCirclePlay} size='5x' />
        </MovieNav>
        <ImageLoader
          imageFirebasePath={imageSmall[i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language]}
        />
      </ImageWrapper>
      <DescriptionBox>
        {!!videoBookmark && (
          <Box sx={{ position: 'absolute', left: 0, bottom: '100%', width: '100%' }}>
            <WatchedTimeIndicator
              videoWatchedTime={videoBookmark.lastWatchTime}
              videoDuration={videoBookmark.videoDuration}
            />
          </Box>
        )}
        <SegmentDescriptor
          icon={faSignature}
          segmentDescriptorSize='small'
          title={t('shared.segmentDescriptor.title')}
          description={
            <Link
              component={NavLink}
              to={`/${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}/movies/${convertMovieTileToDescriptivePath(t(title))}/${id}`}
              color='inherit'>
              {t(title)}
            </Link>
          }
        />
      </DescriptionBox>
    </Container>
  );
};

interface OwnProps {
  myMovie: MyMovie;
  user: User;
}
