import { ThemeOptions } from '@mui/material';
import React from 'react';

declare module '@mui/material/styles' {
  // eslint-disable-next-line
  interface TypeBackground {
    background2: string;
    background3: string;
    background4: string;
    background5: string;
  }

  // eslint-disable-next-line
  interface Palette {
    // needed for type in themeing in styled components files
    border: {
      color1: React.CSSProperties['color'];
      color2: React.CSSProperties['color'];
    };
  }

  // eslint-disable-next-line
  interface PaletteOptions {
    // neded for type for creatingTheme
    border: {
      color1: React.CSSProperties['color'];
      color2: React.CSSProperties['color'];
    };
  }
}

export const getDesign: ThemeOptions = {
  palette: {
    mode: 'dark',
    background: {
      paper: '#0A1929',
      default: '#0A1929',
      background2: '#29425B',
      background3: '#001E3C',
      background4: '#132F4C',
      background5: '#080F15',
    },
    border: {
      color1: '#c2e0ff66',
      color2: '#1E4976',
    },
    text: {
      primary: '#E7EBF0',
      secondary: '#B2BAC2',
    },
  },
};
