import { Movies } from 'components/Movies/Movies';
import React from 'react';

export const BasedOnTheTrueStoryMovies: React.FC = () => {
  return (
    <Movies
      initialTag='based_on_the_true_story'
      title='basedOnTheTrueStoryMovies.title'
      titlePrefix='basedOnTheTrueStoryMovies.titlePrefix'
      pageTitle='basedOnTheTrueStoryMovies.pageTitle'
      pageDescription='basedOnTheTrueStoryMovies.pageDescription'
      keywords='basedOnTheTrueStoryMovies.pageKeywords'
    />
  );
};
