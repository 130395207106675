import { Category, Movie } from 'types/shared/movie-type';

export const sortMoviesByCategoryAndOrder = (category: string, movies: Movie[]): Movie[] => {
  return movies.sort((movieA: Movie, movieB: Movie) => {
    const orderOfMovieA: number = movieA.categories.find(
      (movieCategory: Category) => movieCategory.value === category,
    )!!.order;
    const orderOfMovieB: number = movieB.categories.find(
      (movieCategory: Category) => movieCategory.value === category,
    )!!.order;
    return orderOfMovieA - orderOfMovieB;
  });
};
