import { User } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { checkIsUserAuth } from 'firestore';

interface UseAuth {
  user: null | User;
  isLoading: boolean;
}

export const useAuth = (): UseAuth => {
  const [user, setUser] = useState<null | User>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    checkIsUserAuth(
      (user: User | null) => {
        setIsLoading(false);
        setUser(user);
      },
      () => {
        setUser(null);
        setIsLoading(false);
      },
    );
  }, []);

  return { isLoading, user };
};
