import React from 'react';
import { Container } from './ShoppingListItem.styled';
import { Typography } from '@mui/material';
import { ProductsList } from './ProductsList/ProductsList';
import dayjs from 'dayjs';
import { Payment } from 'models/Account/Shopping/payment/payment';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';

export const ShoppingListItem: React.FC<OwnProps> = (props: OwnProps) => {
  const {
    payment: { amount, items, created, id, currency },
  } = props;

  const { i18n } = useTranslation();

  return (
    <Container>
      <Typography fontSize='36px' fontWeight={700} variant='h2' color='text.primary'>
        <Trans
          i18nKey='account.shopping.shoppingListItem.sum'
          values={{ sum: (amount * 0.01).toFixed(2), currency: currency.toUpperCase() }}
          components={[
            <Typography
              key={0}
              sx={{ display: 'inline-block', minWidth: '120px' }}
              fontSize='inherit'
              fontWeight='inherit'
              component='span'
              color='primary.main'
            />,
            <Typography key={1} fontSize='30px' fontWeight='inherit' component='span' />,
          ]}
        />
      </Typography>
      <Typography fontSize='16px' sx={{ margin: '0 0 5px 0' }} component='p' color='text.primary'>
        <Trans
          i18nKey='account.shopping.shoppingListItem.purchaseNumber'
          values={{ purchaseNumber: id }}
          components={[
            <Typography
              key={0}
              sx={{ display: 'inline-block', minWidth: '120px' }}
              component='span'
              fontSize='inherit'
            />,
            <Typography key={1} component='span' fontSize='inherit' />,
          ]}
        />
      </Typography>
      <Typography fontSize='16px' sx={{ margin: '0 0 30px 0' }} component='p' color='text.primary'>
        <Trans
          i18nKey='account.shopping.shoppingListItem.purchaseDate'
          values={{
            purchaseDate: dayjs(created).format(
              (i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language) === 'en'
                ? 'YYYY-MM-DD'
                : 'DD/MM/YYYY',
            ),
          }}
          components={[
            <Typography
              key={0}
              sx={{ display: 'inline-block', minWidth: '120px' }}
              component='span'
              fontSize='inherit'
            />,
            <Typography key={1} component='span' fontSize='inherit' />,
          ]}
        />
      </Typography>
      <ProductsList paymentItems={items} />
    </Container>
  );
};

interface OwnProps {
  payment: Payment;
}
