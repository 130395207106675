import { Payment } from 'models/Account/Shopping/payment/payment';
import { PaymentItem } from 'models/Account/Shopping/payment/payment-item/payment-item';
import { Movie } from 'types/shared/movie-type';
import dayjs from 'dayjs';

export const checkIfUserPaidForMovie = (movie: Movie, payments: Payment[]): boolean => {
  let isSomePaymentsItemContainProductId: boolean = false;
  const TWO_DAYS_IN_MILISECONDS: number = 172800000;
  payments.forEach((payment: Payment) => {
    if (payment.status !== 'succeeded') return;
    const isSomePaymentItemContainProductId = payment.items.some((item: PaymentItem) => {
      const paymentDate = dayjs(payment.created);
      const todayDate = dayjs();
      const diff = paymentDate.diff(todayDate);
      const isDateOfPaymentIsLongerThanTwoDaysAfterTodaysDate = Math.abs(diff) > TWO_DAYS_IN_MILISECONDS;

      return item.productId === movie.productId && !isDateOfPaymentIsLongerThanTwoDaysAfterTodaysDate;
    });
    if (isSomePaymentItemContainProductId) isSomePaymentsItemContainProductId = true;
  });
  return isSomePaymentsItemContainProductId;
};
