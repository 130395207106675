import { styled } from '@mui/material';
import { TitleOptionEnum } from 'types/common/Title/title-option.type';

interface ContainerProps {
  defaultMargin?: string;
  titleOption: TitleOptionEnum;
  hoverAnimation: boolean;
}

export const Container = styled('h1', {
  shouldForwardProp: (prop) => prop !== 'defaultMargin' && prop !== 'titleOption' && prop !== 'hoverAnimation',
})<ContainerProps>(({ theme, defaultMargin, titleOption, hoverAnimation }) => ({
  fontSize: titleOption === TitleOptionEnum.LARGE ? '26px' : '20px',
  fontWeight: '500',
  padding: '0 0 10px 0',
  width: 'fit-content',
  position: 'relative',
  color: theme.palette.common.white,
  borderBottom: `3px solid ${theme.palette.primary.main}`,
  margin: defaultMargin ? defaultMargin : 0,

  '&:hover div:first-of-type': {
    transition: hoverAnimation ? 'color .3s' : 'none',
    color: hoverAnimation ? theme.palette.primary.main : theme.palette.common.white,
  },

  '&:hover div:last-of-type': {
    opacity: 1,
    transition: 'opacity .3s',
  },

  [theme.breakpoints.up('lg')]: {
    fontSize: titleOption === TitleOptionEnum.LARGE ? '30px' : '24px',
  },
}));

interface TextProps {
  hoverAnimation: boolean;
}

export const Text = styled('span')<TextProps>(({ hoverAnimation }) => ({
  display: 'inline-block',
  transition: hoverAnimation ? 'opacity .3s' : 'text',
}));

export const TitleModifier = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
  display: 'inline-block',
  margin: '0 10px 0 0',
}));

interface IconsContainerProps {
  hoverAnimation: boolean;
}

export const IconsContainer = styled('div')<IconsContainerProps>(({ hoverAnimation }) => ({
  margin: '0 0 0 15px',
  display: 'inline-block',
  transition: hoverAnimation ? 'color .3s' : 'none',
}));

export const HoverAnimationTextContainer = styled('div')({
  transition: 'opacity .3s',
  opacity: 0,
  position: 'absolute',
  left: 'calc(100% + 10px)',
  top: '50%',
  height: '100%',
  transform: 'translateY(-43%)',
});

export const HoverAnimationText = styled('p')(({ theme }) => ({
  transition: 'all .3s',
  fontSize: '16px',
  fontWeight: 500,
  whiteSpace: 'nowrap',
  color: theme.palette.common.white,
}));
