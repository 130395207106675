import { styled } from '@mui/material';

export const Container = styled('li')(({ theme }) => ({
  padding: '20px 0',
  borderBottom: `1px solid ${theme.palette.border.color2}`,

  '&:last-of-type': {
    borderBottom: 'none',
    padding: '20px 0 0 0',
  },
}));

export const Image = styled('img')(() => ({
  width: '100%',
}));
