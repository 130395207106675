import { styled } from '@mui/material';

export const Container = styled('div')(({ theme }) => ({
  flexDirection: 'column',
  flex: '1 0 auto',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  padding: '20px',
  backgroundColor: theme.palette.background.default,
}));
