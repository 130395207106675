import React from 'react';
import { Container } from './ProductsList.styled';
import { ProductsListItem } from './ProductsListItem/ProductsListItem';
import { PaymentItem } from 'models/Account/Shopping/payment/payment-item/payment-item';

export const ProductsList: React.FC<OwnProps> = (props: OwnProps) => {
  const { paymentItems } = props;
  return (
    <Container>
      {paymentItems.map((element: PaymentItem) => (
        <ProductsListItem key={element.id} paymentItem={element} />
      ))}
    </Container>
  );
};

interface OwnProps {
  paymentItems: PaymentItem[];
}
