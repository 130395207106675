import { addDoc, collection } from 'firebase/firestore';
import { db } from 'firestore';
import { CollectionReference, DocumentData, DocumentReference, doc, onSnapshot } from 'firebase/firestore';
import { initializeStripe } from './initializeStripe';
import { LineItem } from 'types/stripe/stripe.type';

export const createCheckoutSession = async (
  uid: string,
  success_url: string,
  cancel_url: string,
  lineItems: LineItem[],
  locale: string,
) => {
  try {
    const userCheckoutSessionsCollectionRef: CollectionReference<DocumentData> = collection(
      db,
      'users',
      uid,
      'checkout_sessions',
    );
    const createdUserCheckoutSessionDocRef: DocumentReference<DocumentData> = await addDoc(
      userCheckoutSessionsCollectionRef,
      {
        success_url: success_url,
        cancel_url: cancel_url,
        allow_promotion_codes: true,
        payment_method_types: ['card', 'p24', 'blik'],
        line_items: lineItems,
        mode: 'payment',
        //optionals
        locale: locale,
        billing_address_collection: 'auto',
      },
    );

    const userCheckoutSessionDocRef: DocumentReference<DocumentData> = await doc(
      db,
      'users',
      uid,
      'checkout_sessions',
      createdUserCheckoutSessionDocRef.id,
    );

    onSnapshot(userCheckoutSessionDocRef, async (doc) => {
      const { sessionId }: any = doc.data();

      if (sessionId) {
        try {
          const stripe = await initializeStripe();
          if (stripe) {
            await stripe.redirectToCheckout({ sessionId });
          }
        } catch (e) {
          // @ts-ignore
          throw new Error(e);
        }
      }
    });
  } catch (e) {
    // @ts-ignore
    throw new Error(e);
  }
};
