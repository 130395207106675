import { FirebaseError } from 'firebase/app';

export const firestoreErrorHandler = (error: FirebaseError): string => {
  switch (error?.code) {
    case 'auth/user-not-found':
      return 'shared.firebaseErrorCodes.auth/user-not-found';
    case 'auth/email-already-in-use':
      return 'shared.firebaseErrorCodes.auth/email-already-in-use';
    case 'auth/wrong-password':
      return 'shared.firebaseErrorCodes.auth/wrong-password';
    case 'auth/invalid-action-code': // case when user click in expired link to set password
      return 'shared.firebaseErrorCodes.auth/invalid-action-code';
    case 'auth/invalid-email': // case when user don't have email in localstorage, is asked to provide email in promot and he provide wrong email (not associated with email set pasword link)
      return 'shared.firebaseErrorCodes.auth/invalid-email';
    default:
      return 'shared.firebaseErrorCodes.default';
  }
};
