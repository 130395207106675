import { styled } from '@mui/material';

export const Container = styled('main')(() => ({}));

export const TabsContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  margin: '0 0 40px 0',
}));
