import { styled } from '@mui/material';

export const Container = styled('div')(({ theme }) => ({
  background: theme.palette.background.background4,
  borderRadius: '5px',
  boxShadow: theme.shadows[10],
  padding: '0 25px',
  border: `2px solid ${theme.palette.border.color2}`,

  [theme.breakpoints.up('md')]: {
    padding: '0 35px',
  },
}));

export const ContentBox = styled('div')(({ theme }) => ({
  padding: '20px 0',
  borderBottom: `1px solid ${theme.palette.border.color2}`,

  [theme.breakpoints.up('md')]: {
    padding: '30px 0',
  },

  '&:last-of-type': {
    borderBottom: 'none',
  },
}));

export const DialogForm = styled('form')(() => ({
  padding: '8px 24px',
}));
