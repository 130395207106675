import React, { PropsWithChildren, createContext, useEffect, useMemo, useState } from 'react';
import { WhishlistMovie } from 'models/Movie/whishlist-movie/whishlist-movie';

export const WhishlistContext = createContext<WhishlistContextInterface>({
  counter: 0,
  items: [],
});

export const WhishlistContextProvider: React.FC<WhishlistContextProviderInterface> = (
  props: PropsWithChildren<WhishlistContextProviderInterface>,
) => {
  const { children, whishlistMovies } = props;
  const [counter, setCounter] = useState<number>(0);
  const [items, setItems] = useState<WhishlistMovie[]>(whishlistMovies);

  useEffect(() => {
    setCounter(whishlistMovies.length);
    setItems(whishlistMovies);
  }, [whishlistMovies]);

  const value = useMemo<WhishlistContextInterface>(
    () => ({
      counter,
      items,
    }),
    [counter, items],
  );

  return <WhishlistContext.Provider value={value}>{children}</WhishlistContext.Provider>;
};

interface WhishlistContextInterface {
  counter: number;
  items: WhishlistMovie[];
}

interface WhishlistContextProviderInterface {
  children: React.ReactNode;
  whishlistMovies: WhishlistMovie[];
}
