import Modal from '@mui/material/Modal';
import { ImageLoader } from 'components/Common/ImageLoader/ImageLoader';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Poster } from 'types/shared/poster.type';
import { Container, ModalBox, StyledPoster } from './PostersCarousel.styled';

export const PostersCarousel: React.FC<OwnProps> = (props) => {
  const { posters } = props;
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [activePoster, setActivePoster] = useState<string | null>(null);
  const { i18n, t } = useTranslation();

  const handleCloseModal = () => {
    setModalOpen(false);
    setActivePoster(null);
  };

  const handleOpenModal = (posterSrc: string) => {
    setActivePoster(posterSrc);
    setModalOpen(true);
  };

  const responsive = {
    xl: {
      breakpoint: { max: 4000, min: 1536 },
      items: 7,
    },
    lg: {
      // the naming can be any, depends on you.
      breakpoint: { max: 1535, min: 1200 },
      items: 5,
    },
    md: {
      breakpoint: { max: 1199, min: 900 },
      items: 4,
      partialVisibilityGutter: 40,
    },
    sm: {
      breakpoint: { max: 899, min: 600 },
      items: 2.5,
    },
    xs: {
      breakpoint: { max: 599, min: 0 },
      items: 1.05,
    },
  };

  return (
    <Container>
      <Carousel
        itemClass='carousel-item'
        containerClass='carousel-container'
        infinite
        swipeable
        draggable
        removeArrowOnDeviceType={['xs', 'md']}
        responsive={responsive}>
        {posters.map((element: Poster) => (
          <StyledPoster
            onClick={() =>
              handleOpenModal(element.src[i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language])
            }
            key={element.id}>
            <ImageLoader
              alt={t(element.name)}
              imageFirebasePath={element.src[i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language]}
            />
          </StyledPoster>
        ))}
      </Carousel>
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <ModalBox>{activePoster && <ImageLoader imageFirebasePath={activePoster} />}</ModalBox>
      </Modal>
    </Container>
  );
};

interface OwnProps {
  posters: Poster[];
}
