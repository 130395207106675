import { styled } from '@mui/material';

interface ContainerProps {
  addStyleTop: boolean;
  addStyleBottom: boolean;
  paddingDefault?: string;
  paddingMd?: string;
  paddingLg?: string;
  paddingXl?: string;
}

export const Container = styled('section', {
  shouldForwardProp: (prop) =>
    prop !== 'addStyleTop' &&
    prop !== 'addStyleBottom' &&
    prop !== 'paddingDefault' &&
    prop !== 'paddingMd' &&
    prop !== 'paddingLg' &&
    prop !== 'paddingXl',
})<ContainerProps>(({ theme, addStyleTop, addStyleBottom, paddingDefault, paddingMd, paddingLg, paddingXl }) => ({
  display: 'flex',
  justifyContent: 'center',
  minHeight: '100vh',
  padding: paddingDefault ? paddingDefault : '40px 20px',
  overflow: 'hidden',
  position: 'relative',

  [theme.breakpoints.up('md')]: {
    padding: paddingMd ? paddingMd : '40px',
  },

  [theme.breakpoints.up('lg')]: {
    padding: paddingLg ? paddingLg : '60px 80px 40px 80px',
  },

  [theme.breakpoints.up('xl')]: {
    padding: paddingXl ? paddingXl : '120px 100px 40px 100px',
  },

  '&:before': {
    backgroundImage: 'url("/assets/images/backgrounds/background_image_2.svg")',
    display: addStyleTop ? 'block' : 'none',
    content: '""',
    width: '100%',
    height: '250px',
    backgroundSize: '100%',
    backgroundPositionX: '-500px',
    backgroundPositionY: '-500px',

    backgroundRepeat: 'no-repeat',
    zIndex: 0,
    position: 'absolute',
    top: '0',
    left: '0',
  },

  '&:after': {
    backgroundImage: 'url("/assets/images/backgrounds/background_image_2.svg")',
    display: addStyleBottom ? 'block' : 'none',
    content: '""',
    width: '100%',
    height: '500px',
    backgroundSize: '165%',
    backgroundPositionX: '20%',
    backgroundPositionY: '28%',

    backgroundRepeat: 'no-repeat',
    zIndex: 0,
    position: 'absolute',
    bottom: '0',
    right: '0',
  },
}));

interface WrapperProps {
  maxWidth?: 'auto';
}

export const Wrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'maxWidth',
})<WrapperProps>(({ maxWidth }) => ({
  width: '100%',
  maxWidth: maxWidth ? maxWidth : '1000px',
  position: 'relative',
  zIndex: 1,
}));

export const TitleWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',

  margin: '0 0 50px 0',

  [theme.breakpoints.up('lg')]: {
    margin: '0 0 120px 0',
  },
}));
