import { faChevronDown, faEnvelope, faPhone, faSign } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { HelmetContent } from 'components/Common/HelmetContent/HelmetContent';
import { SegmentDescriptor } from 'components/Common/SegmentDecriptor/SegmentDecriptor';
import { SectionLayout } from 'layouts/SectionLayout/SectionLayout';
import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { scrollTop } from 'utils/Common/scroll-top/scroll-top';
import { Container, PointsList, PointsListItem } from './Help.styled';
import { Link as MUILink } from '@mui/material';
import { NavLink } from 'react-router-dom';

export const Help: React.FC = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    scrollTop();
  }, []);

  return (
    <Container>
      <HelmetContent title={t('help.pageTitle')} description={t('help.pageDescription')} />
      <SectionLayout addStyleBottom={false} title={t('help.title')}>
        <SegmentDescriptor title={t('help.content.title')} description={t('help.content.description')} icon={faSign} />
        <Accordion>
          <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />}>
            <Typography variant='h2' fontSize='22px' fontWeight={400}>
              {t('help.content.element1.title')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <SegmentDescriptor
              title={t('help.content.element1.child1.email')}
              description={t('help.content.element1.child1.value')}
              icon={faEnvelope}
            />
            <SegmentDescriptor
              title={t('help.content.element1.child2.phone')}
              description={t('help.content.element1.child2.value')}
              icon={faPhone}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />}>
            <Typography variant='h2' fontSize='22px' fontWeight={400}>
              {t('help.content.element2.title')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography fontSize='18px' fontWeight={300}>
              <Trans
                i18nKey='help.content.element2.description'
                components={[
                  <Typography fontSize='18px' key={0} component='span' fontWeight={500} color='primary.main' />,
                ]}
              />
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />}>
            <Typography variant='h2' fontSize='22px' fontWeight={400}>
              {t('help.content.element3.title')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography fontSize='18px' fontWeight={300} sx={{ margin: '0 0 5px 0' }}>
              <Trans
                i18nKey='help.content.element3.description'
                components={[
                  <MUILink
                    key={0}
                    fontWeight={500}
                    sx={{ display: 'inline-block' }}
                    component={NavLink}
                    to={`/${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}/account?open=shopping`}
                  />,
                  <Typography fontSize='18px' key={1} component='span' fontWeight={500} color='primary.main' />,
                ]}
              />
            </Typography>
            <PointsList>
              <PointsListItem>
                <Typography fontWeight={500} sx={{ display: 'inline-block' }} fontSize='18px'>
                  {t('help.content.element3.point1')}
                </Typography>
              </PointsListItem>
              <PointsListItem>
                <Typography fontWeight={500} sx={{ display: 'inline-block' }} fontSize='18px'>
                  {t('help.content.element3.point2')}
                </Typography>
              </PointsListItem>
              <PointsListItem>
                <Typography fontWeight={700} sx={{ display: 'inline-block' }} fontSize='18px'>
                  {t('help.content.element3.point3')}
                </Typography>
              </PointsListItem>
            </PointsList>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />}>
            <Typography variant='h2' fontSize='22px' fontWeight={400}>
              {t('help.content.element4.title')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography fontSize='18px' fontWeight={300}>
              <Trans i18nKey='help.content.element4.description' />
            </Typography>
          </AccordionDetails>
        </Accordion>
      </SectionLayout>
    </Container>
  );
};
