import React from 'react';
import { Container } from './ShoppingList.styled';
import { ShoppingListItem } from './ShoppingListItem/ShoppingListItem';
import { Payment } from 'models/Account/Shopping/payment/payment';

export const ShoppingList: React.FC<OwnProps> = (props: OwnProps) => {
  const { payments } = props;
  return (
    <Container>
      {payments.map((element: Payment) => (
        <ShoppingListItem key={element.id} payment={element} />
      ))}
    </Container>
  );
};

interface OwnProps {
  payments: Payment[];
}
