import React from 'react';
import {
  Badge,
  BadgeProps,
  BottomNavigation,
  BottomNavigationAction,
  Menu,
  MenuProps,
  Popper,
  alpha,
  styled,
} from '@mui/material';
import { NavLink } from 'react-router-dom';

export const Container = styled('header')(() => ({
  height: '75px',
}));

export const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    border: `2px solid ${theme.palette.background.default}`,
    padding: '0 4px',
  },
}));

export const TopNavWrapper = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: '0',
  left: '0',
  width: '100%',
  background: theme.palette.background.default,
  borderBottom: `1px solid ${theme.palette.border.color1}`,
  zIndex: theme.zIndex.appBar,
  backdropFilter: 'blur(8px)',
  display: 'flex',
  opacity: 0.9,
  justifyContent: 'space-between',
  alignItems: 'center',
  overflow: 'hidden',

  padding: '20px',

  [theme.breakpoints.up('md')]: {
    padding: '20px 70px',
  },
}));

export const LogoWrapper = styled(NavLink)(({ theme }) => ({
  width: '110px',

  [theme.breakpoints.up('md')]: {
    width: '110px',
  },
}));

export const Logo = styled('img')(() => ({
  width: '100%',
}));

export const TopNav = styled('nav')(() => ({
  margin: '0 0 0 50px',
}));

export const TopNavList = styled('ul')(() => ({
  display: 'flex',
}));

export const TopNavListItem = styled('li')(() => ({
  margin: '0 5px 0 0',

  '&:last-of-type': {
    margin: 0,
  },
}));
export const ListItemNavLink = styled(NavLink)(({ theme }) => ({
  display: 'block',
  padding: '10px',
  textDecoration: 'none',
  color: theme.palette.text.primary,
  borderRadius: '4px',

  '&.active': {
    color: theme.palette.primary.main,
    fontWeight: 700,
  },
  transition: '0.3s background',

  '&:hover': {
    background: 'rgba(144, 202, 249, 0.08)',
    transition: '0.3s background',
  },
}));

interface SearchProps {
  isSearchMoviesOpen: boolean;
}

interface LanguageProps {
  active: boolean;
}

export const StyledPopper = styled(Popper)(() => ({
  // position: 'fixed',
  // width: '90% !important',
  // top: '20px !important',
  // height: '70% !important',
  // left: '50% !important',
  // transform: 'translate(-50%, 50px) !important',
  // '& > div': {
  //   height: '100% !important',
  // },
  // maxWidth: '750px',
  // [theme.breakpoints.up('lg')]: {
  //   width: '100% !important',
  //   transform: 'translate(-50%, 50px) !important',
  //   left: '50% !important',
  // },
}));

export const StyledPopperDesktop = styled(Popper)(() => ({
  // height: '50% !important',
}));

export const Search = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isSearchMoviesOpen',
})<SearchProps>(({ theme, isSearchMoviesOpen }) => ({
  position: 'fixed',
  top: isSearchMoviesOpen ? '0' : '100%',
  left: 0,
  display: 'flex',
  borderBottom: `1px solid ${theme.palette.border.color1}`,
  width: '100%',
  alignItems: 'center',
  padding: '0 20px',
  height: '75px',
  zIndex: theme.zIndex.modal,
  background: theme.palette.background.default,
}));

export const SearchList = styled('ul')(({ theme }) => ({
  background: theme.palette.background.background2,
  padding: '10px !important',
}));

export const SearchListDesktop = styled('ul')(({ theme }) => ({
  background: theme.palette.background.background2,
  padding: '10px !important',
}));

export const LanguageListItem = styled('li', {
  shouldForwardProp: (prop) => prop !== 'active',
})<LanguageProps>(({ theme, active }) => ({
  padding: '10px',
  // background: active ? theme.palette.background.default : theme.palette.background.background2,
  width: '100%',
  fontWeight: active ? 700 : 400,
  display: 'flex !important',
  borderRadius: '5px',
  // boxShadow: theme.shadows[10],

  margin: '0 0 10px 0',

  '&:last-of-type': {
    margin: '0',
  },

  cursor: 'pointer',
  '&:hover': {
    background: active ? theme.palette.background.background3 : theme.palette.background.background3,
  },
}));

export const SearchListItem = styled('li')(({ theme }) => ({
  padding: '10px',
  background: theme.palette.background.default,
  width: '100%',
  display: 'flex !important',
  borderRadius: '5px',
  boxShadow: theme.shadows[10],

  margin: '0 0 30px 0',

  '&:last-of-type': {
    margin: '0',
  },
}));

export const SearchListItemImageWrapper = styled(NavLink)(() => ({
  flexBasis: '100px',
  flexShrink: 0,
  alignSelf: 'flex-start',
  display: 'flex',
}));

export const SearchListItemImage = styled('img')(() => ({
  width: '100%',
}));

export const SearchListItemWrapper = styled('div')(({ theme }) => ({
  flexGrow: 1,
  padding: '0 0 0 10px',

  [theme.breakpoints.up('md')]: {
    padding: '0 0 0 30px',
  },
}));

export const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    background: theme.palette.background.background4,
    border: `1px solid ${theme.palette.border.color1}`,
    borderRadius: 6,
    padding: '10px',
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.common.white,
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

export const StyledMenuItemNavLink = styled(NavLink)(({ theme }) => ({
  display: 'block',
  textDecoration: 'none',
  color: theme.palette.common.white,
  borderRadius: '4px',
  width: '100%',
  padding: '6px 16px',
}));

export const BottomNavWrapper = styled('div')(({ theme }) => ({
  position: 'fixed',
  bottom: '0',
  left: '0',
  width: '100%',
  opacity: 0.9,
  borderTop: `1px solid ${theme.palette.border.color1}`,
  zIndex: theme.zIndex.appBar,
}));

export const CustomBottomNavigation = styled(BottomNavigation)(() => ({}));

export const CustomBottomNavigationAction = styled(BottomNavigationAction)(() => ({}));
