export class Bookmark {
  id: string;
  lastWatchTime: number;
  audio: string;
  subtitle: string;
  videoDuration: number;

  constructor(id: string, lastWatchTime: number, audio: string, subtitle: string, videoDuration: number) {
    this.id = id;
    this.lastWatchTime = lastWatchTime;
    this.audio = audio;
    this.subtitle = subtitle;
    this.videoDuration = videoDuration;
  }
}
