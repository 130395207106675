import React from 'react';
import { Container } from './MyMoviesList.styled';
import { MyMovie } from 'types/shared/movie-type';
import { MyMovieListItem } from './MyMovieListItem/MyMovieListItem';
import { User } from 'firebase/auth';

export const MyMoviesList: React.FC<OwnProps> = (props) => {
  const { myMovies, user } = props;

  return (
    <Container>
      {myMovies.map((element: MyMovie) => (
        <MyMovieListItem key={element.id} user={user} myMovie={element} />
      ))}
    </Container>
  );
};

interface OwnProps {
  myMovies: MyMovie[];
  user: User;
}
