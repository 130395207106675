import { styled } from '@mui/material';

export const Container = styled('article')(({ theme }) => ({
  width: '100%',
  margin: '0 0 50px 0',

  '&:last-of-type': {
    margin: '0',
  },

  '& .carousel-container': {
    padding: '30px 0',
    overflow: 'none',
  },

  '& .react-multiple-carousel__arrow--left': {
    left: '4px',
  },

  '& .react-multiple-carousel__arrow--right': {
    right: '4px',
  },

  '& .carousel-item': {
    padding: '0 30px 0 0',
    transition: 'all .2s ease-in-out',

    '&:last-of-type': {
      padding: '0',
    },
  },

  '& .carousel-item:hover': {
    transform: 'scale(1.2)',
    zIndex: theme.zIndex.modal,
  },
}));

export const StyledPoster = styled('div')(({ theme }) => ({
  display: 'block',
  width: '100%',
  maxWidth: '300px',
  minWidth: '190px',
  borderRadius: '5px',
  boxShadow: theme.shadows[10],
  overflow: 'hidden',
  cursor: 'pointer',

  '&:last-of-type': {
    padding: '0',
  },

  [theme.breakpoints.up('sm')]: {
    maxWidth: '200px',
  },
}));

export const PosterImage = styled('img')({
  width: '100%',
});

export const ModalBox = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '300px',

  [theme.breakpoints.up('md')]: {
    width: '600px',
  },
}));

export const ModalImage = styled('img')(({ theme }) => ({
  width: '100%',
  borderRadius: '5px',
  boxShadow: theme.shadows[10],
}));
