import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import { Alert, Button, TextField, Typography } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { HelmetContent } from 'components/Common/HelmetContent/HelmetContent';
import { firebaseCheckIfUserExistsByEmail, firebaseSendSignInLinkToEmail, firebaseSignInWithGoogle } from 'firestore';
import { SectionLayout } from 'layouts/SectionLayout/SectionLayout';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { isEmailValid } from 'utils/Common/is-email-valid/is-email-valid';
import { scrollTop } from 'utils/Common/scroll-top/scroll-top';
import { Container, Form, FormContainer, TabsContainer } from './Register.styled';

export const Register: React.FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { t, i18n } = useTranslation();

  const [value, setValue] = React.useState(1);
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string | null>(null);
  const [isSendingSignInLinkToEmailLoading, setIsSendingSignInLinkToEmailLoading] = useState<boolean>(false);
  const [sendingSignInLinkToEmailError, setSendingSignInLinkToEmailError] = useState<string | null>(null);
  const [isLinkSendSuccessfully, setIsLinkSendSuccessfully] = useState<string | null>(null);

  const handleTabsChange = (event: React.SyntheticEvent, newValue: number): void => {
    setValue(newValue);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setEmail(event.target.value);
    if (event.target.value.length === 0) return setEmailError(t('shared.textField.email.error.empty'));
    if (!isEmailValid(event.target.value)) return setEmailError(t('shared.textField.email.error.invalid'));
    return setEmailError(null);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    let errors: string[] = [];

    if (email.length === 0) {
      setEmailError(t('shared.textField.email.error.empty'));
      errors.push(t('shared.textField.email.error.empty'));
    }

    if (email.length > 0 && !isEmailValid(email)) {
      setEmailError(t('shared.textField.email.error.invalid'));
      errors.push(t('shared.textField.email.error.invalid'));
    }

    if (errors.length > 0) return;

    try {
      setSendingSignInLinkToEmailError(null);
      setIsLinkSendSuccessfully(null);
      setIsSendingSignInLinkToEmailLoading(true);
      const ifUserExists = await firebaseCheckIfUserExistsByEmail(email);
      if (ifUserExists) return setSendingSignInLinkToEmailError('shared.firebaseErrorCodes.auth/email-already-in-use');

      await firebaseSendSignInLinkToEmail(
        email,
        i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language,
      );
      setIsLinkSendSuccessfully(email);
      setEmail('');
    } catch (e: any) {
      console.error(`${e.name}: ${e.message}`);
      setSendingSignInLinkToEmailError(e.message);
    } finally {
      setIsSendingSignInLinkToEmailLoading(false);
    }
  };

  const handleClickSignInWithGoogle = async () => {
    try {
      await firebaseSignInWithGoogle(() => {
        navigate(`/${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}`);
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    scrollTop();
  }, []);

  return (
    <SectionLayout title={t('register.title')}>
      <HelmetContent title={t('register.pageTitle')} description={t('register.pageDescription')} />
      <Container>
        <TabsContainer>
          <Tabs value={value} onChange={handleTabsChange} aria-label='nav tabs example'>
            <Tab
              label={t('shared.tabs.logIn')}
              value={0}
              to={`/${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}/login`}
              component={Link}
            />
            <Tab
              label={t('shared.tabs.register')}
              value={1}
              to={`/${i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language}/register`}
              component={Link}
            />
          </Tabs>
        </TabsContainer>
        <FormContainer>
          <Form noValidate onSubmit={(event: React.FormEvent<HTMLFormElement>) => handleSubmit(event)}>
            {!!state && state.errorFromSetPassword && (
              <Alert sx={{ margin: '0 0 30px 0' }} severity='error'>
                {state.errorFromSetPassword}
              </Alert>
            )}
            <TextField
              onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleEmailChange(event)}
              value={email}
              error={!!emailError}
              helperText={emailError ? emailError : null}
              id='email'
              sx={{ margin: '0 0 20px 0', width: '100%' }}
              label={t('shared.textField.email.label')}
              variant='standard'
            />
            <LoadingButton
              sx={{ margin: '0 0 40px 0', width: '100%', textTransform: 'none' }}
              variant='contained'
              loading={isSendingSignInLinkToEmailLoading}
              type='submit'
              size='large'>
              {t('shared.buttons.register')}
            </LoadingButton>
            {!!sendingSignInLinkToEmailError && (
              <Alert sx={{ margin: '0 0 30px 0' }} severity='error'>
                {t(sendingSignInLinkToEmailError)}
              </Alert>
            )}
            {!!isLinkSendSuccessfully && (
              <Alert sx={{ margin: '0 0 30px 0' }} severity='success'>
                <Trans
                  i18nKey='register.alerts.success.linkWasSend'
                  values={{
                    email: isLinkSendSuccessfully,
                  }}
                  components={[
                    <Typography
                      key={0}
                      fontWeight={700}
                      sx={{ display: 'inline-block', margin: '0 10px 0 0', wordBreak: 'break-word' }}
                      component='span'
                    />,
                  ]}
                />
              </Alert>
            )}
            <Button
              onClick={handleClickSignInWithGoogle}
              type='button'
              startIcon={<FontAwesomeIcon size='2xs' icon={faGoogle as IconProp} />}
              variant='outlined'
              size='large'
              sx={{ width: '100%', textTransform: 'none', background: (theme) => theme.palette.background.default }}>
              {t('shared.buttons.registerWithGoogle')}
            </Button>
          </Form>
        </FormContainer>
      </Container>
    </SectionLayout>
  );
};
