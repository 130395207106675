import { MoviesContext } from 'context/MoviesContext';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MoviePreview } from 'types/shared/movie-type';
import { convertMovieToMoviePreview } from 'utils/Home/convert-movie-to-movie-preview/convert-movie-to-movie-preview';
import { filterMoviesByCategory } from 'utils/Home/filter-movies-by-category/filter-movies-by-category';
import { sortMoviesByCategoryAndOrder } from 'utils/Home/sort_movies_by_category_and_order/sort_movies_by_category_and_order';
import { MoviesCarousel } from './MoviesCarousel/MoviesCarousel';
import { Container, ExtraBackgroundWrapper } from './WatchMovies.styled';

export const WatchMovies: React.FC = () => {
  const { movies } = useContext(MoviesContext);
  const { t } = useTranslation();
  const [christianMovies] = useState<MoviePreview[]>(
    convertMovieToMoviePreview(sortMoviesByCategoryAndOrder('christian', filterMoviesByCategory(movies, 'christian'))),
  );
  const [casualMovies] = useState<MoviePreview[]>(
    convertMovieToMoviePreview(sortMoviesByCategoryAndOrder('casual', filterMoviesByCategory(movies, 'casual'))),
  );
  const [basedOnTheTrueStory] = useState<MoviePreview[]>(
    convertMovieToMoviePreview(
      sortMoviesByCategoryAndOrder(
        'based_on_the_true_story',
        filterMoviesByCategory(movies, 'based_on_the_true_story'),
      ),
    ),
  );
  const [menFightForRighteousness] = useState<MoviePreview[]>(
    convertMovieToMoviePreview(
      sortMoviesByCategoryAndOrder(
        'men_fight_for_righteousness',
        filterMoviesByCategory(movies, 'men_fight_for_righteousness'),
      ),
    ),
  );

  return (
    <Container>
      <ExtraBackgroundWrapper />
      <MoviesCarousel path='christian-movies' movies={christianMovies} category={t('home.categories.christian')} />
      <MoviesCarousel path='casual-movies' movies={casualMovies} category={t('home.categories.casual')} />
      <MoviesCarousel
        path='based-on-the-true-story-movies'
        movies={basedOnTheTrueStory}
        category={t('home.categories.based_on_the_true_story')}
      />
      <MoviesCarousel
        movies={menFightForRighteousness}
        xlItemsAmount={7}
        category={t('home.categories.men_fight_for_righteousness')}
        path='men-fight-for-righteousness-movies'
      />
    </Container>
  );
};
