import { styled } from '@mui/material';
import { NavLink } from 'react-router-dom';

export const Container = styled('article')(({ theme }) => ({
  width: '100%',
  position: 'relative',
  zIndex: 1,

  '& .react-multiple-carousel__arrow--left': {
    left: '16px',
    background: 'transparent',
  },

  '& .react-multiple-carousel__arrow--left:hover.react-multiple-carousel__arrow--left::before': {
    color: theme.palette.primary.main,
    transition: 'color 0.3s',
  },

  '& .react-multiple-carousel__arrow--left::before': {
    color: theme.palette.common.white,
    transition: 'color 0.3s',
    display: 'block',
    fontWeight: 500,
    fontSize: '34px',
  },

  '& .react-multiple-carousel__arrow--right:hover.react-multiple-carousel__arrow--right::before': {
    color: theme.palette.primary.main,
    transition: 'color 0.3s',
  },

  '& .react-multiple-carousel__arrow--right': {
    right: '16px',
    background: 'transparent',
  },

  '& .react-multiple-carousel__arrow--right::before': {
    color: theme.palette.common.white,
    transition: 'color 0.3s',
    display: 'block',
    fontSize: '34px',
    fontWeight: 500,
  },

  '& .react-multi-carousel-dot-list': {
    margin: '0 0 5px 0',

    [theme.breakpoints.up('sm')]: {
      margin: '0 0 10px 0',
    },

    '& .react-multi-carousel-dot': {
      button: {
        height: '8px',
        width: '8px',
        border: 'none',
        backgroundColor: theme.palette.common.white,
        opacity: '0.9',

        [theme.breakpoints.up('lg')]: {
          height: '10px',
          width: '10px',
        },
      },
    },

    '& .react-multi-carousel-dot--active': {
      button: {
        backgroundColor: theme.palette.primary.main,
        opacity: '0.9',
      },
    },
  },
}));

export const MovieStyled = styled(NavLink)({
  display: 'block',
  position: 'relative',
  width: '100%',
});

export const MovieWrapper = styled('div')({
  width: '100%',
  maskImage: 'linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0.1))',
});
