import { styled } from '@mui/material';

export const Container = styled('section')(({ theme }) => ({
  width: '100%',
  padding: '30px 20px 0px 20px',
  background: theme.palette.background.background2,

  [theme.breakpoints.up('sm')]: {
    padding: '30px 60px 0px 60px',
  },

  [theme.breakpoints.up('md')]: {
    padding: '50px 80px 0px 80px',
  },

  [theme.breakpoints.up('xl')]: {
    padding: '100px 120px 0px 120px',
  },
}));
